<div
  class="image-scope"
  [ngClass]="{
    'outline_invalid': !submition
  }"
>
  <div class="image-scope-preview">
    <div class="image-scope-preview-item">
      <div class="wrapper">
        <div class="file-upload">
          <input
            type="file"
            (change)="onFileSelected($event)"
            accept="image/jpeg, image/png, image/jpeg"
            type="file"
            class="custom-file-input"
            id="customFile"
          />
          <i class="fa fa-arrow-up"></i>
        </div>
      </div>

      <div>
        <span>{{
          (selectedFileName ? "Files.ChooseFile" : "Files.NoFileChosen")
            | translate
        }}</span>
        <span
          >Limit {{ bytesToSizes(maxFileSize) }} choose only JPG,JPEG,PNG</span
        >
      </div>
    </div>
  </div>
  <div class="image-scope-tool">
    <!-- <div class="image-scope-tool-overlay"></div> -->
    <div class="image-scope-tool-scale">
      <div class="spangboom" *ngIf="haveImage">
        <div *ngIf="!selectedFile" class="spinner-grow" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <img
        [src]="selectedFile ? selectedFile : coreConfig.app.defaultIMG"
        alt="Preview"
      />
    </div>
  </div>
</div>

<div
  class="invalid-feedback"
  [ngClass]="{
    'd-block': !submition
  }"
>
  {{ "Handle.SingleImage" | translate }}
</div>
