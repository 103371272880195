import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import {
  catchError,
  finalize,
  map,
  shareReplay,
  switchMap,
} from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { Token } from '../models/token';
import { ApiService } from 'app/main/service/api.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { title } from 'process';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public token: Observable<Token>;
  public currentUser: Observable<User>;

  //private
  private tokenSubject: BehaviorSubject<Token>;
  private currentUserSubject: BehaviorSubject<User>;

  private refreshTokenInProgress: boolean = false;
  private refreshTokenSubject: Subject<any> = new Subject<any>();

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    private _apiService: ApiService,
    private _router: Router
  ) {
    this.tokenSubject = new BehaviorSubject<Token>(
      localStorage.getItem('token')
        ? JSON.parse(localStorage.getItem('token') as string)
        : null
    );
    this.token = this.tokenSubject.asObservable();
    this.currentUserSubject = new BehaviorSubject<User>(
      localStorage.getItem('currentUser')
        ? JSON.parse(
            decodeURIComponent(
              window.atob(localStorage.getItem('currentUser') as string)
            )
          )
        : null
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get tokenValue(): Token {
    return this.tokenSubject.value;
  }
  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  get isSuperAdmin() {
    return (
      this.currentUser &&
      this.currentUserSubject.value.roleSetName === Role.SuperAdmin
    );
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return (
      this.currentUser &&
      this.currentUserSubject.value.roleSetName === Role.Admin
    );
  }

  /**
   *  Confirms if user is client
   */
  get isUser() {
    return (
      this.currentUser &&
      this.currentUserSubject.value.roleSetName === Role.User
    );
  }

  isLoggedIn(): boolean {
    return this.tokenValue != null;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(username: string, password: string): Observable<any> {
    return this._http
      .post<any>(`${environment.apiUrl}/auth/login`, {
        userName: username,
        password: password,
      })
      .pipe(
        map((res) => {

          if (res && res.data) {
            localStorage.setItem(
              'token',
              JSON.stringify({
                accessToken: res.data.token,
                refreshToken: res.data.refreshToken,
              })
            );
            this.tokenSubject.next({
              accessToken: res.data.token,
              refreshToken: res.data.refreshToken,
            });
          }
          return res;
        }),
        catchError(() => EMPTY)
      );
  }

  sendEmailforgetPassword(email: string): Observable<any> {
    return this._http.post<any>(
      `${environment.apiUrl}/account/forgotPassword`,
      { email: email }
    );
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    // notify
    this.tokenSubject.next(null);
    this.currentUserSubject.next(null);

    this._router.navigate(['/login']);
  }

  getAccountInfo(token: Token) {
    let httpHeaders;
    httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', token.accessToken);
    return this._http
      .get<any>(`${environment.apiUrl}/account/info`, { headers: httpHeaders })
      .pipe(
        map((res) => {
          if (res && res.data) {
            localStorage.setItem(
              'currentUser',
              window.btoa(
                encodeURIComponent(JSON.stringify(res.data.resultData[0]))
              )
            );
            this.currentUserSubject.next(res.data.resultData[0]);
          }
          return res.data.resultData[0];
        }),
        catchError(() => EMPTY)
      );
  }

  refreshToken() {
    return this._http
      .post<any>(`${environment.apiUrl}/auth/refresh-token`, {
        accessToken: this.tokenValue.accessToken,
        refreshToken: this.tokenValue.refreshToken,
      })
      .pipe(
        map((res) => {
          if (
            !res.success ||
            res.data.resultData[0].message == 'Token has expired'
          ) {
            Swal.fire('Session Expired', 'Please login again', 'error');
            this.logout();
          } else {
            if (
              res.data.resultData[0].success &&
              res.data.resultData[0].token &&
              res.data.resultData[0].refreshToken
            ) {
              localStorage.setItem(
                'token',
                JSON.stringify({
                  accessToken: res.data.resultData[0].token,
                  refreshToken: res.data.resultData[0].refreshToken,
                })
              );
              this.tokenSubject.next({
                accessToken: res.data.resultData[0].token,
                refreshToken: res.data.resultData[0].refreshToken,
              });
            }
          }
          return res.data.resultData[0];
        }),
        catchError(() => EMPTY)
      );
  }

  revokeToken() {
    let httpHeaders;
    httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${this.tokenValue.accessToken}`);
    return this._http
      .post<any>(
        `${environment.apiUrl}/auth/revoke-token`,
        {
          refreshToken: this.tokenValue.refreshToken,
        },
        { headers: httpHeaders }
      )
      .pipe(
        map((res) => {
          if (!res.success) {
            this.logout();
            window.location.href = '/login';
          } else {
          }
          return res;
        }),
        catchError(() => EMPTY)
      );
  }
}
