import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Content from 'app/main/model/Content';
import { Subject } from 'rxjs';
import { ModalComponent } from '../modal/modal.component';
import { ComponentsService } from '../components.service';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from 'xlsx';
import { DataTableDirective } from 'angular-datatables';
import { ApiService } from 'app/main/service/api.service';

@Component({
  selector: 'app-content-list',
  templateUrl: './content-list.component.html',
  styleUrls: ['./content-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContentListComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();
  itemObjList: Content[] = [];
  @Input() apiPath: string;
  @Input() mainPathUrl: string;
  @Input() editPathUrl: string;
  @Input() page: number;
  @Input() pageSize: number;
  @Input() keyName: string;
  @Input() objectExport: any;
  @BlockUI() blockUI: NgBlockUI;
  @Input() isForGroupTemplate: boolean;
  @Input() isSortByNewest: boolean = false;
  @Input() isGoEditPage: boolean;
  @Input() selectedColumns: string[];
  @Input() searchData: any = {};

  isLoading: boolean = false;

  @ViewChild('contentTable') contentTable: ElementRef;

  @Output('isActiveFunc') isActiveFunc: EventEmitter<any> = new EventEmitter();
  @Output('deleteFunc') deleteFunc: EventEmitter<any> = new EventEmitter();

  activeModal: any;
  currentLang: string;

  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _modalService: NgbModal,
    private _componentService: ComponentsService,
    private _translateService: TranslateService,
    private _apiService: ApiService
  ) {}

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  ExportXLSX() {
    const selectedColumns = this.selectedColumns;
    console.log(this.objectExport);
    const headers = Object.keys(this.objectExport[0]);

    const excludedColumnIndices = headers
      .map((header, index) => (selectedColumns.includes(header) ? -1 : index))
      .filter((index) => index !== -1);

    let filteredTableData = this.objectExport;
    console.log(selectedColumns.length);

    if (selectedColumns.length > 0) {
      filteredTableData = this.objectExport.map((row, index) => {
        const filteredRow = { 'No.': index + 1 };
        Object.entries(row).forEach(([columnName, cell], index) => {
          if (!excludedColumnIndices.includes(index)) {
            filteredRow[columnName] = cell.toString().trim();
          }
        });
        return filteredRow;
      });
    }

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(filteredTableData);

    /* new format */
    var fmt = '0.00';
    /* change cell format of range B2:D4 */
    var range = { s: { r: 1, c: 1 }, e: { r: 2, c: 100000 } };
    for (var R = range.s.r; R <= range.e.r; ++R) {
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var cell = ws[XLSX.utils.encode_cell({ r: R, c: C })];
        if (!cell || cell.t != 'n') continue; // only format numeric cells
        cell.z = fmt;
      }
    }
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    var fmt = '@';
    wb.Sheets['Sheet1']['F'] = fmt;
    console.log(wb);

    /* save to file */
    XLSX.writeFile(wb, `${'Sheet'}.xlsx`);
  }

  openDeleteContentModal(itemObj: any) {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title =
      this._translateService.instant('Brand.DeleteTitle') + `${itemObj.name}`;
    modalRef.componentInstance.detail =
      this._translateService.instant('Brand.Delete') + `${itemObj.name}`;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.deleteContent(itemObj.id);
    });
  }

  openIsActiveModal(itemObj, status): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    let modeText = status
      ? this._translateService.instant('Status.Published')
      : this._translateService.instant('Status.Unpublished');
    modalRef.componentInstance.title = `${modeText}`;
    modalRef.componentInstance.detail =
      this._translateService.instant('Brand.Active') +
      `${modeText} ${itemObj.name}`;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      this.setIsActiveContent(itemObj.id, status);
    });
  }

  setIsActiveContent(id, status): void {
    this.blockUI.start();
    this.isActiveFunc.emit({ id: id, status: status });
  }

  deleteContent(id): void {
    this.blockUI.start();
    this.deleteFunc.emit(id);
  }

  ngOnInit(): void {
    var self = this;
    this.currentLang = this._translateService.currentLang || 'en';

    this._translateService.onLangChange.subscribe((e) => {
      this.currentLang = this._translateService.currentLang;
    });

    // this._componentService.contentListSubject.subscribe((list) => {
    //   this.itemObjList = list;
    //   // this.refreshDataTable()
    // });

    this.dtOptions = {
      pagingType: 'full_numbers',
      scrollX: false,
      info: true,
      autoWidth: false,
      searching: false,
      pageLength: 10,

      order: [[this.isSortByNewest ? 3 : 1, 'desc']],
      ordering: true,
      serverSide: true,

      lengthChange: false,
      columnDefs: [
        { orderable: false, targets: 0 },
        { name: this.keyName ? this.keyName : 'name', targets: 1 },
        { name: 'IsActive', targets: 2 },
        { name: 'UpdateDate', targets: 3 },
        { name: 'CreateBy', targets: 4 },
        { orderable: false, targets: 5 },
      ],
      ajax: (dataTablesParameters: any, callback: any) => {
        this.itemObjList = [];
        self.SetLoadingState();

        let defaultSortName = '';
        if (this.isSortByNewest) {
          defaultSortName = 'UpdateDate';
        } else {
          defaultSortName = this.keyName ? this.keyName : 'name';
        }

        let listcol = dataTablesParameters?.columns;
        let order = dataTablesParameters?.order[0];
        let founded = listcol?.filter(
          (item: any) => item.data === order.column
        )[0];
        let column_filter_number = founded.data;
        let column_filter_name = founded.name;
        if (column_filter_number == 0) {
          self.searchData.SortPath = defaultSortName;
        } else {
          self.searchData.SortPath = !!column_filter_name
            ? column_filter_name
            : defaultSortName;
        }

        this.searchData.Direction = order?.dir == 'desc' ? 1 : 0;

        this.searchData.Page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.page = Math.ceil(
          (dataTablesParameters.start + 1) / dataTablesParameters.length
        );
        this.pageSize = dataTablesParameters.length;

        this.searchData.PageLength = dataTablesParameters.length;

        this.searchData.isDelete = false;

        this._apiService.GetAllData(this.apiPath, this.searchData).subscribe(
          (response) => {
            console.log(response);
            this.itemObjList = response.data.resultData;
            self.SetLoadedState();
            callback({
              recordsTotal: response.data.itemCount,
              recordsFiltered: response.data.totalItem,
              data: [],
            });
          },
          (err) => {
            this.itemObjList = [];
            self.SetLoadedState();
            callback({
              recordsTotal: 0,
              recordsFiltered: 0,
              data: [],
            });
          }
        );
      },
    };
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  refreshDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  loadData(searchData?: any) {
    if (searchData) {
      this.searchData = searchData;
    }

    if (this.dtTrigger) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.ajax.reload(() => {
          this.SetLoadedState();
        });
      });
    }
  }
}
