import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryComponent } from './category.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../../auth/helpers';
import { CategoryFormpageComponent } from './category-formpage/category-formpage.component';

import { ContentHeaderModule } from '../../../../layout/components/content-header/content-header.module';
import { ComponentsModule } from '../../../components/components.module';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';
import { environment } from 'environments/environment';

const routes: Routes = !environment.menuList.itemManage ? []: [
  {
    path: 'item-manage/category',
    component: CategoryComponent,
    canActivate: [AuthGuard],
    data: { animation: 'category-manage' },
  },
  {
    path: 'item-manage/category/create',
    component: CategoryFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'category-create' },
  },
  {
    path: 'item-manage/category/edit/:id',
    component: CategoryFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'category-edit' },
  },
];
@NgModule({
  declarations: [
    CategoryComponent,
    CategoryFormpageComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    CoreCommonModule,
    ContentHeaderModule,
    ComponentsModule,
    NgbPaginationModule,
    TranslateModule
  ],
  exports: [CategoryComponent, CategoryFormpageComponent],
})
export class CategoryModule {}
