import { Component, OnInit } from '@angular/core';
import { ContentHeader } from 'app/layout/components/content-header/content-header.component';

@Component({
  selector: 'app-user-guide-cms',
  templateUrl: './user-guide-cms.component.html',
  styleUrls: ['./user-guide-cms.component.scss'],
})
export class UserGuideCmsComponent implements OnInit {
  title: string = 'ng2-pdf-viewer';
  src: string = '/assets/cms-user-guide.pdf';
  constructor() {}

  contentHeader: ContentHeader;
  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'General.UserGuideCMS',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }
}
