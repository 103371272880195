import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormTemplateComponent } from 'app/main/components/form/form-template/form-template.component';
import { CmsFormConfig } from 'app/main/config/CMSFormConfig';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { ApiService } from 'app/main/service/api.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-product-service',
  templateUrl: './product-service.component.html',
  styleUrls: ['./product-service.component.scss']
})
export class ProductServiceComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();
  private dataSubscription: Subscription;

  contentHeader: object;
  isLoading: boolean;
  isLoadingData: boolean = false;

  apiPath: string;
  itemID: string;

  itemObj: any;
  config: ICMSConfig;

  @ViewChild('formTemplate') formTemplate: FormTemplateComponent;

  constructor(
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _apiService: ApiService,
    private _cmsFormConfig: CmsFormConfig
  ) {
    this.apiPath = 'ProductsServices';
    this.itemID = this._route.snapshot.paramMap.get('id');

    this.config = this._cmsFormConfig.config['ProductsServices'];
    this.itemObj = this._cmsFormConfig.config['ProductsServices'].model;

    console.log(this.config);
    console.log(this.itemObj);

    if (this.config.isList) {
      if (this.itemID) {
        this.GetItemData(this.apiPath, this.itemID);
      }
    } else {
      this.GetItemData(this.apiPath, '');
    }
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'BreadCrumbs.CMS.Detail',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  GetItemData(apiPath, itemID): void {
    var self = this;
    this.isLoadingData = true

    this.dataSubscription = this._apiService
      .GetDataById(apiPath, itemID)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        self.itemObj = res.data.resultData[0];
        
        // self.setFormValue(self.itemObj);
        this.formTemplate.setFormValue(self.itemObj);
        this.isLoadingData = false
      },err => {
        
      });
  }

  setFormValue(obj): void {
    this.formTemplate.setFormValue(obj);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe()
    }
  }
}
