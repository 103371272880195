import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import Group from '../model/Group';

@Injectable({
  providedIn: 'root',
})
export class MainConfig {
  dataList: Group[];

  summernoteNewsConfig: object = {
    placeholder: 'Begin typing here...',
    tabsize: 2,
    height: '400px',
    disableDragAndDrop: false,
    toolbar: [
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['font', ['strikethrough', 'superscript', 'subscript']],
      ['fontsize', ['fontsize']],
      // ['color', ['color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['height', ['height']],
      ['insert', ['hr','link', 'picture', 'video']],
      ['view', ['codeview']],
    ],
    callbacks: {
      onPaste: function (e) {
        var bufferText = (
          (e.originalEvent || e).clipboardData || window.ClipboardItem
        ).getData('Text');
        e.preventDefault();
        document.execCommand('insertText', false, bufferText);
      },
    },
  };
}

export const filesPropNameConfig = {
  user: {
    profileImage: 'profileImage',
  },
  item: {
    coverImage: 'coverImage',
    detailImages: 'detailImages',
    attachedFiles: 'attachedFiles'
  },
  itemSet: {
    coverImage: 'coverImage',
    detailImages: 'detailImages',
  },
}