import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home.component';
import { AuthGuard } from 'app/auth/helpers';
import { TranslateModule } from '@ngx-translate/core';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSummernoteModule } from 'ngx-summernote';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { ComponentsModule } from 'app/main/components/components.module';
import { HomeBannerComponent } from './home-banner/home-banner.component';
import { HomeBannerFormpageComponent } from './home-banner-formpage/home-banner-formpage.component';
import { CoreCommonModule } from '@core/common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const routes: Routes = [
  {
    path: 'cms/home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-home' },
  },

  {
    path: 'cms/homeBanner',
    component: HomeBannerComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-homeBanner' },
  },

  {
    path: 'cms/homeBanner/create',
    component: HomeBannerFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-homeBanner-create' },
  },
  {
    path: 'cms/homeBanner/edit/:id',
    component: HomeBannerFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-homeBanner-edit' },
  },
];

@NgModule({
  declarations: [
    HomeComponent,
    HomeBannerComponent,
    HomeBannerFormpageComponent,
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    TranslateModule,
    ContentHeaderModule,
    FormsModule,
    NgxSummernoteModule,
    ReactiveFormsModule,
    CorePipesModule,
    ComponentsModule,
    RouterModule.forChild(routes),
    NgbModule
  ],
  exports: [HomeComponent],
})
export class HomeModule {}
