import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClassComponent } from './class.component';
import { ClassFormpageComponent } from './class-formpage/class-formpage.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../../../../auth/helpers';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ContentHeaderModule } from '../../../../layout/components/content-header/content-header.module';
import { ComponentsModule } from '../../../components/components.module';
import { CoreCommonModule } from '@core/common.module';
import { environment } from 'environments/environment';

const routes: Routes = !environment.menuList.itemManage ? []: [
  {
    path: 'item-manage/class',
    component: ClassComponent,
    canActivate: [AuthGuard],
    data: { animation: 'class-manage' },
  },
  {
    path: 'item-manage/class/create',
    component: ClassFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'class-create' },
  },
  {
    path: 'item-manage/class/edit/:id',
    component: ClassFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'class-edit' },
  },
];

@NgModule({
  declarations: [
    ClassComponent,
    ClassFormpageComponent,
  ],
  imports: [
    RouterModule.forChild(routes),
    CoreCommonModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    ContentHeaderModule,
    ComponentsModule,
  ],
  exports: [ClassComponent, ClassFormpageComponent],
})
export class ClassModule {}
