import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { User } from 'app/auth/models';
import { UsersApiService } from 'app/main/service/users-api.service';
import { ModalComponent } from '../../modal/modal.component';
import { ComponentsService } from '../../components.service';
import { ApiService } from 'app/main/service/api.service';
import { RoleSet } from 'app/auth/models/roleSet';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FilesApiService } from 'app/main/service/files-api.service';
import { UserService } from 'app/main/pages/admin/users/users.service';
import { ImageUploadComponent } from '../../image-upload/image-upload.component';
import { AuthenticationService } from 'app/auth/service';
import { filesPropNameConfig } from 'app/main/config/MainConfig';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  contentHeader: object;
  componentName: string;
  modeText: string;
  @BlockUI() blockUI: NgBlockUI;

  isLoading: boolean;
  isEditing: boolean;
  isSubmitted: boolean;

  userGuid: string;
  roleSetList: RoleSet[];
  // userObj: User;

  userObj: User;
  @Input() callbackUrl: string;

  formGroup: FormGroup;
  coverIMGform: FormData;

  // organizationList: any[] = [
  //   { id: 1, name: 'กระทรวงเวทมนตร์' },
  //   { id: 2, name: 'โรงเรียน Hogwarts' },
  //   { id: 3, name: 'มหาลัยวัวชน' },
  // ];

  // branchList: any[] = [
  //   { id: 1, name: 'บางโพธิ์' },
  //   { id: 2, name: 'โคราช' },
  //   { id: 3, name: 'กาดมันดุ' },
  // ];

  // departmentList: any[] = [
  //   { id: 1, name: 'FBI' },
  //   { id: 2, name: 'Carry' },
  //   { id: 3, name: 'ขายหวย' },
  // ];
  // positionList: any[] = [
  //   { id: 1, name: 'โปรแกรมเมอร์' },
  //   { id: 2, name: 'ช่างแอร์' },
  //   { id: 3, name: 'ป๋าหมวกทอง' },
  // ];

  prefixList: any[] = [
    { id: 1, name: 'นาย' },
    { id: 2, name: 'นาง' },
    { id: 3, name: 'นางสาว' },
    // { id: 99, name: 'อื่นๆ' },
  ];

  genderList: any[] = [
    { id: 1, name: 'ชาย' },
    { id: 2, name: 'หญิง' },
    // { id: 99, name: 'ไม่ระบุ' },
  ];

  filesPropName = filesPropNameConfig;

  @ViewChild('imageUploadComponent') imageUploadComponent: ImageUploadComponent;
  apiPath: string;
  pathUrl: string;
  constructor(
    private _userApiService: UsersApiService,
    private _userManageService: UserService,
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _formBuilder: FormBuilder,
    private _modalService: NgbModal,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _fileApiService: FilesApiService,
    private _authenticationService: AuthenticationService
  ) {
    this.SetLoadingState();

    this.userGuid = this._route.snapshot.paramMap.get('id');
    this.isEditing = this.userGuid ? true : false;
    this.componentName = this._userManageService.componentName;

    this.apiPath = this._userManageService.apiPath;
    this.pathUrl = this._userManageService.pathUrl;

    console.log(this.userGuid);

    this.getRoleSetData();
    this.initForm();

    if (this.userGuid) {
      this.getUserData(this.userGuid);
    } else {
      this.userObj = new User();
      this.userObj.isActive = true;
      this.userObj.isVerify = true;
      this.setFormValue();
      this.SetLoadedState();
      console.log(this.userObj);
    }
  }

  ngOnInit(): void {
    // if (this.userGuid) {
    //   this.isEditing = true;
    //   this.setFormValue();
    //   this.SetLoadedState();
    // } else {
    //   this.userObj = new User();
    //   this.setFormValue();
    //   this.SetLoadedState();
    // }

    // console.log(this.isEditing)

    this.modeText = this.isEditing
      ? this._translateService.instant('General.Edit')
      : this._translateService.instant('General.Create');
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  getUserData(guid: string): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._userApiService.GetByID(guid).subscribe((res) => {
        self.userObj = res.data.resultData[0];

        if (self.userObj.mediaFiles[self.filesPropName.user.profileImage]) {
          this._fileApiService
            .GetFile(
              self.userObj.mediaFiles[self.filesPropName.user.profileImage][0]
                .id
            )
            .subscribe((res: Blob) => {
              self.imageUploadComponent.setCoverImage(
                res,
                self.userObj.mediaFiles[self.filesPropName.user.profileImage][0]
                  .id
              );
            });
        }

        self.setFormValue();
        self.SetLoadedState();
        console.log(this.userObj);
      });
    });
  }

  initForm(): void {
    this.formGroup = this._formBuilder.group({
      username: ['', [Validators.required, Validators.maxLength(100)]],
      // password: ['', [Validators.required]],
      roleSetId: [null, [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', [Validators.required, Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.maxLength(100)]],
      isCompanyUser: [false, []],
      socialMediaLink: ['', []],
      namePrefixId: [null, []],
      genderId: [null, []],
      personalId: [null, []],
      isActive: [true, [Validators.required]],
      phone: ['', []],
      businessTypeId: ['', []],
    });
  }

  get f() {
    return this.formGroup.controls;
  }

  setFormValue(): void {
    var self = this;
    this.formGroup.patchValue({
      username: self.userObj.username ? self.userObj.username : '',
      roleSetId: self.userObj.roleSetId ? self.userObj.roleSetId : 'default',
      email: self.userObj.email,
      firstName: self.userObj.firstName,
      lastName: self.userObj.lastName,
      isCompanyUser: self.userObj.isCompanyUser,
      socialMediaLink: self.userObj.socialMediaLink,
      namePrefixId: self.userObj.namePrefixId
        ? self.userObj.namePrefixId
        : 'default',
      genderId: self.userObj.genderId ? self.userObj.genderId : 'default',
      personalId: self.userObj.personalId,
      phone: self.userObj.phone,
      businessTypeId: self.userObj.businessTypeId,
      isActive: self.userObj.isActive ? self.userObj.isActive : true,
    });
  }

  openConfirmModal(): void {
    let isConfirm = true;
    let title;
    let detail;
    let contentNameText = this.isEditing
      ? this.userObj.username
      : this.componentName;
    if (this.isEditing) {
      title = this._translateService.instant('ManageUser.ConfirmEditTitle');
      detail = this._translateService.instant('ManageUser.ConfirmEdit');
    } else {
      title = this._translateService.instant('ManageUser.ConfirmCreateTitle');
      detail = this._translateService.instant('ManageUser.ConfirmCreate');
    }
    this.openModal(title, detail, isConfirm);
  }

  openCancelModal(): void {
    let isConfirm = false;
    let title;
    let detail;
    let contentNameText = this.isEditing
      ? this.userObj.username
      : this.componentName;
    if (this.isEditing) {
      title = this._translateService.instant('ManageUser.CancelEditTitle');
      detail = this._translateService.instant('ManageUser.CancelEdit');
    } else {
      title = this._translateService.instant('ManageRole.CancelCreateTitle');
      detail = this._translateService.instant('ManageRole.CancelCreate');
    }
    this.openModal(title, detail, isConfirm);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    if (!IsConfirm) {
      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        this._router.navigate([this.callbackUrl]);
      });
    } else {
      this.isSubmitted = true;

      if (this.formGroup.invalid) {
        return;
      }

      const modalRef = this._modalService.open(ModalComponent, {
        centered: true,
        backdrop: 'static',
      });
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.isConfirm = IsConfirm;
      modalRef.componentInstance.detail = detail;
      modalRef.componentInstance.callBackFunc.subscribe((res) => {
        this.Submit();
        this._router.navigate([this.callbackUrl]);
      });
    }
  }

  setIsActive(val): void {
    this.userObj.isActive = val;
  }

  getRoleSetData(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('roleSet', { isDelete: false })
        .subscribe((res) => {
          self.roleSetList = res.data.resultData;
          console.log(res);
        });
    });
  }

  Submit(): void {
    this.blockUI.start();
    var self = this;
    console.log(this.formGroup.value);

    this.isSubmitted = true;

    if (this.formGroup.invalid) {
      let title = this._translateService.instant('Alert.FormValidationFailed');
      this._componentsService.ErrorSwal(title);
      return;
    }
    let profileFieldName = 'profileImage';
    console.log(this.coverIMGform);

    this.formGroup.patchValue({
      genderId: parseInt(this.f.genderId.value),
    });

    if (this.isEditing) {
      this._userApiService
        .SetIsActiveStatus(this.userObj.id, this.f.isActive.value)
        .subscribe((res) => {});

      this._userApiService
        .UpdateByID(this.userGuid, this.formGroup.value)
        .subscribe(
          (res) => {
            const params = {
              isEdit: true,
              idResId: res.data.resultData[0],
              tablename: this.apiPath,
              collectionName: profileFieldName,
              ordinal: 0,
            };

            // cover file upload
            if (this.imageUploadComponent.getFile()) {
              this.imageUploadComponent.uploadImage(params);
            }

            let token = self._authenticationService.tokenValue;
            self._authenticationService
              .getAccountInfo(token)
              .subscribe((x) => {});

            self._componentsService.SuccessSwal();
            self._router.navigate([this.callbackUrl]);
          },
          (err) => {
            self._componentsService.ErrorSwal();
            return;
          }
        );
    } else {
      this._userApiService.AddUser(this.formGroup.value).subscribe(
        (res) => {
          // this._componentsService.SuccessSwal();
          let tempGuid = res.data.resultData[0];
          const params = {
            isEdit: true,
            idResId: tempGuid,
            tablename: this.apiPath,
            collectionName: profileFieldName,
            ordinal: 0,
          };

          // cover file upload
          this.imageUploadComponent.uploadImage(params);

          if (this.coverIMGform) {
            self._fileApiService
              .AddCover(
                this.apiPath,
                tempGuid,
                profileFieldName,
                0,
                self.coverIMGform
              )
              .subscribe(
                (res) => {
                  self._componentsService.SuccessSwal();
                  self._router.navigate([this.callbackUrl]);
                },
                (err) => {
                  self._componentsService.ErrorSwal();
                }
              );
          }
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
    }
  }

  prepareCoverFile(formData): void {
    let itemGuid = this.userObj.id.toString();
    this.coverIMGform = formData;
  }

  changegender(value) {
    console.log(value);
    console.log(this.formGroup.value);
  }
}
