import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImageUploadService {
  uploadTriggered: EventEmitter<void> = new EventEmitter<void>();

  triggerUpload(): void {
    this.uploadTriggered.emit();
  }
}
