import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerComponent } from './banner.component';
import { ContentHeaderModule } from '../../../../layout/components/content-header/content-header.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '../../../components/components.module';

import { TranslateModule } from '@ngx-translate/core';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { AuthGuard } from '../../../../auth/helpers';
import { CoreCommonModule } from '@core/common.module';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { BannerCreateComponent } from './banner-create/banner-create.component';

const routes = [
  {
    path: 'cms/banner',
    component: BannerComponent,
    canActivate: [AuthGuard],
    data: { animation: 'banner-manage' },
  },
  {
    path: 'cms/banner/create',
    component: BannerCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'banner-create' },
  },
  {
    path: 'cms/banner/edit/:id',
    component: BannerCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'banner-edit' },
  },


  // {
  //   path: 'banner',
  //   component: BannerComponent,
  //   canActivate: [AuthGuard],
  //   data: { animation: 'banner-manage' },
  // },
  // {
  //   path: 'banner/create',
  //   component: BannerCreateComponent,
  //   canActivate: [AuthGuard],
  //   data: { animation: 'banner-create' },
  // },
  // {
  //   path: 'banner/edit/:id',
  //   component: BannerCreateComponent,
  //   canActivate: [AuthGuard],
  //   data: { animation: 'banner-edit' },
  // },
];

@NgModule({
  declarations: [
    BannerComponent,
    BannerCreateComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CoreCommonModule,
    CommonModule,
    ContentHeaderModule,
    FormsModule,
    ComponentsModule,
    CorePipesModule,
    TranslateModule,
    NgbPaginationModule,
    DataTablesModule
  ],
  exports: [
    BannerComponent,
    BannerCreateComponent
  ]
})
export class BannerModule { }
