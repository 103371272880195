import { CoreCommonModule } from '@core/common.module';
import { ContentCardComponent } from './content-card/content-card.component';
import { ContentListComponent } from './content-list/content-list.component';
import { BannerFormComponent } from './form/banner-form/banner-form.component';
import { BrandFormComponent } from './form/brand-form/brand-form.component';
import { CategoryFormComponent } from './form/category-form/category-form.component';
import { ClassFormComponent } from './form/class-form/class-form.component';
import { ContentFormComponent } from './form/content-form/content-form.component';
import { GroupFormComponent } from './form/group-form/group-form.component';
import { ItemFormComponent } from './form/item-form/item-form.component';
import { NewsFormComponent } from './form/news-form/news-form.component';
import { SubcategoryFormComponent } from './form/subcategory-form/subcategory-form.component';
import { SubclassFormComponent } from './form/subclass-form/subclass-form.component';
import { UserFormComponent } from './form/user-form/user-form.component';
import { LoadingComponent } from './loading/loading.component';
import { ModalComponent } from './modal/modal.component';
import { SearchPanelComponent } from './search-panel/search-panel.component';
import { CommonModule } from '@angular/common';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxSummernoteModule } from 'ngx-summernote';
import { NgModule } from '@angular/core';
import { AclFormComponent } from './form/acl-form/acl-form.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { MultiFileUploadComponent } from './multi-file-upload/multi-file-upload.component';
import { DataTablesModule } from 'angular-datatables';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RoleBadgeComponent } from './role-badge/role-badge.component';
import { RoleFormComponent } from './form/role-form/role-form.component';
import { FormTemplateComponent } from './form/form-template/form-template.component';
import { SingleFileUploadComponent } from './single-file-upload/single-file-upload.component';
import { HorizontalTimelineComponent } from './horizontal-timeline/horizontal-timeline.component';
import { DragulaModule } from 'ng2-dragula';
import { SearchPanelDocumentComponent } from './search-panel-document/search-panel-document.component';
import { SearchPanelStockComponent } from './search-panel-stock/search-panel-stock.component';
import { BarcodeGeneratorComponent } from './misc/barcode-generator/barcode-generator.component';
import { QrcodeGeneratorComponent } from './misc/qrcode-generator/qrcode-generator.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { NgxBarcodeModule } from 'ngx-barcode';
import { NgSelectModule } from '@ng-select/ng-select';
import { BlockUIModule } from 'ng-block-ui';
import { ItemSetFormComponent } from './form/item-set-form/item-set-form.component';
import { CustomDropzoneComponent } from './custom-dropzone/custom-dropzone.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AdjustItemModalComponent } from './adjust-item-modal/adjust-item-modal.component';
import { ContentChildDocumentComponent } from './content-child-document/content-child-document.component';
import { SearchPanelFrontendUserComponent } from './search-panel-frontend-user/search-panel-frontend-user.component';


@NgModule({
  declarations: [
    SearchPanelComponent,
    ContentCardComponent,
    ModalComponent,
    LoadingComponent,
    ContentListComponent,
    ItemFormComponent,
    GroupFormComponent,
    CategoryFormComponent,
    BannerFormComponent,
    NewsFormComponent,
    BrandFormComponent,
    ContentFormComponent,
    SubcategoryFormComponent,
    SubclassFormComponent,
    ClassFormComponent,
    UserFormComponent,
    AclFormComponent,
    ImageUploadComponent,
    MultiFileUploadComponent,
    RoleBadgeComponent,
    RoleFormComponent,
    FormTemplateComponent,
    SingleFileUploadComponent,
    HorizontalTimelineComponent,
    SearchPanelDocumentComponent,
    SearchPanelStockComponent,
    BarcodeGeneratorComponent,
    QrcodeGeneratorComponent,
    ItemSetFormComponent,
    CustomDropzoneComponent,
  
    AdjustItemModalComponent,
    ContentChildDocumentComponent,
    SearchPanelFrontendUserComponent,
  ],
  exports: [
    SearchPanelComponent,
    SearchPanelDocumentComponent,
    ContentCardComponent,
    ModalComponent,
    LoadingComponent,
    ContentListComponent,
    ItemFormComponent,
    GroupFormComponent,
    CategoryFormComponent,
    BannerFormComponent,
    NewsFormComponent,
    BrandFormComponent,
    ContentFormComponent,
    SubcategoryFormComponent,
    SubclassFormComponent,
    ClassFormComponent,
    UserFormComponent,
    AclFormComponent,
    ImageUploadComponent,
    MultiFileUploadComponent,
    RoleBadgeComponent,
    RoleFormComponent,
    FormTemplateComponent,
    HorizontalTimelineComponent,
    BarcodeGeneratorComponent,
    QrcodeGeneratorComponent,
    ItemSetFormComponent,
    CustomDropzoneComponent,
    NgSelectModule,
    ContentChildDocumentComponent,
    SearchPanelFrontendUserComponent,
  ],
  imports: [
    FormsModule,
    CoreCommonModule,
    CommonModule,
    CorePipesModule,
    RouterModule,
    NgbModule,
    TranslateModule,
    NgxDropzoneModule,
    NgxSummernoteModule,
    DataTablesModule,
    Ng2FlatpickrModule,
    ReactiveFormsModule,
    DragulaModule.forRoot(),
    FormsModule,
    NgxQRCodeModule,
    NgxBarcodeModule,
    NgSelectModule,
    DragDropModule,
    BlockUIModule.forRoot(),
  ],
})
export class ComponentsModule {}
