import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  componentName: string = 'BreadCrumbs.CMS.News';
  apiPath: string = 'News';
  pathUrl: string = '/cms/news';
  keyName: string = 'name';

  constructor() { }
}
