// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  apiUrl: 'https://sth-api.appintouch.net/api',
  // apiUrl: 'https://192.168.1.177:7150/api',
  // apiUrl: 'https://192.168.1.148:7148/api',
  // apiUrl: 'https://192.168.88.171:7149/api',
  appName: 'Stainless Hub CMS',
  appTitle: 'Stainless Hub CMS',
  appLogoImage: 'assets/images/logo/logo.png',
  appLogoNav: 'assets/images/logo/logo.png',
  defaultIMG: 'assets/images/general/no-image.png',

  // language list for content
  langDefault: 'th',
  langContent: ['th', 'en'],

  //transaction
  transaction: {
    issuerData: {
      name: 'Cocueco (Thailand) Co., Ltd.',
      branch: 'สำนักงานใหญ่',
      email: 'sales@cocueco.com',
      taxID: '0105561167749',
      website: 'https://www.repove.world/',
      telephone: '081 234 5679',
    },
    logoOnPaperPath: '../assets/images/logo/logo.png',
    isUseWht: false,
    isSplitIVfromSO: true,
    isSplitGRfromPO: true,
    isJoinIV_TX_RE: false,
    isQuantityDecimal: true,
  },

  //customer
  customer: {
    requiredDetail: false,
    requiredCentralContact: false,
  },

  limitFileSize: {
    maxImageSizeInBytes: 500 * 1024, //300KB
    maxVideoSizeInBytes: 5 * 1024 * 1024, //2MB
    maxFileSizeInBytes: 2 * 1024 * 1024, //2MB
  },

  menuList: {
    itemManage: false,
    cms: true,
    customer: false,
    transactionDoc: false,
    stock: false,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
