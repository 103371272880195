import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  NgbActiveModal,
  NgbModalConfig,
} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() public modalConfig: NgbModalConfig;

  @Input() isConfirm: boolean;
  @Input() title: string;
  @Input() detail: string;
  @Input() confirmUrl: string;

  @Output() callBackFunc: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    public activeModal: NgbActiveModal
  ) {}

  closeModal(): void {
    this.activeModal.close();
  }
  callBack(): void {
    this.callBackFunc.emit();
    this.closeModal();
    // this.router.navigate([this.confirmUrl]);
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
