import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from 'app/main/components/components.service';
import { OurClientService } from '../our-client.service';
import { ApiService } from 'app/main/service/api.service';
import { ContentListComponent } from 'app/main/components/content-list/content-list.component';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-our-client-partner',
  templateUrl: './our-client-partner.component.html',
  styleUrls: ['./our-client-partner.component.scss']
})
export class OurClientPartnerComponent implements OnInit {

  private _unsubscribeAll: Subject<any>;
  private dataSubscription: Subscription;

  componentName: string;
  apiPath: string;
  pathUrl: string;
  keyName: string;

  contentHeader: object;
  contentList: any[];

  isLoading: boolean;
  isGridView: boolean;
  isGoEditPage: boolean = true;
  isForGroupTemplate: boolean = false;
  activeStatus: string;
  searchText: string;
  searchData: { [k: string]: any } = {};

  public page = 1;
  public pageSize = 10;

  @ViewChild(ContentListComponent) contentListTable: ContentListComponent;

  constructor(
    private _apiService: ApiService,
    private _ourClientService: OurClientService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService
  ) {
    this._unsubscribeAll = new Subject();
    this.componentName = this._ourClientService.ourClientPartner.componentName;
    this.apiPath = this._ourClientService.ourClientPartner.apiPath;
    this.pathUrl = this._ourClientService.ourClientPartner.pathUrl;
    this.keyName = this._ourClientService.ourClientPartner.keyName;

    this.initData();
  }

  initData(obj?): void {
    this.SetLoadingState();
    this.contentList = [];

    if (obj) {
      this.searchText = obj.searchText ? obj.searchText : '';

      if (!obj.params) {
        this.searchData = { SortPath: this.keyName, Direction: 1, isDelete: false };
      } else {
        this.searchData = obj.params;
      }
    } else {
      this.searchText = '';
      this.searchData = { SortPath: this.keyName, Direction: 1, isDelete: false };
    }

    this.searchData.name = this.searchText

    if (this.contentListTable) {
      this.contentListTable.loadData(this.searchData)
    }

  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  listView() {
    this.isGridView = false;
  }

  gridView() {
    this.isGridView = true;
  }

  SearchFilter(val: string) {
    this.searchText = val;
    this.isLoading = false;
  }

  ActiveStatusFilter(val: string) {
    this.activeStatus = val;
    console.log(val);
  }

  SortFilter(key: string) {
    let objKey = JSON.parse(key);
    let SortPath = objKey.SortPath;
    let Direction = objKey.Direction;
  }

  exportXLSX() {
    this.contentListTable.ExportXLSX();
  }

  setPageSize(val: number): void {
    this.pageSize = val;
    console.log(this.pageSize);
  }

  openModal(modal: any): void {}

  setActiveContent(val): void {
    this._apiService
      .SetIsActiveData(this.apiPath, val.id, val.status)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          this._componentsService.SuccessSwal();
          this.isLoading = true;
          this.initData();
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );
  }

  deleteContent(guid: string): void {
    console.log('delele Complete' + guid);
    this._apiService
      .SoftDelete(this.apiPath, guid)
      .pipe()
      .subscribe(
        (res) => {
          this._componentsService.SuccessSwal();
          this.isLoading = true;
          this.initData();
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.contentHeader = {
      headerTitle: 'BreadCrumbs.CMS.Client',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe()
    }
  }

}
