import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonComponent } from './common.component';
import { TranslateModule } from '@ngx-translate/core';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSummernoteModule } from 'ngx-summernote';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { ComponentsModule } from 'app/main/components/components.module';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';

const routes: Routes = [
  {
    path: 'cms/common',
    component: CommonComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-common' },
  },
];

@NgModule({
  declarations: [CommonComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ContentHeaderModule,
    FormsModule,
    NgxSummernoteModule,
    ReactiveFormsModule,
    CorePipesModule,
    ComponentsModule,
    RouterModule.forChild(routes),
  ],
  exports: [CommonComponent],
})
export class CmsCommonModule {}
