export enum StockType {
  FG = 1,
  FOC = 2,
  Claim = 3,
  Clear = 4,
}

export const kStockType = [
  { value: StockType.FG, name: 'FG' },
  { value: StockType.FOC, name: 'FOC' },
  { value: StockType.Claim, name: 'Claim' },
  { value: StockType.Clear, name: 'Clear' },
];
