import { CoreMenu } from '@core/types';
import { environment } from 'environments/environment';

const isJoinIV_TX_RE = environment.transaction.isJoinIV_TX_RE;
const isShowMenuList = environment.menuList;

export const menu: CoreMenu[] = [
  {
    id: 'general-section',
    type: 'section',
    title: 'General.General',
    icon: 'bar-chart-2',
    children: [
      {
        id: 'home',
        title: 'Home',
        translate: 'General.Home',
        type: 'item',
        icon: 'home',
        url: 'home',
      },
      {
        id: 'settings',
        title: 'Settings',
        translate: 'General.Settings',
        type: 'item',
        icon: 'settings',
        url: 'settings',
      },

      {
        id: 'general-section',
        type: 'collapsible',
        title: 'General.UserGuide',
        icon: 'grid',
        children: [
          {
            id: 'userGuide-frontend',
            title: 'Frontend',
            translate: 'General.Frontend',
            type: 'item',
            url: 'user-guide/frontend',
          },
          {
            id: 'userGuide-cms',
            title: 'CMS',
            type: 'item',
            translate: 'General.CMS',
            url: 'user-guide/cms',
          },
        ],
      },
    ],
  },

  {
    id: 'itemmanage-section',
    type: 'section',
    title: 'General.ItemManagement',
    icon: 'bar-chart-2',
    hidden: !isShowMenuList.itemManage,
    role: ['SuperAdmin'],
    children: [
      {
        id: 'brand-management',
        translate: 'General.BrandManage',
        title: 'Manage Brand',
        hidden: !isShowMenuList.itemManage,
        type: 'item',
        icon: 'award',
        url: 'item-manage/brand',
      },
      {
        id: 'group-management-collapse',
        title: 'Manage Group',
        translate: 'General.GroupManage',
        hidden: !isShowMenuList.itemManage,
        type: 'collapsible',
        icon: 'tag',
        children: [
          {
            id: 'group-management',
            translate: 'General.Group',
            title: 'Group',
            hidden: !isShowMenuList.itemManage,
            type: 'item',
            icon: 'circle',
            url: 'item-manage/group',
          },
          {
            id: 'category-management',
            translate: 'General.Category',
            title: 'Category',
            hidden: !isShowMenuList.itemManage,
            type: 'item',
            icon: 'circle',
            url: 'item-manage/category',
          },
          {
            id: 'subcategory-management',
            translate: 'General.SubCategory',
            title: 'Sub-Category',
            hidden: !isShowMenuList.itemManage,
            type: 'item',
            icon: 'circle',
            url: 'item-manage/subcategory',
          },
          {
            id: 'class-management',
            translate: 'General.Class',
            title: 'Class',
            hidden: !isShowMenuList.itemManage,
            type: 'item',
            icon: 'circle',
            url: 'item-manage/class',
          },
          {
            id: 'subclass-management',
            translate: 'General.SubClass',
            title: 'Sub-Class',
            hidden: !isShowMenuList.itemManage,
            type: 'item',
            icon: 'circle',
            url: 'item-manage/subclass',
          },
        ],
      },
      {
        id: 'item-management',
        translate: 'General.ItemManage',
        title: 'Manage Item',
        hidden: !isShowMenuList.itemManage,
        type: 'item',
        icon: 'gift',
        url: 'item-manage/item',
      },
      {
        id: 'item-set-management',
        translate: 'General.ItemSetManage',
        title: 'Manage Item Set',
        hidden: !isShowMenuList.itemManage,
        type: 'item',
        icon: 'gift',
        url: 'item-manage/set',
      },
    ],
  },

  {
    id: 'content-section',
    type: 'section',
    title: 'CMS',
    icon: 'bar-chart-2',
    hidden: !isShowMenuList.cms,
    role: ['SuperAdmin'],
    children: [
      {
        id: 'home-management',
        title: 'Home',
        translate: 'General.Home',
        hidden: !isShowMenuList.cms,
        icon: 'trello',
        type: 'collapsible',
        children: [
          {
            id: 'home-detail-management',
            title: 'Home Detail',
            translate: 'BreadCrumbs.CMS.Detail',
            hidden: !isShowMenuList.cms,
            type: 'item',
            // icon: 'trello',
            url: 'cms/home',
          },
          {
            id: 'home-banner-management',
            title: 'Home Banner',
            translate: 'BreadCrumbs.CMS.HomeBanner',
            hidden: !isShowMenuList.cms,
            type: 'item',
            // icon: 'trello',
            url: 'cms/homeBanner',
          },
        ],
      },
      {
        id: 'about-us-management',
        title: 'About Us',
        translate: 'BreadCrumbs.Manage.AboutUs',
        hidden: !isShowMenuList.cms,
        icon: 'trello',
        type: 'collapsible',
        children: [
          {
            id: 'about',
            title: 'Detail',
            translate: 'BreadCrumbs.CMS.Detail',
            hidden: !isShowMenuList.cms,
            type: 'item',
            // icon: 'book-open',
            url: 'cms/about',
          },
          {
            id: 'about',
            title: 'Certified',
            translate: 'BreadCrumbs.CMS.Certified',
            hidden: !isShowMenuList.cms,
            type: 'item',
            // icon: 'book-open',
            url: 'cms/about-certified',
          },
          {
            id: 'about',
            title: 'History',
            translate: 'BreadCrumbs.CMS.History',
            hidden: !isShowMenuList.cms,
            type: 'item',
            // icon: 'book-open',
            url: 'cms/about-history',
          },
          {
            id: 'about',
            title: 'Team',
            translate: 'BreadCrumbs.CMS.Team',
            hidden: !isShowMenuList.cms,
            type: 'item',
            // icon: 'book-open',
            url: 'cms/about-team',
          },

          {
            id: 'about',
            title: 'Team Activity',
            translate: 'BreadCrumbs.CMS.TeamActivity',
            hidden: !isShowMenuList.cms,
            type: 'item',
            // icon: 'book-open',
            url: 'cms/about-activity',
          },
        ],
      },

      {
        id: 'our-client-management',
        title: 'Our Client',
        translate: 'BreadCrumbs.Manage.OurClient',
        hidden: !isShowMenuList.cms,
        icon: 'trello',
        type: 'collapsible',
        children: [
          {
            id: 'our-client-detail',
            title: 'Detail',
            translate: 'BreadCrumbs.CMS.Detail',
            hidden: !isShowMenuList.cms,
            type: 'item',
            // icon: 'book-open',
            url: 'cms/our-client',
          },
          {
            id: 'our-client-certified',
            title: 'Client',
            translate: 'BreadCrumbs.CMS.Client',
            hidden: !isShowMenuList.cms,
            type: 'item',
            // icon: 'book-open',
            url: 'cms/our-client-partner',
          },
          {
            id: 'our-client-history',
            title: 'Performance',
            translate: 'BreadCrumbs.CMS.Performance',
            hidden: !isShowMenuList.cms,
            type: 'item',
            // icon: 'book-open',
            url: 'cms/our-client-performance',
          },
        ],
      },

      {
        id: 'cms-common',
        title: 'E-Catelog',
        translate: 'General.Common',
        hidden: !isShowMenuList.cms,
        type: 'item',
        icon: 'coffee',
        url: 'cms/common',
      },

      {
        id: 'product-service-management',
        title: 'Product Service',
        translate: 'BreadCrumbs.CMS.ProductService',
        hidden: !isShowMenuList.cms,
        icon: 'trello',
        type: 'collapsible',
        children: [
          {
            id: 'product-landing-detail',
            title: 'Detail',
            translate: 'BreadCrumbs.CMS.Detail',
            hidden: !isShowMenuList.cms,
            type: 'item',
            // icon: 'book-open',
            url: 'cms/product-service/landing',
          },
          {
            id: 'cms-product',
            title: 'Product',
            translate: 'BreadCrumbs.CMS.Product',
            hidden: !isShowMenuList.cms,
            type: 'item',
            // icon: 'book-open',
            url: 'cms/product-service/product',
          },
          {
            id: 'cms-service',
            title: 'Service',
            translate: 'BreadCrumbs.CMS.Service',
            hidden: !isShowMenuList.cms,
            type: 'item',
            // icon: 'book-open',
            url: 'cms/product-service/service',
          },
          {
            id: 'cms-innovation',
            title: 'Innovation',
            translate: 'BreadCrumbs.CMS.Innovation',
            hidden: !isShowMenuList.cms,
            type: 'item',
            // icon: 'book-open',
            url: 'cms/product-service/innovation',
          },
        ],
      },

      {
        id: 'news-management',
        title: 'News',
        translate: 'General.NewsEvent',
        hidden: !isShowMenuList.cms,
        icon: 'trello',
        type: 'collapsible',
        children: [
          {
            id: 'news-landing-management',
            title: 'Landing Page',
            translate: 'General.LandingPage',
            hidden: !isShowMenuList.cms,
            type: 'item',
            // icon: 'trello',
            url: 'cms/news-landing',
          },
          {
            id: 'news-management',
            title: 'News',
            translate: 'General.NewsEvent',
            hidden: !isShowMenuList.cms,
            type: 'item',
            // icon: 'trello',
            url: 'cms/news',
          },
        ],
      },

      {
        id: 'contact-management',
        title: 'Contact',
        translate: 'General.Contact',
        hidden: !isShowMenuList.cms,
        type: 'item',
        icon: 'phone',
        url: 'cms/contact',
      },

      {
        id: 'privacy_policy',
        title: 'Privacy & Policy',
        translate: 'General.PrivacyPolicy',
        hidden: !isShowMenuList.cms,
        type: 'item',
        icon: 'user-check',
        // icon: 'user-check',
        url: 'cms/privacy-policy',
      },

      // {
      //   id: 'content-section',
      //   title: 'Manage Content',
      //   translate: 'General.ContentManage',
      //   hidden: !isShowMenuList.cms,
      //   icon: 'bar-chart-2',
      //   type: 'collapsible',
      //   children: [

      //   ],
      // },
    ],
  },
  {
    id: 'customer-section',
    type: 'section',
    title: 'Customer',
    translate: 'General.Customer',
    hidden: !isShowMenuList.customer,
    icon: 'bar-chart-2',
    role: ['SuperAdmin'],
    children: [
      {
        id: 'customer',
        title: 'Customer',
        translate: 'General.Customer',
        hidden: !isShowMenuList.customer,
        type: 'item',
        icon: 'users',
        url: 'customer',
      },
    ],
  },

  {
    id: 'transaction',
    type: 'section',
    title: 'General.Sale', // title: 'Transaction',
    // translate: 'MENU.OTHERS.SECTION',
    hidden: !isShowMenuList.transactionDoc,
    icon: 'box',
    children: [
      {
        id: 'document',
        title: 'Revenue Document',
        translate: 'General.RevenueDocument',
        hidden: !isShowMenuList.transactionDoc,
        icon: 'paperclip',
        type: 'collapsible',
        children: [
          {
            id: 'quotation',
            title: 'Quotation',
            translate: 'Menu.Document.QO',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'quotation-create',
                title: 'Create',
                translate: 'General.Create',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/quotation/create',
              },
              {
                id: 'quotation-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/quotation/view',
              },
            ],
          },

          {
            id: 'saleorder',
            title: 'Sale Order',
            translate: 'Menu.Document.SO',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'saleorder-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/saleorder/view',
              },
            ],
          },

          {
            id: 'invoice',
            title: isJoinIV_TX_RE ? 'Invoice/Tax Invoice/Receipt' : 'Invoice',
            translate: isJoinIV_TX_RE
              ? 'Document.IV_TX_RE'
              : 'Menu.Document.IV',
            icon: 'circle',
            type: 'collapsible',
            hidden: !isShowMenuList.transactionDoc,
            children: [
              {
                id: 'invoice-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/invoice/view',
              },
            ],
          },

          {
            id: 'tax',
            title: 'Tax Invoice',
            translate: 'Menu.Document.TX',
            icon: 'circle',
            type: 'collapsible',
            hidden: isJoinIV_TX_RE || !isShowMenuList.transactionDoc,
            children: [
              {
                id: 'tax-view',
                title: 'View',
                translate: 'General.ViewAll',
                type: 'item',
                url: 'document/tax/view',
              },
            ],
          },

          {
            id: 'receipt',
            title: 'Receipt',
            translate: 'Menu.Document.RC',
            icon: 'circle',
            type: 'collapsible',
            hidden: isJoinIV_TX_RE || !isShowMenuList.transactionDoc,
            children: [
              {
                id: 'receipt-view',
                title: 'View',
                translate: 'General.ViewAll',
                type: 'item',
                url: 'document/receipt/view',
              },
            ],
          },

          {
            id: 'credit-note',
            title: 'Credit Note',
            translate: 'Menu.Document.CNT',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'credit-note-create',
                title: 'Create',
                translate: 'General.Create',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/credit-note/create',
              },
              {
                id: 'credit-note-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/credit-note/view',
              },
            ],
          },

          {
            id: 'debit-note',
            title: 'Debit Note',
            translate: 'Menu.Document.DBN',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'debit-note-create',
                title: 'Create',
                translate: 'General.Create',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/debit-note/create',
              },
              {
                id: 'debit-note-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/debit-note/view',
              },
            ],
          },
        ],
      },
      {
        id: 'document-expenses',
        title: 'Expenses Document',
        translate: 'General.ExpensesDocument',
        hidden: !isShowMenuList.transactionDoc,
        icon: 'paperclip',
        type: 'collapsible',
        children: [
          {
            id: 'purchase-request',
            title: 'Purchase Request',
            translate: 'Menu.Document.PR',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'pr-create',
                title: 'Create',
                translate: 'General.Create',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/purchase-request/create',
              },
              {
                id: 'pr-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/purchase-request/view',
              },
            ],
          },
          {
            id: 'purchase-order',
            title: 'Purchase Order',
            translate: 'Menu.Document.PO',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'po-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/purchase-order/view',
              },
            ],
          },
          {
            id: 'goods-receive',
            title: 'Goods Receive',
            translate: 'Menu.Document.GR',
            hidden: !isShowMenuList.transactionDoc,
            icon: 'circle',
            type: 'collapsible',
            children: [
              {
                id: 'gr-view',
                title: 'View',
                translate: 'General.ViewAll',
                hidden: !isShowMenuList.transactionDoc,
                type: 'item',
                url: 'document/goods-receive/view',
              },
            ],
          },
        ],
      },
    ],
  },

  {
    id: 'stock-management-section',
    type: 'section',
    title: 'Stock',
    hidden: !isShowMenuList.stock,
    translate: 'General.Stock',
    icon: 'bar-chart-2',
    role: ['SuperAdmin'],
    children: [
      {
        id: 'stock-management',
        title: 'Stock',
        hidden: !isShowMenuList.stock,
        translate: 'General.Stock',
        type: 'item',
        icon: 'box',
        url: 'stock',
      },
    ],
  },

  {
    id: 'control-panel-section',
    type: 'section',
    title: 'Control Panel',
    translate: 'General.ControlPanel',
    icon: 'bar-chart-2',
    role: ['SuperAdmin'],
    children: [
      // {
      //   id: 'trash',
      //   title: 'Trash',
      //   translate: 'General.Trash',
      //   type: 'item',
      //   icon: 'trash-2',
      //   url: 'admin/trash',
      // },
      // {
      //   id: 'acl-management',
      //   title: 'Manage ACL',
      //   translate: 'General.ACLManage',
      //   type: 'item',
      //   icon: 'key',
      //   url: 'admin/acl',
      // },
      {
        id: 'role-management',
        title: 'Manage Roles',
        translate: 'General.RolesManage',
        type: 'item',
        icon: 'key',
        url: 'admin/roleset',
      },
      {
        id: 'user-management',
        title: 'Manage User',
        translate: 'General.UserManage',
        type: 'item',
        icon: 'user',
        url: 'admin/users',
      },
    ],
  },
];
