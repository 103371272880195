import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../service/api.service';
import { TranslateService } from '@ngx-translate/core';
import { BannerService } from './banner.service';
import { ComponentsService } from '../../../components/components.service';
import Banner from '../../../model/Banner';
import { Subject } from 'rxjs';
import { ContentListComponent } from 'app/main/components/content-list/content-list.component';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  private _unsubscribeAll: Subject<any>;
  componentName: string;
  apiPath: string;
  pathUrl: string;
  keyName: string;

  contentHeader: object;
  contentList: Banner[];

  isLoading: boolean;
  isGridView: boolean;
  isGoEditPage: boolean = true;
  isForGroupTemplate: boolean = false;
  activeStatus: string;
  searchText: string;
  selectedColumns: string[];

  public page = 1;
  public pageSize = 10;

  @ViewChild(ContentListComponent) contentListTable: ContentListComponent;

  constructor(
    private _apiService: ApiService,
    private _contentTemplateService: BannerService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService
  ) {
    this._unsubscribeAll = new Subject();
    this.componentName = this._contentTemplateService.componentName;
    this.apiPath = this._contentTemplateService.apiPath;
    this.pathUrl = this._contentTemplateService.pathUrl;
    this.keyName = this._contentTemplateService.keyName;

    this.initData();
  }

  initData(obj?): void {
    this.selectedColumns = ["NO.", "NAME", "STATUS", "UPDATE DATE"];
    this.SetLoadingState();
    let params;
    this.contentList = [];

    if (obj) {
      this.searchText = obj.searchText ? obj.searchText : '';
      if (!obj.params) {
        params = { SortPath: this.keyName, Direction: 0, isDelete: false };
      } else {
        params = obj.params;
      }
    } else {
      this.searchText = '';
      params = { SortPath: this.keyName, Direction: 0, isDelete: false };
    }

    this._apiService.GetAllData(this.apiPath, params).subscribe((res) => {
      this.contentList = res.data.resultData;

      if (this.searchText) {
        this.contentList = this.contentList.filter((item) =>
          item.name.toLowerCase().includes(this.searchText.toLowerCase())
        );
      }

      this._componentsService.setContentList(this.contentList);
      this.SetLoadedState();
    });
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  listView() {
    this.isGridView = false;
  }

  gridView() {
    this.isGridView = true;
  }

  SearchFilter(val: string) {
    this.searchText = val;
    this.isLoading = false;
  }

  ActiveStatusFilter(val: string) {
    this.activeStatus = val;
    console.log(val);
  }

  SortFilter(key: string) {
    let objKey = JSON.parse(key);
    let SortPath = objKey.SortPath;
    let Direction = objKey.Direction;
    console.log(key);

    // this.isLoading = true;
    // this._apiService.GetAllData(this.apiPath, objKey.SortPath, objKey.Direction).subscribe((res) => {
    //   this.contentList = res.data.resultData;
    //   this.isLoading = false;
    // });
  }

  exportXLSX() {
    this.contentListTable.ExportXLSX();
  }

  setPageSize(val: number): void {
    this.pageSize = val;
    console.log(this.pageSize);
  }

  openModal(modal: any): void {}

  setActiveContent(val): void {
    this._apiService
      .SetIsActiveData(this.apiPath, val.id, val.status)
      .subscribe(
        (res) => {
          this._componentsService.SuccessSwal();
          this.isLoading = true;
          this.initData();
        },
        (err) => {
          this._componentsService.ErrorSwal();
        }
      );
  }

  deleteContent(guid: string): void {
    console.log('delele Complete' + guid);
    this._apiService.SoftDelete(this.apiPath, guid).subscribe(
      (res) => {
        this._componentsService.SuccessSwal();
        this.isLoading = true;
        this.initData();
      },
      (err) => {
        this._componentsService.ErrorSwal();
      }
    );
  }

  ngOnInit(): void {
    this.isLoading = true;

    this.contentHeader = {
      headerTitle: 'BreadCrumbs.CMS.Banner',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    this.initData();
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
