import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AclModule } from './acl/acl.module';
import { RolesetModule } from './roleset/roleset.module';
import { UsersModule } from './users/users.module';
import { TruncateModule } from './truncate/truncate.module';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    AclModule,
    RolesetModule,
    UsersModule,
    TruncateModule,
  ]
})
export class AdminModule { }
