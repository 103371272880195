import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdpaModule } from './pdpa/pdpa.module';
import { NewsModule } from './news/news.module';
import { BannerModule } from './banner/banner.module';
import { CoreCommonModule } from '@core/common.module';
import { ComponentsModule } from 'app/main/components/components.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { HomeModule } from './home/home.module';
import { CookiePolicyModule } from './cookie-policy/cookie-policy.module';
import { ContactModule } from './contact/contact.module';
import { AboutModule } from './about/about.module';
import { TranslateModule } from '@ngx-translate/core';
import { NewsLandingModule } from './news-landing/news-landing.module';
import { CmsCommonModule } from './common/cms-common.module';
import { RouterModule, Routes } from '@angular/router';
import { OurClientModule } from './our-client/our-client.module';
import { ProductServiceModule } from './product-service/product-service.module';

const routes: Routes = [

];
@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    ComponentsModule,
    TranslateModule,
    PdpaModule,
    NewsModule,
    NewsLandingModule,
    BannerModule,
    ContentHeaderModule,
    HomeModule,
    CookiePolicyModule,
    ContactModule,
    AboutModule,
    CmsCommonModule,
    OurClientModule,
    ProductServiceModule,
    RouterModule.forChild(routes),
  ],
  exports: [],
})
export class CmsModule {}
