enum FileTypeFilter {
  All = 0,
  Images = 1,
  PDF_Docs_XML = 2,
  AllExceptZipRar = 3,
}

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CoreConfigService } from '@core/services/config.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { DragulaService } from 'ng2-dragula';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-custom-dropzone',
  templateUrl: './custom-dropzone.component.html',
  styleUrls: ['./custom-dropzone.component.scss'],
})
export class CustomDropzoneComponent implements OnChanges, OnDestroy, OnInit {
  @ViewChild('fileInputDropzone') fileInput: ElementRef;

  private _unsubscribeAll: Subject<any> = new Subject();
  fileDataUrls: string[] = [];
  private originalFiles: File[] = []; // Store original File objects
  overDrag: Boolean = false;
  AlertFiles: boolean = false;
  filesOrder: number[] = [];
  @Input() media: FileList | null = null;
  @Input() typeFiles: number = 0;
  @Input() editing: boolean = false;
  @Input() countMedia: number = 5;
  @Input() uniqueName: string;

  @Input() moveParallelFiles: boolean = false;

  handleBox: Boolean = false;

  isNotFile = false;
  @Output() isNotFileChange = new EventEmitter<boolean>();
  @Output() moveParallelFunction = new EventEmitter<any>();

  imageLoading: Boolean = false;

  public coreConfig: any;

  files: {
    name: string;
    type: string;
    size: number;
    url: SafeUrl; // Change the type to SafeUrl
  }[] = [];
  allowedFileTypes = [
    [],
    ['image/*'],
    [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'text/xml',
    ],
    ['application/zip', 'application/x-rar-compressed'],
  ];

  @Output() filesUploaded: EventEmitter<File[]> = new EventEmitter<File[]>();
  constructor(
    public elementRef: ElementRef,
    private dragulaService: DragulaService,
    private sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    private _globalFuncService: GlobalFuncService,
    private _coreConfigService: CoreConfigService
  ) {}

  logFileOrder() {}
  ngOnDestroy(): void {
    this.dragulaService.destroy(this.uniqueName);

    this.dragulaService.destroy('handle');
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    // No need to revoke object URLs when using base64
  }
  openFileInput(event: MouseEvent) {
    // Check if the click originated from an empty space within the parent
    if (event.target === event.currentTarget) {
      // Your action to perform when clicking on the empty space
      this.fileInput.nativeElement.click();
    }
  }

  onChangePosition(event: any) {
    console.log(event);

    this.filesUploaded.emit(this.originalFiles);
  }
  onFileChange(event: any) {
    console.log('asdfasdfasdhfajsdkfhjklhasdfjkl');

    const selectedFiles: File[] = event.target.files;
    // Clear existing files before processing
    // this.files = [];
    // this.originalFiles = [];

    this.AlertFiles = false;
    for (const file of selectedFiles) {
      let url: string;

      if (file.type.startsWith('image/') || file.type === 'image/svg+xml') {
        url = URL.createObjectURL(file);
      } else {
        url = '/assets/images/logo/logo.png';
      }

      if (this.originalFiles.length + 1 <= this.countMedia) {
        this.files.push({
          name: file.name,
          type: file.type,
          size: file.size,
          url: url,
        });
        this.originalFiles.push(file);
        if (this.originalFiles.length > this.countMedia) {
          this.AlertFiles = true;

          return;
        }
      } else {
        return;
      }
    }

    // Process each selected file and create URLs

    this.handleBox = false;
    this.filesUploaded.emit(this.originalFiles);
    console.log('files');
  }

  private isImageFile(file: File): boolean {
    return file.type.startsWith('image/') || file.type === 'image/svg+xml';
  }

  onDragOver(event: DragEvent): void {
    this.overDrag = true;
    event.preventDefault();

    console.log('onDragOver');
  }

  onDrop(event: DragEvent): void {
    console.log(event);
    console.log('onDrop');

    this.overDrag = false;
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      this.AlertFiles = false;
      for (let i = 0; i < files.length; i++) {
        let url: string;
        if (
          files[i].type.startsWith('image/') ||
          files[i].type === 'image/svg+xml'
        ) {
          url = URL.createObjectURL(files[i]);
        } else {
          url = '/assets/images/logo/logo.png';
        }
        if (this.originalFiles.length + 1 <= this.countMedia) {
          this.files.push({
            name: files[i].name,
            type: files[i].type,
            size: files[i].size,
            url: url,
          });

          this.originalFiles.push(files[i]);
          if (this.originalFiles.length > this.countMedia) {
            this.AlertFiles = true;

            return;
          }
        } else {
          return;
        }
      }
      this.handleBox = false;
      this.filesUploaded.emit(this.originalFiles);
    }
  }

  ngOnInit(): void {
    if (!this.uniqueName) {
      this.uniqueName = 'FILES';
    }
    console.log(this.dragulaService.find(this.uniqueName));
    if (!this.dragulaService.find(this.uniqueName)) {
      console.log('BBBBBBBBBBBBBBBBBBBBBBB');
      this.dragulaService.createGroup(this.uniqueName, {
        moves: (el, container, handle) => handle.classList.contains('handle'),
      });
    }

    this.dragulaService
      .dropModel(this.uniqueName)
      .subscribe(({ el, target, source, sourceIndex, targetIndex }) => {
        console.log('dropped');
        if (sourceIndex !== -1 && targetIndex !== -1) {
          const draggedFile = this.files.splice(sourceIndex, 1)[0];
          this.files.splice(targetIndex, 0, draggedFile);

          const draggedOriginalFiles = this.originalFiles.splice(sourceIndex, 1)[0];
          this.originalFiles.splice(targetIndex, 0, draggedOriginalFiles);

          this.logFileOrder();

          this.handleBox = false;
          this.filesUploaded.emit(this.originalFiles);
        }
      });

    this._coreConfigService.config.subscribe((config) => {
      this.coreConfig = config;
    });
  }
  ngAfterViewInit() {}

  handle() {
    this.handleBox = true;
  }

  startLoading() {
    this.imageLoading = true;
  }

  ngOnChangesConfig(data: any) {
    this.media = data;
    if (data && this.media) {
      switch (this.typeFiles) {
        case FileTypeFilter.All:
          // Do something for All typeFiles
          break;
        case FileTypeFilter.Images:
          // Do something for Images typeFiles
          break;
        case FileTypeFilter.PDF_Docs_XML:
          // Do something for PDF_Docs_XML typeFiles
          break;
        case FileTypeFilter.AllExceptZipRar:
          // Do something for AllExceptZipRar typeFiles
          break;
        default:
          break;
      }

      this.originalFiles = Array.from(this.media);
      this.files = Array.from(this.media).map((file) => {
        let url: string;

        if (file.type.startsWith('image/') || file.type === 'image/svg+xml') {
          url = URL.createObjectURL(file);
        } else {
          url = '/assets/images/logo/logo.png';
        }

        this.imageLoading = false;

        // if (this.onFileChange.length == changes.media) {
        return {
          name: file.name,
          type: file.type,
          size: file.size,
          url: url,
        };
        // }
      });

      // Trigger change detection manually
      this.changeDetectorRef.detectChanges();
      this.checkNotFile();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnChangesConfig(changes.media.currentValue);
  }

  private dataURLtoFile(dataURL: string, filename: string): File {
    const arr = dataURL.split(',');
    if (arr.length < 2) {
      throw new Error('Invalid data URL format');
    }

    const mimeMatch = arr[0].match(/:(.*?);/);
    if (!mimeMatch || mimeMatch.length < 2) {
      throw new Error('Invalid data URL format');
    }

    const mime = mimeMatch[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  removeFile(index: number) {
    if (index >= 0 && index < this.files.length) {
      const removedFile = this.files.splice(index, 1)[0];

      this.originalFiles.splice(index, 1);

      this.filesUploaded.emit(this.originalFiles);
    }
  }

  bytesToSizes(size: number): string {
    return this._globalFuncService.bytesToSize(size);
  }

  onFilesOrderChange(newOrder: number[]) {
    this.filesOrder = newOrder;
    console.log('File order changed:', this.filesOrder);
  }
  checkNotFile() {
    if (!this.media || this.media.length < 1) {
      this.isNotFile = true;
    } else {
      this.isNotFile = false;
      // this.imageLoading = false;
    }
    this.isNotFileChange.emit(this.isNotFile);
  }
}
