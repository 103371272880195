import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormTemplateComponent } from 'app/main/components/form/form-template/form-template.component';
import { CmsFormConfig } from 'app/main/config/CMSFormConfig';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { ApiService } from 'app/main/service/api.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AboutService } from '../../about.service';

@Component({
  selector: 'app-about-history-formpage',
  templateUrl: './about-history-formpage.component.html',
  styleUrls: ['./about-history-formpage.component.scss']
})
export class AboutHistoryFormpageComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();
  private dataSubscription: Subscription;

  contentHeader: object;
  isLoading: boolean;

  apiPath: string;
  itemID: string;

  itemObj: any;
  config: ICMSConfig;

  @ViewChild('formTemplate') formTemplate: FormTemplateComponent;

  constructor(
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _apiService: ApiService,
    private _cmsFormConfig: CmsFormConfig,
    private _aboutService: AboutService
  ) {
    this.apiPath = this._aboutService.aboutHistory.apiPath;
    this.itemID = this._route.snapshot.paramMap.get('id');

    this.config = this._cmsFormConfig.config[this.apiPath];
    this.itemObj = this._cmsFormConfig.config[this.apiPath].model;

    if (this.itemID) {
      this.GetItemData(this.apiPath, this.itemID);
    }
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'BreadCrumbs.CMS.History',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
  }

  GetItemData(apiPath, itemID): void {
    var self = this;

    this.dataSubscription = this._apiService
      .GetDataById(apiPath, itemID)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        self.itemObj = res.data.resultData[0];
        self.formTemplate.setFormValue(self.itemObj);
        console.log(self.itemObj);
      });
  }
}
