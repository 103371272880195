import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreCommonModule } from '@core/common.module';
import { GroupModule } from './group/group.module';
import { ItemModule } from './item/item.module';
import { CategoryModule } from './category/category.module';
import { SubcategoryModule } from './subcategory/subcategory.module';
import { ClassModule } from './class/class.module';
import { SubclassModule } from './subclass/subclass.module';
import { BrandModule } from './brand/brand.module';
import { ItemSetModule } from './item-set/item-set.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    GroupModule,
    CategoryModule,
    ItemModule,
    SubcategoryModule,
    ClassModule,
    SubclassModule,
    BrandModule,
    ItemSetModule
  ],
})
export class ItemManageModule {}
