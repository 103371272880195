import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import Category from 'app/main/model/Category';
import Class from 'app/main/model/Class';
import Group from 'app/main/model/Group';
import SubCategory from 'app/main/model/SubCategory';
import SubClass from 'app/main/model/SubClass';
import { ApiService } from 'app/main/service/api.service';

@Component({
  selector: 'app-search-panel',
  templateUrl: './search-panel.component.html',
  styleUrls: ['./search-panel.component.scss'],
})
export class SearchPanelComponent implements OnInit {
  enumShowMode = {
    Child: 'child',
    Item: 'item',
    Content: 'content',
  };

  searchText: string;
  activeStatus: string;

  roleSetName: any = 'All';
  groupId: any = 'All';
  categoryId: any = 'All';
  subCategoryId: any = 'All';
  classId: any = 'All';
  subClassId: any = 'All';

  roleSetList: any[] = [];

  groupList: Group[] = [];
  categoryList: Category[] = [];
  subCategoryList: SubCategory[] = [];
  classList: Class[] = [];
  subClassList: SubClass[] = [];

  @Input() pathUrl: string;
  @Input() isGridView: boolean;
  @Input() forCompanentName: string;
  @Input() isChildItemView: boolean;
  @Input() isContentView: boolean;
  @Input() isForGroupTemplate: boolean;
  @Input() isShowChildList: boolean;
  @Input() isSortByNewest: boolean;
  @Input() showMode: string;
  @Input() isShowSortByGroup: boolean = false;
  @Input() notShowSwitchView: boolean = false;
  @Input() notForContentMode: boolean = false;

  @Input() isShowGroup: boolean;
  @Input() isShowCategory: boolean;
  @Input() isShowSubCategory: boolean;
  @Input() isShowClass: boolean;
  @Input() isShowSubClass: boolean;

  @Input() isShowRoleSet: boolean = false;

  @Input() loadAllGroupData: boolean;
  @Input() loadAllCategoryData: boolean;
  @Input() loadAllSubCategoryData: boolean;
  @Input() loadAllClassData: boolean;
  @Input() loadAllSubClassData: boolean;
  @Input() notShowCreateBtn: boolean = false;

  @Output('SearchFilter') searchFilterFunc: EventEmitter<any> =
    new EventEmitter();
  @Output('ActiveStatusFilter') activeStatusFilterFunc: EventEmitter<any> =
    new EventEmitter();
  @Output('SortFilter') sortFilterFunc: EventEmitter<any> = new EventEmitter();

  @Output('childListView') childListViewFunc: EventEmitter<any> =
    new EventEmitter();
  @Output('itemListView') itemListViewFunc: EventEmitter<any> =
    new EventEmitter();
  @Output('contentView') contentViewFunc: EventEmitter<any> =
    new EventEmitter();

  @Output('listView') listViewFunc: EventEmitter<any> = new EventEmitter();
  @Output('gridView') gridViewFunc: EventEmitter<any> = new EventEmitter();
  @Output('SetLoading') setLoadingFunc: EventEmitter<any> = new EventEmitter();
  @Output('exportXLSX') exportXLSXFunc: EventEmitter<any> = new EventEmitter();
  @Output('setPageSize') setPageSizeFunc: EventEmitter<any> =
    new EventEmitter();
  @Output('callDataFromAPI') callDataFromAPIFunc: EventEmitter<any> =
    new EventEmitter();

  @ViewChild('searchbar') searchBar: ElementRef;
  @ViewChild('activeFil') activeFil: ElementRef;
  @ViewChild('sortFil') sortFil: ElementRef;

  @ViewChild('selectRoleSet') selectRoleSet: ElementRef;

  @ViewChild('selectGroup') selectGroup: ElementRef;
  @ViewChild('selectCategory') selectCategory: ElementRef;
  @ViewChild('selectSubCategory') selectSubCategory: ElementRef;
  @ViewChild('selectClass') selectClass: ElementRef;
  @ViewChild('selectSubClass') selectSubClass: ElementRef;

  constructor(private _apiService: ApiService) {
    if (this.isShowSortByGroup) {
      this.getGroupData();
    }
  }

  timeout: any = null;
  isTyping: boolean = false;

  ResetSearch(): void {
    //inputsearch reset
    this.searchBar.nativeElement.value = '';
    //activestatus reset
    this.activeFil.nativeElement.value = 'all';

    // if (this.isSortByNewest) {
    //   this.sortFil.nativeElement.value =
    //   '{"SortPath":"UpdateDate","Direction":1,"isDelete": false}';
    // } else {
    //   this.sortFil.nativeElement.value =
    //   '{"SortPath":"Name","Direction":0,"isDelete": false}';
    // }
    

    this.resetRoleSet();
    this.resetGroup();
    this.resetCategory();
    this.resetSubCategory();
    this.resetClass();
    this.resetSubClass();

    this.callDataFromAPIFunc.emit();
  }

  Submit(): void {
    let params: any = {};
    if (this.roleSetName != 'All') {
      params.roleSetName = this.roleSetName;
    }

    if (this.groupId != 'All') {
      params.groupId = this.groupId;
    }

    if (this.categoryId != 'All') {
      params.categoryId = this.categoryId;
    }

    if (this.subCategoryId != 'All') {
      params.subCategoryId = this.subCategoryId;
    }

    if (this.classId != 'All') {
      params.classId = this.classId;
    }

    if (this.subClassId != 'All') {
      params.subClassId = this.subClassId;
    }

    // let sortObj = JSON.parse(this.sortFil.nativeElement.value);

    // params.SortPath = sortObj.SortPath;
    // params.Direction = sortObj.Direction;
    // params.isDelete = sortObj.isDelete;

    const activeStr = this.activeFil.nativeElement.value;
    if (activeStr === 'active') {
      params.isActive = true;
    } else if (activeStr === 'inactive') {
      params.isActive = false;
    }

    let obj = {
      searchText: this.searchBar.nativeElement.value
        ? this.searchBar.nativeElement.value
        : '',
      params: params,
    };
    console.log(obj);
    this.callDataFromAPIFunc.emit(obj);
  }

  ExportXLSX(): void {
    this.exportXLSXFunc.emit();
  }

  childListView(): void {
    this.childListViewFunc.emit();
  }

  itemListView(): void {
    this.itemListViewFunc.emit();
  }

  contentView(): void {
    this.contentViewFunc.emit();
  }

  listView(): void {
    this.listViewFunc.emit();
  }

  gridView(): void {
    this.gridViewFunc.emit();
  }

  getGroupData(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('group', { isDelete: false })
        .subscribe((res) => {
          self.groupList = res.data.resultData;
        });
    });
  }

  resetRoleSet(): void {
    this.roleSetName = 'All';
  }

  resetGroup(): void {
    this.groupId = 'All';
  }

  resetCategory(): void {
    this.categoryId = 'All';
    this.categoryList = [];
  }

  resetSubCategory(): void {
    this.subCategoryId = 'All';
    this.subCategoryList = [];
  }

  resetClass(): void {
    this.classId = 'All';
    this.classList = [];
  }

  resetSubClass(): void {
    this.subClassId = 'All';
    this.subClassList = [];
  }

  getRoleSet(): void {
    this._apiService
      .GetAllData('roleSet')

      .subscribe((res) => {
        this.roleSetList = res.data.resultData;
      });
  }

  getCategoryData(selectedGroupId?): void {
    var self = this;
    var params: { [k: string]: any } = {};

    this.resetCategory();
    this.resetSubCategory();
    this.resetClass();
    this.resetSubClass();

    if (selectedGroupId) {
      params.groupId = selectedGroupId;
    }
    params.isDelete = false;

    this._apiService.GetAllData('category', params).subscribe((res) => {
      self.categoryList = res.data.resultData;
    });
  }

  getSubCategoryData(selectedCategoryId?): void {
    var self = this;
    var params: { [k: string]: any } = {};

    this.resetSubCategory();
    this.resetClass();
    this.resetSubClass();

    if (selectedCategoryId) {
      params.categoryId = selectedCategoryId;
    }
    params.isDelete = false;

    this._apiService.GetAllData('subcategory', params).subscribe((res) => {
      self.subCategoryList = res.data.resultData;
    });
  }

  getClassData(selectedSubCategoryId?): void {
    var self = this;
    var params: { [k: string]: any } = {};

    this.resetClass();
    this.resetSubClass();

    if (selectedSubCategoryId) {
      params.subCategoryId = selectedSubCategoryId;
    }

    params.isDelete = false;

    this._apiService.GetAllData('class', params).subscribe((res) => {
      self.classList = res.data.resultData;
    });
  }

  getSubClassData(selectedClassId?): void {
    var self = this;
    var params: { [k: string]: any } = {};

    this.resetSubClass();

    if (selectedClassId) {
      params.classId = selectedClassId;
    }
    params.isDelete = false;

    this._apiService.GetAllData('subclass', params).subscribe((res) => {
      self.subClassList = res.data.resultData;
    });
  }

  ngOnInit(): void {
    if (this.loadAllCategoryData) {
      this.getCategoryData();
    }

    if (this.loadAllSubCategoryData) {
      this.getSubCategoryData();
    }

    if (this.loadAllClassData) {
      this.getClassData();
    }

    if (this.loadAllSubClassData) {
      this.getSubClassData();
    }

    if (this.isShowRoleSet) {
      this.getRoleSet();
    }
  }
}
