import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ItemSetComponent } from './item-set.component';
import { AuthGuard } from '../../../../auth/helpers';
import { ItemSetFormpageComponent } from './item-set-formpage/item-set-formpage.component';
import { CoreCommonModule } from '@core/common.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { ComponentsModule } from 'app/main/components/components.module';
import { environment } from 'environments/environment';

const routes: Routes = !environment.menuList.itemManage ? []: [
  {
    path: 'item-manage/set',
    component: ItemSetComponent,
    canActivate: [AuthGuard],
    data: { animation: 'set-manage' },
  },
  {
    path: 'item-manage/set/create',
    component: ItemSetFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'set-create' },
  },
  {
    path: 'item-manage/set/edit/:id',
    component: ItemSetFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'set-edit' },
  },
];

@NgModule({
  declarations: [ItemSetComponent, ItemSetFormpageComponent],
  imports: [
    RouterModule.forChild(routes),
    CoreCommonModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    ContentHeaderModule,
    ComponentsModule,
  ],
  exports: [ItemSetComponent, ItemSetFormpageComponent],
})
export class ItemSetModule {}
