<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <!-- content-header component -->
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
  
      <section>
        <div class="card">
          <div class="card-body">
            <div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
              <app-loading></app-loading>
            </div>
            <app-form-template
              *ngIf="!isLoading"
              [config]="config.formConfig"
              [apiPath]="config.apiPath"
              [pathUrl]="config.pathUrl"
              [tableName]="config.tableName"
              [itemObj]="itemObj"
              [isEditing]="false"
              #formTemplate
            ></app-form-template>
          </div>
        </div>
      </section>
    </div>
  </div>
  