import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductComponent } from './product/product.component';
import { ServiceComponent } from './service/service.component';
import { InnovationComponent } from './innovation/innovation.component';
import { InnovationFormpageComponent } from './innovation/innovation-formpage/innovation-formpage.component';
import { ServiceFormpageComponent } from './service/service-formpage/service-formpage.component';
import { ProductFormpageComponent } from './product/product-formpage/product-formpage.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/auth/helpers';
import { TranslateModule } from '@ngx-translate/core';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSummernoteModule } from 'ngx-summernote';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { ComponentsModule } from 'app/main/components/components.module';
import { ProductServiceComponent } from './product-service.component';

const routes: Routes = [
  {
    path: 'cms/product-service/landing',
    component: ProductServiceComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-product-service' },
  },
  {
    path: 'cms/product-service/innovation',
    component: InnovationComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-innovation' },
  },
  {
    path: 'cms/product-service/innovation/create',
    component: InnovationFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-innovation-create' },
  },
  {
    path: 'cms/product-service/innovation/edit/:id',
    component: InnovationFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-innovation-edit' },
  },

  {
    path: 'cms/product-service/service',
    component: ServiceComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-service' },
  },
  {
    path: 'cms/product-service/service/create',
    component: ServiceFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-service-create' },
  },
  {
    path: 'cms/product-service/service/edit/:id',
    component: ServiceFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-service-edit' },
  },
  {
    path: 'cms/product-service/product',
    component: ProductComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-product' },
  },
  {
    path: 'cms/product-service/product/create',
    component: ProductFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-product-create' },
  },
  {
    path: 'cms/product-service/product/edit/:id',
    component: ProductFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-product-edit' },
  },
];

@NgModule({
  declarations: [
    ProductComponent,
    ProductServiceComponent,
    ServiceComponent,
    InnovationComponent,
    InnovationFormpageComponent,
    ServiceFormpageComponent,
    ProductFormpageComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ContentHeaderModule,
    FormsModule,
    NgxSummernoteModule,
    ReactiveFormsModule,
    CorePipesModule,
    ComponentsModule,
    RouterModule.forChild(routes),
  ]
})
export class ProductServiceModule { }
