import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  homeBanner = {
    componentName: 'homeBanner',
    apiPath: 'HomeBanner',
    pathUrl: '/cms/homeBanner',
    keyName: 'name',
  };

  constructor() {}
}
