<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <app-search-panel
      [forCompanentName]="componentName"
      [pathUrl]="pathUrl + '/create'"
      [isGridView]="isGridView"
      [isForGroupTemplate]="isForGroupTemplate"
      [isShowSortByGroup]="false"
      (listView)="listView()"
      (gridView)="gridView()"
      (ActiveStatusFilter)="ActiveStatusFilter($event)"
      (SetLoading)="SetLoadingState()"
      (exportXLSX)="exportXLSX()"
      (setPageSize)="setPageSize($event)"
      (callDataFromAPI)="initData($event)"
    ></app-search-panel>

    <section>
      <div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
        <app-loading></app-loading>
      </div>
      <div *ngIf="!isLoading" [ngClass]="{ 'd-none': isGridView }">
        <app-content-list
      [keyName]="keyName"
          [itemObjList]="
            contentList
              | filter : searchText : 'name'
          "
          [mainPathUrl]="pathUrl + '/edit'"
          [editPathUrl]="pathUrl + '/edit'"
          [page]="page"
          [pageSize]="pageSize"
          (deleteFunc)="deleteContent($event)"
          (isActiveFunc)="setActiveContent($event)"
          [isGoEditPage]="isGoEditPage"
          [selectedColumns]="selectedColumns"
        ></app-content-list>
      </div>
      <div *ngIf="!isLoading" [ngClass]="{ 'd-none': !isGridView }">
        <app-content-card
          [itemObjList]="
            contentList
              | filter : searchText : 'name'
              | isActiveFilter : activeStatus : 'isActive'
              | slice : (page - 1) * pageSize : (page - 1) * pageSize + pageSize
          "
          [mainPathUrl]="pathUrl + '/edit'"
          [editPathUrl]="pathUrl + '/edit'"
          (deleteFunc)="deleteContent($event)"
          (isActiveFunc)="setActiveContent($event)"
          [isGoEditPage]="isGoEditPage"
        ></app-content-card>
      </div>
    </section>

  </div>
</div>
