import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from 'app/main/components/components.service';
import { ContentListComponent } from 'app/main/components/content-list/content-list.component';
import Group from 'app/main/model/Group';
import { ApiService } from 'app/main/service/api.service';
import { GroupService } from '../group/group.service';
import { BrandService } from './brand.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss'],
})
export class BrandComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();
  private dataSubscription: Subscription;

  componentName: string;

  apiPath: string;
  pathUrl: string;

  contentHeader: object;
  itemList: Group[];

  isLoading: boolean;
  isGridView: boolean = false;
  isShowChildList: boolean = false;
  isForGroupTemplate: boolean = false;
  isGoEditPage: boolean = false;
  selectedColumns: string[];

  activeStatus: string;
  searchText: string;
  searchData: {[k: string]: any} = {};

  public page = 1;
  public pageSize = 10;

  @ViewChild(ContentListComponent) contentListTable: ContentListComponent;

  constructor(
    private route: ActivatedRoute,
    private _apiService: ApiService,
    private _brandService: BrandService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService
  ) {
    this.SetLoadingState();
    this._unsubscribeAll = new Subject();

    this.initConfig();
    this.initData();
  }

  initConfig(): void {
    this.componentName = this._brandService.componentName;
    this.apiPath = this._brandService.apiUrl;
    this.pathUrl = this._brandService.pathUrl;
  }

  initData(obj?: { [key: string]: any }): void {
    this.SetLoadingState();
    this.selectedColumns = ['brandCode', 'name', 'isActive'];

    this.itemList = [];

    if (obj) {
      this.searchText = obj.searchText ? obj.searchText : '';

      if (!obj.params) {
        this.searchData = { SortPath: 'Name_uq', Direction: 0, isDelete: false };
      } else {
        this.searchData = obj.params;
      }
    } else {
      this.searchText = '';
      this.searchData = { SortPath: 'Name_uq', Direction: 0, isDelete: false };
    }

    this.searchData.name = this.searchText

    if (this.contentListTable) {
      this.contentListTable.loadData(this.searchData)
    }
    
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  listView() {
    this.isGridView = false;
    this.contentListTable.refreshDataTable();
  }

  gridView() {
    this.isGridView = true;
  }

  SearchFilter(val: string) {
    this.searchText = val;
    this.SetLoadedState();
  }

  setPageSize(val: number): void {
    this.pageSize = val;
  }

  ActiveStatusFilter(val: string) {
    this.activeStatus = val;
  }

  SortFilter(key: string) {
    var self = this;

    let objKey = JSON.parse(key);
    let SortPath = objKey.SortPath;
    let Direction = objKey.Direction;

    this.SetLoadingState();
    this._apiService
      .GetAllData(this.apiPath, {
        SortPath: SortPath,
        Direction: Number(Direction),
      })
      .subscribe((res) => {
        self.itemList = res.data.resultData;
        self.SetLoadedState();
      });
  }

  exportXLSX() {
    this.contentListTable.ExportXLSX();
  }

  setActiveContent(val): void {
    var self = this;
    this._apiService
      .SetIsActiveData(this.apiPath, val.id, val.status)
      .subscribe(
        (res) => {
          self._componentsService.SuccessSwal();
          self.SetLoadingState();
          self.initData();
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
  }

  deleteContent(guid: string): void {
    var self = this;
    this._apiService.SoftDelete(this.apiPath, guid).subscribe(
      (res) => {
        self._componentsService.SuccessSwal();
        self.SetLoadingState();
        self.initData();
      },
      (err) => {
        self._componentsService.ErrorSwal();
      }
    );
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'BreadCrumbs.Manage.Brand',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe()
    }
  }
}
