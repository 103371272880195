import { Component, OnInit, ViewChild } from '@angular/core';
import { ContentListComponent } from '../../../components/content-list/content-list.component';
import { ApiService } from '../../../service/api.service';
import { TranslateService } from '@ngx-translate/core';
import { ComponentsService } from '../../../components/components.service';
import Category from '../../../model/Category';
import { CategoryService } from './category.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();
  private dataSubscription: Subscription;
  componentName: string;

  apiPath: string;
  pathUrl: string;

  contentHeader: object;
  itemList: Category[];

  isLoading: boolean;
  isGridView: boolean = false;
  isShowChildList: boolean = false;
  isForGroupTemplate: boolean = false;
  isGoEditPage: boolean = false;

  activeStatus: string;
  searchText: string;
  searchData: {[k: string]: any} = {};

  public page = 1;
  public pageSize = 10;

  @ViewChild(ContentListComponent) contentListTable: ContentListComponent;

  // @ViewChild(DataTableDirective)
  // datatableElement: DataTableDirective;
  // dtOptions: DataTables.Settings = {};
  // dtTrigger: Subject<any> = new Subject<any>();

  constructor(
    private _apiService: ApiService,
    private _groupTemplateService: CategoryService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService
  ) {
    this.SetLoadingState();
    this.initConfig();
    this.initData();
  }

  initConfig(): void {
    this.componentName = this._groupTemplateService.componentName;
    this.apiPath = this._groupTemplateService.apiUrl;
    this.pathUrl = this._groupTemplateService.pathUrl;
  }

  initData(obj?: { [key: string]: any }): void {
    this.SetLoadingState();
    this.itemList = [];

    if (obj) {
      this.searchText = obj.searchText ? obj.searchText : '';

      if (!obj.params) {
        this.searchData = { SortPath: 'Name', Direction: 0, isDelete: false };
      } else {
        this.searchData = obj.params;
      }
    } else {
      this.searchText = '';
      this.searchData = { SortPath: 'Name', Direction: 0, isDelete: false };
    }

    this.searchData.name = this.searchText

    if (this.contentListTable) {
      this.contentListTable.loadData(this.searchData)
    }
    
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  listView() {
    this.isGridView = false;
  }

  gridView() {
    this.isGridView = true;
  }

  SearchFilter(val: string) {
    this.searchText = val;
    this.SetLoadedState();
  }

  setPageSize(val: number): void {
    this.pageSize = val;
  }

  ActiveStatusFilter(val: string) {
    this.activeStatus = val;
  }

  SortFilter(key: string) {
    var self = this;

    let objKey = JSON.parse(key);
    let SortPath = objKey.SortPath;
    let Direction = objKey.Direction;

    this.SetLoadingState();

    this.initData({
      SortPath: SortPath,
      Direction: Number(Direction),
    });
    // this._apiService
    //   .GetAllData(this.apiPath, {
    //     SortPath: SortPath,
    //     Direction: Number(Direction),
    //   })
    //   .pipe(takeUntil(this._unsubscribeAll))
    //   .subscribe((res) => {
    //     self.itemList = res.data.resultData;
    //     self.SetLoadedState();
    //   });
  }

  exportXLSX() {
    this.contentListTable.ExportXLSX();
  }

  setActiveContent(val): void {
    var self = this;
    this._apiService
      .SetIsActiveData(this.apiPath, val.id, val.status)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          self._componentsService.SuccessSwal();
          self.SetLoadingState();
          self.initData();
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
  }

  deleteContent(guid: string): void {
    var self = this;
    this._apiService
      .SoftDelete(this.apiPath, guid)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(
        (res) => {
          self._componentsService.SuccessSwal();
          self.SetLoadingState();
          self.initData();
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
  }

  // drawDatatables(): void {
  //   this.dtOptions = {
  //     pagingType: 'full_numbers',
  //     scrollX: true,
  //     info: true,
  //     autoWidth: false,
  //     searching: false,
  //     order: [[0, 'asc']],
  //   };

  //   if (this.itemList) {
  //     this.dtTrigger.next();
  //   }
  // }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'General.Category',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'General.Home',
            isLink: true,
            link: '/',
          },
        ],
      },
    };

    // this.drawDatatables();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next()
    this._unsubscribeAll.complete()
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe()
    }
  }
}
