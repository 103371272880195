<div class="lang-switcher-sidebar nav-vertical">
  <ul ngbNav class="nav nav-pills flex-column text-nowrap mb-0">
    <li ngbNavItem *ngFor="let lang of langList">
      <a
        ngbNavLink
        (click)="selectedLanguage(lang)"
        [class.active]="selectedLang === lang"
        >{{ lang | uppercase }}</a
      >
    </li>
  </ul>
</div>

<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <app-content-header [contentHeader]="contentHeader"></app-content-header>

    <section>
      <div class="card">
        <div class="card-header d-flex justify-content-end">
          

          <ul ngbNav class="nav nav-pills">
            <li ngbNavItem *ngFor="let lang of langList">
              <a
                ngbNavLink
                (click)="selectedLanguage(lang)"
                [class.active]="selectedLang === lang"
                >{{ lang | uppercase }}</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body">
          <div *ngIf="isLoading" class="mt-5 d-flex justify-content-center">
            <app-loading></app-loading>
          </div>

          <form [formGroup]="formGroup" *ngIf="!isLoading">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
                >{{ "Form.Name" | translate }}
                <span class="text-danger">*</span></label
              >
              <div class="col-sm-9">
                <input
                  class="form-control-custom"
                  type="text"
                  formControlName="name"
                  [ngClass]="{
                    'is-invalid error':
                      (isSubmit && f.name?.invalid) ||
                      (f.name?.invalid && (f.name?.dirty || f.name?.touched))
                  }"
                  [placeholder]="'Form.EnterName' | translate"
                />
                <div
                  class="invalid-feedback"
                  [ngClass]="{
                    'd-block':
                      f.name.invalid && (f.name.dirty || f.name.touched)
                  }"
                >
                  <div>
                    <div *ngIf="f.name.errors?.required">
                      {{ "Handle.Name" | translate }}
                    </div>
                    <div *ngIf="f.name.errors?.maxlength">
                      {{ "Handle.Exceed" | translate }}
                      100
                      {{ "Handle.Char" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-3 col-form-label">
                <p class="text-bold" style="margin-bottom: 5px">
                  Banner VDO
                  <span class="text-danger" *ngIf="f.isUseVDO.value">*</span>
                </p>
                <p style="white-space: pre-line">
                  วีดีโอแบนเนอร์<br />
                  Resolution: 1900px*965px
                </p>
                <!-- <div *ngIf="isMultiLang" class="badge badge-primary">
                  {{ controlName | uppercase }}
                </div> -->
                <!-- <div *ngIf="!isMultiLang" class="badge badge-dark">Shared</div> -->
              </div>
              <div class="col-sm-9">
                <div style="position: relative">
                  <div>
                    <div
                      class="custom-video"
                      [ngClass]="{
                        'is-invalid error':
                          isSubmit &&
                          f.isUseVDO.value &&
                          !getPreviewImageUrl('bannerVDO')
                      }"
                    >
                      <button
                        class="cover-input w-auto"
                        (click)="callSelectVideo('bannerVDO')"
                      >
                        {{ "Files.ChooseFile" | translate }}
                      </button>
                      <p
                        class="text-truncate"
                        style="
                          min-width: 200px;
                          margin-top: 50px;
                          padding: 0 16px;
                          margin-right: 16px;
                        "
                      >
                        {{
                          (getVideoName("bannerVDO")
                            ? "Files.ChooseFile"
                            : "Files.NoFileChosen"
                          ) | translate
                        }}
                      </p>
                      <input
                        (change)="prepareVideo($event, 'bannerVDO')"
                        accept="video/mp4,video/x-m4v,video/* "
                        type="file"
                        [id]="'bannerVDO'"
                        hidden
                        #videoUploadComponent
                        [required]="f.isUseVDO.value"
                        class="cover-input"
                      />
                      <label class="cover-file-label">
                        <div>
                          <i data-feather="play"></i
                          >{{
                            (getVideoName("bannerVDO")
                              ? "Files.ChooseFile"
                              : "Files.NoFileChosen"
                            ) | translate
                          }}
                        </div>
                        <div
                          class="inloader d-none"
                          [ngClass]="{
                            'd-block': loadingFile
                          }"
                        ></div>
                      </label>
                      <div class="preview-video-cover">
                        <div class="pvc-overlay"></div>

                        <div
                          class="pvc-image"
                          *ngIf="
                            !getPreviewImageUrl('bannerVDO') &&
                            mediaData['bannerVDO']
                          "
                        >
                          <div
                            class="d-flex align-items-center h-100 mt-50 justify-content-end mr-3"
                          >
                            <div class="spinner-grow" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="pvc-image"
                          *ngIf="getPreviewImageUrl('bannerVDO')"
                        >
                          <img
                            [src]="getPreviewImageUrl('bannerVDO')"
                            alt="Preview Cover"
                          />
                        </div>
                      </div>
                    </div>
                    <label
                      [for]="'bannerVDO'"
                      class="d-flex justify-content-between"
                      ><span>Choose only .mp4</span
                      ><span
                        >Limit: {{ bytesToSizes(videoFileSize) }}</span
                      ></label
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-3 col-form-label">
                <p class="text-bold" style="margin-bottom: 5px">
                  Banner VDO Mobile
                  <span class="text-danger" *ngIf="f.isUseVDO.value">*</span>
                </p>
                <p style="white-space: pre-line">
                  วีดีโอแบนเนอร์สำหรับโทรศัพท์<br />
                  Resolution: 375px*633px
                </p>
                
              </div>
              <div class="col-sm-9">
                <div style="position: relative">
                  <div>
                    <div
                      class="custom-video"
                      [ngClass]="{
                        'is-invalid error':
                          isSubmit &&
                          f.isUseVDO.value &&
                          !getPreviewImageUrl('bannerVDOMobile')
                      }"
                    >
                      <button
                        class="cover-input w-auto"
                        (click)="callSelectVideo('bannerVDOMobile')"
                      >
                        {{ "Files.ChooseFile" | translate }}
                      </button>
                      <p
                        class="text-truncate"
                        style="
                          min-width: 200px;
                          margin-top: 50px;
                          padding: 0 16px;
                          margin-right: 16px;
                        "
                      >
                        {{
                          (getVideoName("bannerVDOMobile")
                            ? "Files.ChooseFile"
                            : "Files.NoFileChosen"
                          ) | translate
                        }}
                      </p>
                      <input
                        (change)="prepareVideo($event, 'bannerVDOMobile')"
                        accept="video/mp4,video/x-m4v,video/* "
                        type="file"
                        [id]="'bannerVDOMobile'"
                        hidden
                        #mobileVideoUploadComponent
                        [required]="f.isUseVDO.value"
                        class="cover-input"
                      />
                      <label class="cover-file-label">
                        <div>
                          <i data-feather="play"></i
                          >{{
                            (getVideoName("bannerVDOMobile")
                              ? "Files.ChooseFile"
                              : "Files.NoFileChosen"
                            ) | translate
                          }}
                        </div>
                        <div
                          class="inloader d-none"
                          [ngClass]="{
                            'd-block': loadingFile
                          }"
                        ></div>
                      </label>
                      <div class="preview-video-cover">
                        <div class="pvc-overlay"></div>

                        <div
                          class="pvc-image"
                          *ngIf="
                            !getPreviewImageUrl('bannerVDOMobile') &&
                            mediaData['bannerVDOMobile']
                          "
                        >
                          <div
                            class="d-flex align-items-center h-100 mt-50 justify-content-end mr-3"
                          >
                            <div class="spinner-grow" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="pvc-image"
                          *ngIf="getPreviewImageUrl('bannerVDOMobile')"
                        >
                          <img
                            [src]="getPreviewImageUrl('bannerVDOMobile')"
                            alt="Preview Cover"
                          />
                        </div>
                      </div>
                    </div>
                    <label
                      [for]="'bannerVDOMobile'"
                      class="d-flex justify-content-between"
                      ><span>Choose only .mp4</span
                      ><span
                        >Limit: {{ bytesToSizes(videoFileSize) }}</span
                      ></label
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-3 col-form-label">
                <p class="text-bold" style="margin-bottom: 5px">
                  Banner Image
                  <span class="text-danger" *ngIf="!f.isUseVDO.value">*</span>
                </p>
                <p style="white-space: pre-line">
                  {{ "รูปภาพแบนเนอร์\nResolution: 1900px*965px" }}
                </p>
                <!-- <div *ngIf="isMultiLang" class="badge badge-primary">
                  {{ controlName | uppercase }}
                </div> -->
                <!-- <div *ngIf="!isMultiLang" class="badge badge-dark">Shared</div> -->
              </div>
              <div class="col-sm-9">
                <app-image-upload
                  #imageUploadComponent
                  [id]="'bannerIMG'"
                  (inputChanged)="handleInputChanged($event, 'bannerIMG')"
                ></app-image-upload>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-3 col-form-label">
                <p class="text-bold" style="margin-bottom: 5px">
                  Banner Image Mobile
                  <span class="text-danger" *ngIf="!f.isUseVDO.value">*</span>
                </p>
                <p style="white-space: pre-line">
                  {{ "รูปภาพแบนเนอร์สำหรับโทรศัพท์\nResolution: 375px*633px" }}
                </p>
              </div>
              <div class="col-sm-9">
                <app-image-upload
                  #mobileImageUploadComponent
                  [id]="'bannerIMGMobile'"
                  (inputChanged)="handleInputChanged($event, 'bannerIMGMobile')"
                ></app-image-upload>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-3 col-form-label">
                <p class="text-bold" style="margin-bottom: 5px">
                  Use Banner as VDO
                </p>
                <p style="white-space: pre-line">ใช้แบนแนอร์ในรูปแบบวีดีโอ</p>
              </div>
              <div class="col-sm-9">
                <div
                  class="custom-control custom-switch custom-control-inline col-form-label"
                >
                  <input
                    type="checkbox"
                    [id]="'isUseVDO'"
                    class="custom-control-input"
                    [formControlName]="'isUseVDO'"
                    (change)="changeRequiredMode($event)"
                  />
                  <label
                    class="custom-control-label"
                    [for]="'isUseVDO'"
                  ></label>
                </div>
              </div>
            </div>
            <div formGroupName="title">
              <div *ngFor="let lang of langList">
                <div
                  class="form-group row"
                  [class.d-none]="selectedLang != lang"
                >
                  <div class="col-sm-3 col-form-label">
                    <p class="text-bold" style="margin-bottom: 5px">
                      Banner Title
                      <span class="text-danger">*</span>
                    </p>
                    <p style="white-space: pre-line">หัวข้อแบนเนอร์</p>
                    <div
                      *ngIf="langList.length > 1"
                      class="badge badge-primary"
                    >
                      {{ lang | uppercase }}
                    </div>
                  </div>
                  <div class="col-sm-9">
                    <input
                      class="form-control-custom"
                      type="text"
                      [formControlName]="lang"
                      [ngClass]="{
                        'is-invalid error':
                          (isSubmit && f.title?.invalid) ||
                          (f.title?.invalid &&
                            (f.title?.dirty || f.title?.touched))
                      }"
                      [placeholder]="'Form.EnterDescription' | translate"
                    />
                    <div class="invalid-feedback">
                      <div
                        *ngIf="
                          isSubmit ||
                          (f.title.invalid &&
                            (f.title.dirty || f.title.touched))
                        "
                      >
                        {{ "Handle.DescriptionAllLanguage" | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-sm-3 col-form-label">
                <p class="text-bold" style="margin-bottom: 5px">
                  {{ "Informative.ActiveStatus" | translate }}
                </p>
                <!-- <p style="white-space: pre-line">ใช้แบนแนอร์ในรูปแบบวีดีโอ</p> -->
              </div>
              <div class="col-sm-9">
                <div
                  class="custom-control custom-switch custom-control-inline col-form-label"
                >
                  <input
                    type="checkbox"
                    [id]="'isActive'"
                    class="custom-control-input"
                    [formControlName]="'isActive'"
                  />
                  <label
                    class="custom-control-label"
                    [for]="'isActive'"
                  ></label>
                </div>
              </div>
            </div>

            <div class="auto-form-footer">
              <a class="btn btn-secondary" (click)="openCancelModal()">
                {{ "Form.Cancel" | translate }}
              </a>

              <u
                class="text-warning d-none"
                [ngClass]="{
                  'd-block': invalidAlert
                }"
              >
                <span
                  class="d-none"
                  [ngClass]="{
                    'd-block': formGroup.invalid
                  }"
                >
                  {{ "Alert.Invalid" | translate }}
                </span>
              </u>

              <div class="d-flex justify-content-between align-items-center">
                <a class="btn btn-primary" (click)="openConfirmModal()">
                  {{ "Form.Submit" | translate }}
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</div>
