import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AboutService {
  aboutHistory = {
    componentName: 'aboutHistory',
    apiPath: 'AboutUsHistory',
    pathUrl: '/cms/about-history',
    keyName: 'title',
  };

  aboutCertified = {
    componentName: 'aboutCertified',
    apiPath: 'AboutUsCertified',
    pathUrl: '/cms/about-certified',
    keyName: 'title',
  };

  aboutTeam = {
    componentName: 'aboutTeam',
    apiPath: 'AboutUsTeam',
    pathUrl: '/cms/about-team',
    keyName: 'name',
  };

  aboutActivity = {
    componentName: 'AboutUsTeamActivity',
    apiPath: 'AboutUsTeamActivity',
    pathUrl: '/cms/about-activity',
    keyName: 'name',
  };

  constructor() {}
}
