import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormType, ICMSConfig } from './CMSInterface';
import Banner from '../model/Banner';
import News from '../model/News';
import PDPA from '../model/PDPA';
import Event from '../model/Event';
import Home from '../model/Home';
import CookiePolicy from '../model/CookiePolicy';
import TermsCondition from '../model/TermsCondition';
import Contact from '../model/Contact';
import About from '../model/About';
import { FileTypeFilter } from '../components/custom-dropzone/file-type.enum';
import CMSSignIn from '../model/CMSSignIn';
import ShoppingCart from '../model/ShoppingCart';

@Injectable({
  providedIn: 'root',
})
export class CmsFormConfig {
  config: { [key: string]: ICMSConfig } = {
    AboutUs: {
      apiPath: 'AboutUs',
      pathUrl: '/cms/about',
      tableName: 'Content',
      model: new About(),
      isList: false,
      componentName: 'About Us',
      formConfig: {
        aboutUsBanner: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Banner Background',
          description: 'รูปแบนเนอร์เกี่ยวกับเรา\n Resolution: 1900px*407px',
        },
        aboutUsBannerMobile: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Banner Background Mobile',
          description: 'รูปแบนเนอร์เกี่ยวกับเราสำหรับโทรศัพท์\n Resolution: 375px*310px',
        },
        aboutUsDescription: {
          type: FormType.Textarea,
          isMultipleLang: true,
          label: 'Description',
          description: 'คำอธิบายเกี่ยวกับเรา',
        },

        visionImage: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Vision Image',
          description: 'รูปภาพวิสัยทัศน์\n Resolution: 1900px*1080px',
        },

        visionImageMobile: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Vision Image Mobile',
          description: 'รูปภาพวิสัยทัศน์สำหรับโทรศัพท์\n Resolution: 375px*943px',
        },

        visionDescription: {
          type: FormType.RichText,
          isMultipleLang: true,
          label: 'Vision Description',
          description: 'คำอธิบายวิสัยทัศน์',
        },

        missionDiscription: {
          type: FormType.RichText,
          isMultipleLang: true,
          label: 'Mission Description',
          description: 'คำอธิบายภารกิจ',
        },

        aboutUsGroupImage: {
          type: FormType.SingleImage,
          label: 'About Us Group Image',
          description: 'รูปภาพบริษัทในเครือ\n Resolution: 578px*578px',
        },
        aboutUsGroupLogo1: {
          type: FormType.SingleImage,
          label: 'About Us Group Logo 1',
          description: 'โลโก้บริษัทในเครือ 1\n Resolution: 100px*100px',
        },
        aboutUsGroupLogo2: {
          type: FormType.SingleImage,
          label: 'About Us Group Logo 2',
          description: 'โลโก้บริษัทในเครือ 2\n Resolution: 100px*100px',
        },
        aboutUsGroupLogo3: {
          type: FormType.SingleImage,
          label: 'About Us Group Logo 3',
          description: 'โลโก้บริษัทในเครือ 3\n Resolution: 100px*100px',
        },

        aboutUsGroupDescription: {
          type: FormType.RichText,
          isMultipleLang: true,
          label: 'About Us Group Description',
          description: 'คำอธิบายเกี่ยวกับบริษัทในเครือ',
        },
        aboutUsGroupPrice1: {
          type: FormType.Text,
          label: 'Product Count',
          description: 'จำนวนผลงาน',
        },
        aboutUsGroupPrice2: {
          type: FormType.Text,
          label: 'Customer Count',
          description: 'จำนวนลูกค้า',
        },
        aboutUsGroupPrice3: {
          type: FormType.Text,
          label: 'Reward Count',
          description: 'จำนวนรางวัล',
        },

        certifiedImage: {
          type: FormType.SingleImage,
          label: 'Certified Image',
          description: 'รูปภาพส่วนมาตรฐาน\n Resolution: 1063px*945px',
        },
        certifiedTitle: {
          type: FormType.Text,
          isMultipleLang: true,
          label: 'Certified Title',
          description: 'หัวข้อส่วนมาตรฐาน',
        },
        certifiedDescription: {
          type: FormType.Textarea,
          isMultipleLang: true,
          label: 'Certified Description',
          description: 'คำอธิบายส่วนมาตรฐาน',
        },

        aboutFooterBannerImage: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'About Footer Banner Image',
          description: 'รูปภาพแบนเนอร์ด้านล่างสุด\n Resolution: 1900px*646px',
        },

        aboutFooterBannerImageMobile: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'About Footer Banner Image Mobile',
          description: 'รูปภาพแบนเนอร์ด้านล่างสุดสำหรับโทรศัพท์\nResolution: 375px*300px',
        },

        divider: {
          type: FormType.Divider
        },

        teamTitle: {
          type: FormType.Text,
          isMultipleLang: true,
          label: 'Team Title',
          description: 'หัวข้อทีมงาน',
        },

        teamQuote: {
          type: FormType.Textarea,
          isMultipleLang: true,
          label: 'Team Quote',
          description: 'Quote ทีมงาน',
        }
      },
    },
    AboutUsCertified: {
      apiPath: 'AboutUsCertified',
      pathUrl: '/cms/about-certified',
      tableName: 'Content',
      model: new About(),
      isList: false,
      componentName: 'About Us Certified',
      formConfig: {
        title: {
          type: FormType.Text,
          validator: [Validators.required],
          label: 'Certified Item Title',
          description: 'หัวข้อมาตรฐาน',
        },
        
        certifiedItemImage: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Certified Item Image',
          description: 'รูปภาพมาตรฐาน\n Resolution: 162px*162px',
        },
        isActive: {
          type: FormType.IsActive,
          label: 'Active Status',
          labelTranslate: 'Informative.ActiveStatus',
        },
      },
    },
    AboutUsHistory: {
      apiPath: 'AboutUsHistory',
      pathUrl: '/cms/about-history',
      tableName: 'Content',
      model: new About(),
      isList: false,
      componentName: 'About Us History',
      formConfig: {
        title: {
          type: FormType.Text,
          validator: [Validators.required],
          label: 'Year',
          description: 'ปี ค.ศ.',
        },
        subTitle: {
          type: FormType.RichText,
          validator: [Validators.required],
          isMultipleLang: true,
          label: 'Timeline Title',
          description: 'หัวข้อ',
        },
        detail: {
          type: FormType.Textarea,
          validator: [Validators.required],
          isMultipleLang: true,
          label: 'Timeline Description',
          description: 'คำอธิบาย',
        },
        timelineImage: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Timeline Image',
          description: 'รูปภาพ\n Resolution: 951px*530px',
        },
        isActive: {
          type: FormType.IsActive,
          label: 'Active Status',
          labelTranslate: 'Informative.ActiveStatus',
        },
      },
    },
    AboutUsTeam: {
      apiPath: 'AboutUsTeam',
      pathUrl: '/cms/about-team',
      tableName: 'Content',
      model: new About(),
      isList: false,
      componentName: 'About Us Team',
      formConfig: {
        
        name: {
          type: FormType.Text,
          validator: [Validators.required],
          label: 'Name',
          description: 'ชื่อ',
        },
        title: {
          type: FormType.Text,
          validator: [Validators.required],
          isMultipleLang: true,
          label: 'Team Member Name',
          description: 'ชื่อทีมงาน',
        },
        subTitle: {
          type: FormType.Text,
          validator: [Validators.required],
          isMultipleLang: true,
          label: 'Team Position',
          description: 'ตำแหน่งทีม',
        },
        mgmTeamImage: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Image',
          description: 'รูปภาพ\n Resolution: 416px*480px',
        },
        row: {
          type: FormType.Number,
          validator: [Validators.required],
          label: 'Row',
          description: 'แถวแนวนอน',
        },
        column: {
          type: FormType.Number,
          validator: [Validators.required],
          label: 'Column',
          description: 'แถวแนวตั้ง',
        },
        isActive: {
          type: FormType.IsActive,
          label: 'Active Status',
          labelTranslate: 'Informative.ActiveStatus',
        },
      },
    },
    AboutUsTeamActivity: {
      apiPath: 'AboutUsTeamActivity',
      pathUrl: '/cms/about-activity',
      tableName: 'Content',
      model: new About(),
      isList: false,
      componentName: 'About Us Activity',
      formConfig: {
        
        name: {
          type: FormType.Text,
          validator: [Validators.required],
          label: 'Activity Name',
          description: 'ชื่อ Activity',
        },
        title: {
          type: FormType.Text,
          validator: [Validators.required],
          isMultipleLang: true,
          label: 'Activity Title',
          description: 'หัวข้อ Activity',
        },
        certifiedItemImage: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Activity Image',
          description: 'รูปภาพ Activity\n Resolution: 416px*480px',
        },

        isActive: {
          type: FormType.IsActive,
          label: 'Active Status',
          labelTranslate: 'Informative.ActiveStatus',
        },
      },
    },
    banner: {
      apiPath: 'banner',
      pathUrl: '/cms/banner',
      tableName: 'Content',
      model: new Banner(),
      isList: true,
      componentName: 'Banner',
      formConfig: {
        name: {
          type: FormType.Text,
          validator: [Validators.required],
        },
        title: {
          type: FormType.Text,
          validator: [Validators.required],
        },
        subTitle: {
          type: FormType.Textarea,
          validator: [Validators.required],
        },

        linkBtnCaption: {
          type: FormType.Text,
          label: 'Button Caption',
          description: 'Text that show on button',
        },
        linkBtnUrl: {
          type: FormType.Text,
          label: 'Button URL',
          description: 'URL that linked when press button',
        },
        isActive: {
          type: FormType.Radio,
          radioList: [
            { label: 'Active', translate: 'Status.Active', value: true },
            { label: 'Inactive', translate: 'Status.Inactive', value: false },
          ],
          label: 'Active Status',
        },
        media: {
          type: FormType.MultipleFiles,
          limit: 3,
          description: 'Cover of banner',
        },
      },
    },
    common: {
      apiPath: 'Common',
      pathUrl: '/cms/common',
      tableName: 'Content',
      model: new Object(),
      isList: false,
      componentName: 'E-Catalog',
      formConfig: {
        eCategoryBackground: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'E-Catalog Background',
          description: 'ภาพพื้นหลัง\nResolution: 1897px*407px\n ',
        },
        eCategoryBackgroundMobile: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'E-Catalog Background Mobile',
          description: 'ภาพพื้นหลังสำหรับโทรศัพท์\nResolution: 375px*577px\n ',
        },
        eCategoryTitle: {
          type: FormType.Text,
          validator: [Validators.required],
          label: 'E-Catalog Title',
          description: 'หัวข้อ',
          isMultipleLang: true,
        },
        eCategoryFile: {
          type: FormType.SingleFile,
          validator: [Validators.required],
          label: 'E-Catalog File',
          description: 'ไฟล์',
        },
      },
    },
    ContactUs: {
      apiPath: 'ContactUs',
      pathUrl: '/cms/contact',
      tableName: 'Content',
      model: new Contact(),
      isList: false,
      componentName: 'Contact',
      formConfig: {
        contactBanner: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Banner Background',
          description: 'ภาพพื้นหลังแบนเนอร์\nResolution: 1897px*407px\n ',
        },
        contactBannerMobile: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Banner Background Mobile',
          description: 'ภาพพื้นหลังแบนเนอร์สำหรับโทรศัพท์\n Resolution: 375px*310px ',
        },
        contactLogo: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Logo Image',
          description: 'รูปโลโก้บนภาพแบนเนอร์ \nResolution: 383px*118px\n ',
        },
        contactGoogleMapURL: {
          type: FormType.Text,
          label: 'Google Map Embed URL',
          description: 'ลิ้งค์ Google Map',
          validator: [Validators.required],
        },
        contactAddress: {
          type: FormType.Textarea,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Address',
          description: 'ที่อยู่',
        },
        contactTel: {
          type: FormType.Text,
          label: 'Telephone',
          description: 'เบอร์โทรศัพท์',
          validator: [Validators.required],
        },
        contactEmail: {
          type: FormType.Text,
          label: 'Email',
          description: 'อีเมล',
          validator: [Validators.required],
        },
        contactLINEQR: {
          type: FormType.SingleImage,
          label: 'Line QR Code',
          description: 'Resolution: 200px*200px ',
          validator: [Validators.required],
   
        },
        contactLINEID: {
          type: FormType.Text,
          label: 'Line ID',
          validator: [Validators.required],
   
        },
        contactFacebook: {
          type: FormType.Text,
          label: 'Facebook',
          validator: [Validators.required],
        },
        contactInstagram: {
          type: FormType.Text,
          label: 'Instagram',
          validator: [Validators.required],
        },
        contactX: {
          type: FormType.Text,
          label: 'X (Twitter)',
          validator: [Validators.required],
        },

        contactCompanyDescription: {
          type: FormType.Textarea,
          isMultipleLang: true,
          label: 'Description',
          description: 'รายละเอียด',
          validator: [Validators.required],
        },

        contactFormBackground: {
          type: FormType.SingleImage,
          label: 'Contact Form Background',
          description: 'ภาพพื้นหลังฟอร์ม \nResolution: 1897px*653px\n ',
          validator: [Validators.required],
        },
        contactFormBackgroundMobile: {
          type: FormType.SingleImage,
          label: 'Contact Form Background Mobile',
          description: 'ภาพพื้นหลังฟอร์ม \nResolution: 375px*367px\n ',
          validator: [Validators.required],
        },
      },
    },
    home: {
      apiPath: 'home',
      pathUrl: '/cms/home',
      tableName: 'Content',
      model: new Home(),
      isList: false,
      componentName: 'Home',
      formConfig: {
        productServiceDescription: {
          type: FormType.Textarea,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Product Service Description',
          description: 'คำอธิบายส่วนผลิตภัณท์และบริการ',
        },
        productServiceHighlight1: {
          type: FormType.Select,
          selectDataAPI: 'ProductsServicesItem?CommonEnum=1+2&isDelete=false&isActive=true',
          isUseMultipleAPI: true,
          selectDataLabelColumn: 'name',
          selectDataValueColumn: 'id',
          label: 'Product Service Highlight 1',
          description: 'ผลิตภัณท์และบริการ 1',
        },
        productServiceHighlight2: {
          type: FormType.Select,
          selectDataAPI: 'ProductsServicesItem?CommonEnum=1+2&isDelete=false&isActive=true',
          isUseMultipleAPI: true,
          selectDataLabelColumn: 'name',
          selectDataValueColumn: 'id',
          label: 'Product Service Highlight 2',
          description: 'ผลิตภัณท์และบริการ 2',
        },
        productServiceHighlight3: {
          type: FormType.Select,
          selectDataAPI: 'ProductsServicesItem?CommonEnum=1+2&isDelete=false&isActive=true',
          isUseMultipleAPI: true,
          selectDataLabelColumn: 'name',
          selectDataValueColumn: 'id',
          label: 'Product Service Highlight 3',
          description: 'ผลิตภัณท์และบริการ 3',
        },
        innovationDescription: {
          type: FormType.Textarea,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Innovation Description',
          description: 'คำอธิบายส่วนนวัตกรรม',
        },

        innovationHighlight1: {
          type: FormType.Select,
          selectDataAPI: 'ProductsServicesItem?CommonEnum=3&isDelete=false&isActive=true',
          selectDataLabelColumn: 'name',
          selectDataValueColumn: 'id',
          label: 'Innovation Highlight 1',
          description: 'นวัตกรรมที่ไฮไลท์ 1',
        },

        innovationHighlight2: {
          type: FormType.Select,
          selectDataAPI: 'ProductsServicesItem?CommonEnum=3&isDelete=false&isActive=true',
          selectDataLabelColumn: 'name',
          selectDataValueColumn: 'id',
          label: 'Innovation Highlight 2',
          description: 'นวัตกรรมที่ไฮไลท์ 2',
        },

        aboutusSectionImage: {
          type: FormType.SingleImage,
          label: 'About Us Section Image',
          description: 'รูปภาพในส่วนเกี่ยวกับเรา\n Resolution: 1900px*965px',
          validator: [Validators.required],
        },

        aboutusSectionImageMobile: {
          type: FormType.SingleImage,
          label: 'About Us Section Image Mobile',
          description: 'รูปภาพในส่วนเกี่ยวกับเราสำหรับ\n Resolution: 375px*450px',
          validator: [Validators.required],
        },

        aboutusSectionDescription: {
          type: FormType.Textarea,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'About Us Section Description',
          description: 'คำอธิบายส่วนเกี่ยวกับเรา',
        },

        plantourTitle1: {
          type: FormType.Text,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Plantour Title 1',
          description: 'หัวข้อแพลนทัวร์ 1',
        },
        plantourDescription1: {
          type: FormType.Textarea,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Plantour Description 1',
          description: 'คำอธิบายแพลนทัวร์ 1',
        },
        plantourTitle2: {
          type: FormType.Text,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Plantour Title 2',
          description: 'หัวข้อแพลนทัวร์ 2',
        },
        plantourDescription2: {
          type: FormType.Textarea,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Plantour Description 2',
          description: 'คำอธิบายแพลนทัวร์ 2',
        },
        plantourTitle3: {
          type: FormType.Text,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Plantour Title 3',
          description: 'หัวข้อแพลนทัวร์ 3',
        },
        plantourDescription3: {
          type: FormType.Textarea,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Plantour Description 3',
          description: 'คำอธิบายแพลนทัวร์ 3',
        },
        plantourTitle4: {
          type: FormType.Text,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Plantour Title 4',
          description: 'หัวข้อแพลนทัวร์ 4',
        },
        plantourDescription4: {
          type: FormType.Textarea,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Plantour Description 4',
          description: 'คำอธิบายแพลนทัวร์ 4',
        },
        plantourTitle5: {
          type: FormType.Text,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Plantour Title 5',
          description: 'หัวข้อแพลนทัวร์ 5',
        },
        plantourDescription5: {
          type: FormType.Textarea,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Plantour Description 5',
          description: 'คำอธิบายแพลนทัวร์ 5',
        },
        plantourTitle6: {
          type: FormType.Text,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Plantour Title 6',
          description: 'หัวข้อแพลนทัวร์ 6',
        },
        plantourDescription6: {
          type: FormType.Textarea,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Plantour Description 6',
          description: 'คำอธิบายแพลนทัวร์ 6',
        },

        sectionServiceDescription: {
          type: FormType.Textarea,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Section Service Description',
          description: 'คำอธิบายส่วนบริการ',
        },
        serviceHighlightTitle1: {
          type: FormType.Text,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Service Highlight Title 1',
          description: 'หัวข้อบริการที่ไฮไลท์ 1',
        },
        serviceHighlightDiscription1: {
          type: FormType.Textarea,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Service Highlight Description 1',
          description: 'คำอธิบายบริการที่ไฮไลท์ 1',
        },

        serviceHighlightTitle2: {
          type: FormType.Text,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Service Highlight Title 2',
          description: 'หัวข้อบริการที่ไฮไลท์ 2',
        },
        serviceHighlightDiscription2: {
          type: FormType.Textarea,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Service Highlight Description 2',
          description: 'คำอธิบายบริการที่ไฮไลท์ 2',
        },

        serviceHighlightTitle3: {
          type: FormType.Text,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Service Highlight Title 3',
          description: 'หัวข้อบริการที่ไฮไลท์ 3',
        },
        serviceHighlightDiscription3: {
          type: FormType.Textarea,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Service Highlight Discription 3',
          description: 'คำอธิบายบริการที่ไฮไลท์ 3',
        },

        serviceHighlightTitle4: {
          type: FormType.Text,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Service Highlight Title 4',
          description: 'หัวข้อบริการที่ไฮไลท์ 4',
        },
        serviceHighlightDiscription4: {
          type: FormType.Textarea,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Service Highlight Discription 4',
          description: 'คำอธิบายบริการที่ไฮไลท์ 4',
        },
      },
    },
    HomeBanner: {
      apiPath: 'HomeBanner',
      pathUrl: '/cms/homeBanner',
      tableName: 'Content',
      model: new Object(),
      isList: true,
      componentName: 'homeBanner',
      formConfig: {
         title: {
          type: FormType.Text,
          validator: [Validators.required],
          label: 'Banner Title',
          description: 'หัวข้อแบนเนอร์',
        },
        name: {
          type: FormType.Text,
          validator: [Validators.required],
          label: 'Banner Name',
          isMultipleLang: true,
          description: 'ชื่อแบนเนอร์',
        },
        bannerVDO: {
          type: FormType.Video,
          // validator: [Validators.required],////
          label: 'Banner VDO',
          description: 'วีดีโอแบนเนอร์\n Resolution: 1900px*965px',
        },
        bannerIMG: {
          type: FormType.SingleImage,
          validator: [Validators.required],

          label: 'Banner Image',
          description: 'รูปภาพแบนเนอร์\n Resolution: 1900px*965px',
        },

        isUseVDO: {
          type: FormType.Switch,

          label: 'Use Banner as VDO',
          description: 'ใช้แบนแนอร์ในรูปแบบวีดีโอ',
        },
       

        isActive: {
          type: FormType.IsActive,
          label: 'Active Status',
          labelTranslate: 'Informative.ActiveStatus',
        },
      },
    },
    News: {
      apiPath: 'News',
      pathUrl: '/cms/news',
      tableName: 'Content',
      model: new Object(),
      isList: true,
      componentName: 'News',
      formConfig: {
        contentDate: {
          type: FormType.Datepicker,
          validator: [Validators.required],
          label: 'News Date',
          description: 'วันที่ของข่าวสาร',
        },
        name: {
          type: FormType.Text,
          validator: [Validators.required],
          // isMultipleLang: true,
          label: 'News Name',
          description: 'ชื่อข่าวสาร',
        },

        title: {
          type: FormType.Text,
          validator: [Validators.required],
          isMultipleLang: true,
          label: 'News Title',
          description: 'หัวข้อข่าวสาร',
        },

        newsThumbnails: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'News Picture',
          description: 'ภาพปก \nResolution: 1178px*560px\n ',
        },

        subTitle: {
          type: FormType.Textarea,
          validator: [Validators.required],
          isMultipleLang: true,
          label: 'News Description',
          description: 'คำอธิบายข่าวสารอย่างย่อ',
        },

        detail: {
          type: FormType.RichText,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'News Content',
          description: 'เนื้อหาของข่าวสาร',
        },
        isHighlight: {
          type: FormType.Switch,
          label: 'Is Highlight',
        },

        isActive: {
          type: FormType.IsActive,
          // radioList: [
          //   { label: 'Active', translate: 'Status.Active', value: true },
          //   { label: 'Inactive', translate: 'Status.Inactive', value: false },
          // ],
          label: 'Active Status',
          labelTranslate: 'Informative.ActiveStatus',
        },
      },
    },
    NewsLanding: {
      apiPath: 'NewsLanding',
      pathUrl: '/cms/news-landing',
      tableName: 'Content',
      model: new News(),
      isList: false,
      componentName: 'News',
      formConfig: {
        bannerNews: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Banner Background',
          description: 'ภาพแบนเนอร์ \n Resolution: 1900px*407px',
        },
        bannerNewsMobile: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Banner Background Mobile',
          description: 'ภาพแบนเนอร์สำหรับโทรศัพท์\n Resolution: 375px*310px',
        },
      },
    },
    OurClient: {
      apiPath: 'OurClient',
      pathUrl: '/cms/our-client',
      tableName: 'Content',
      model: new Object(),
      isList: false,
      componentName: 'Our Client',
      formConfig: {
        ourClientBannerImage: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Banner Background',
          description: 'ภาพพื้นหลัง Banner\n Resolution: 1900px*407px',
        },
        ourClientBannerImageMobile: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Banner Background Mobile',
          description: 'ภาพพื้นหลัง Banner สำหรับโทรศัพท์\n Resolution: 375px*310px',
        },

        ourClientBannerTitle: {
          type: FormType.Textarea,
          validator: [Validators.required],
          isMultipleLang: true,
          label: 'Description',
          description: 'Description ของ Section Our Client Wording ขวาตัวบาง',
        },
      },
    },
    OurClientPartner: {
      apiPath: 'OurClientPartner',
      pathUrl: '/cms/our-client-partner',
      tableName: 'Content',
      model: new Object(),
      isList: true,
      componentName: 'Our Client Partner',
      formConfig: {
        name: {
          type: FormType.Text,
          validator: [Validators.required],
          label: 'Client Name',
          description: 'ชื่อของ Client',
        },

        ourClientLogo: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Client Logo',
          description: 'Logo ของ Client\n Resolution: 116px*116px',
        },

        isActive: {
          type: FormType.IsActive,
          label: 'Active Status',
          labelTranslate: 'Informative.ActiveStatus',
        },
      },
    },
    OurClientPerformance: {
      apiPath: 'OurClientPerformance',
      pathUrl: '/cms/our-client-performance',
      tableName: 'Content',
      model: new Object(),
      isList: true,
      componentName: 'Our Client Performance',
      formConfig: {
        performanceThumbnail: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Thumbnail',
          description: 'รูปของผลงาน\n Resolution: 424px*541px',
        },
        name: {
          type: FormType.Text,
          validator: [Validators.required],
          label: 'Name',
          description: 'ชื่อของผลงาน',
        },
        title: {
          type: FormType.Text,
          validator: [Validators.required],
          isMultipleLang: true,
          label: 'Title',
          description: 'Title ของผลงาน',
        },

        contentDate: {
          type: FormType.Datepicker,
          label: 'Content Date',
          description: 'วันที่ของผลงาน',
        },

        isActive: {
          type: FormType.IsActive,
          label: 'Active Status',
          labelTranslate: 'Informative.ActiveStatus',
        },
      },
    },
    PrivacyPolicy: {
      apiPath: 'PrivacyPolicy',
      tableName: 'Content',
      pathUrl: '/cms/privacy-policy',
      model: new PDPA(),
      isList: false,
      componentName: 'Privacy Policy',
      formConfig: {
        bannerPDPA: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Banner Background',
          description: 'ภาพพื้นหลัง \nResolution: 1897px*407px\n ',
        },

        bannerPDPAMobile: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Banner Background Mobile',
          description: 'ภาพพื้นหลังสำหรับโทรศัพท์\n Resolution: 375px*310px',
        },

        descriptionPDPA: {
          type: FormType.RichText,
          isMultipleLang: true,
          validator: [Validators.required],
          label: 'Description',
          description: 'รายละเอียด Privacy Policy',
        },
      },
    },
    ProductsServices: {
      apiPath: 'ProductsServices',
      tableName: 'Content',
      pathUrl: 'cms/product-service/landing',
      model: new Object(),
      isList: false,
      componentName: 'Products Services',
      formConfig: {
        productsServicesBanner: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Banner Background',
          description: 'รูปภาพแบนเนอร์\n Resolution: 1900px*407px',
        },

        productsServicesBannerMobile: {
          type: FormType.SingleImage,
          validator: [Validators.required],
          label: 'Banner Background Mobile',
          description: 'รูปภาพแบนเนอร์สำหรับโทรศัพท์\n Resolution: 375px*310px',
        },
        innovationHighlight1: {
          type: FormType.Select,
          selectDataAPI: 'ProductsServicesItem?CommonEnum=3&isDelete=false&isActive=true',
          selectDataLabelColumn: 'name',
          selectDataValueColumn: 'id',
          label: 'Innovation Highlight 1',
          description: 'นวัตกรรมที่ไฮไลท์ 1',
        },
        innovationHighlight2: {
          type: FormType.Select,
          selectDataAPI: 'ProductsServicesItem?CommonEnum=3&isDelete=false&isActive=true',
          selectDataLabelColumn: 'name',
          selectDataValueColumn: 'id',
          label: 'Innovation Highlight 2',
          description: 'นวัตกรรมที่ไฮไลท์ 2',
        },
        innovationHighlight3: {
          type: FormType.Select,
          selectDataAPI: 'ProductsServicesItem?CommonEnum=3&isDelete=false&isActive=true',
          selectDataLabelColumn: 'name',
          selectDataValueColumn: 'id',
          label: 'Innovation Highlight 3',
          description: 'นวัตกรรมที่ไฮไลท์ 3',
        },
      },
    },
    Product: {
      apiPath: 'ProductsServicesItem',
      tableName: 'Content',
      pathUrl: 'cms/product-service/product',
      model: new Object(),
      isList: false,
      componentName: 'Products',
      formConfig: {
        name: {
          type: FormType.Text,
          validator: [Validators.required],
          label: 'Product Name',
          description: 'ชื่อผลิตภัณฑ์',
        },
        title: {
          type: FormType.Text,
          validator: [Validators.required],
          isMultipleLang: true,

          label: 'Product Title',
          description: 'หัวข้อผลิตภัณฑ์',
        },
        
        contentDate: {
          type: FormType.Datepicker,
          label: 'Content Date',
          description: 'วันที่ของเนื้อหา',
        },
        thumbnails: {
          type: FormType.SingleImage,
          label: 'Thumbnails',
          description: 'รูปภาพหน้าปก\n Resolution: 415px*488px',
        },
        subTitle: {
          type: FormType.Textarea,
          isMultipleLang: true,
          label: 'Description',
          description: 'คำอธิบายอย่างย่อ',
        },
        detail: {
          type: FormType.RichText,
          isMultipleLang: true,
          label: 'Detail',
          description: 'รายละเอียด',
        },
        subThumbnails: {
          type: FormType.SingleImage,
          label: 'Bottom Section Image',
          description: 'รูปภาพ Section ด้านล่าง\n Resolution: 640px*746px',
        },
        reserved1: {
          type: FormType.RichText,
          isMultipleLang: true,
          label: 'Bottom Section Description',
          description: 'คำอธิบาย Section ด้านล่าง',
        },
        gallery: {
          type: FormType.MultipleImage,
          fileTypeFilter: FileTypeFilter.Images,
          limit: 6,
          label: 'Gallery',
          description: 'แกลลอรี่รูปภาพ\n Resolution: 416px*495px',
        },
        commonEnum: {
          type: FormType.StaticValue,
          staticValue: 1,
        },
        isActive: {
          type: FormType.IsActive,
          label: 'Active Status',
          labelTranslate: 'Informative.ActiveStatus',
        },
      },
    },
    Service: {
      apiPath: 'ProductsServicesItem',
      tableName: 'Content',
      pathUrl: 'cms/product-service/service',
      model: new Object(),
      isList: false,
      componentName: 'Services',
      formConfig: {
        
        name: {
          type: FormType.Text,
          validator: [Validators.required],
          label: 'Service Name',
          description: 'ชื่อบริการ',
        },
        title: {
          type: FormType.Text,
          validator: [Validators.required],
          isMultipleLang: true,
          label: 'Service Title',
          description: 'หัวข้อบริการ',
        },
        contentDate: {
          type: FormType.Datepicker,
          label: 'Content Date',
          description: 'วันที่ของเนื้อหา',
        },
        thumbnails: {
          type: FormType.SingleImage,
          label: 'Thumbnails',
          description: 'รูปภาพหน้าปก\n Resolution: 415px*488px',
        },
        subTitle: {
          type: FormType.Textarea,
          isMultipleLang: true,
          label: 'Description',
          description: 'คำอธิบายอย่างย่อ',
        },
        detail: {
          type: FormType.RichText,
          isMultipleLang: true,
          label: 'Detail',
          description: 'รายละเอียด',
        },
        subThumbnails: {
          type: FormType.SingleImage,
          label: 'Bottom Section Image',
          description: 'รูปภาพ Section ด้านล่าง\n Resolution: 640px*746px',
        },
        reserved1: {
          type: FormType.RichText,
          isMultipleLang: true,
          label: 'Bottom Section Description',
          description: 'คำอธิบาย Section ด้านล่าง',
        },
        gallery: {
          type: FormType.MultipleImage,
          fileTypeFilter: FileTypeFilter.Images,
          limit: 6,
          label: 'Gallery',
          description: 'แกลลอรี่รูปภาพ\n Resolution: 416px*495px',
        },
        commonEnum: {
          type: FormType.StaticValue,
          staticValue: 2,
        },
        isActive: {
          type: FormType.IsActive,
          label: 'Active Status',
          labelTranslate: 'Informative.ActiveStatus',
        },
      },
    },
    Innovation: {
      apiPath: 'ProductsServicesItem',
      tableName: 'Content',
      pathUrl: 'cms/product-service/innovation',
      model: new Object(),
      isList: false,
      componentName: 'Innovation',
      formConfig: {
        
        name: {
          type: FormType.Text,
          validator: [Validators.required],
          label: 'Innovation Name',
          description: 'ชื่อนวัตกรรม',
        },
        title: {
          type: FormType.Text,
          validator: [Validators.required],
          isMultipleLang: true,
          label: 'Innovation Title',
          description: 'หัวข้อนวัตกรรม',
        },
        contentDate: {
          type: FormType.Datepicker,
          label: 'Content Date',
          description: 'วันที่ของเนื้อหา',
        },
        thumbnails: {
          type: FormType.SingleImage,
          label: 'Thumbnails',
          description: 'รูปภาพหน้าปก\n Resolution: 415px*488px',
        },
        subTitle: {
          type: FormType.Textarea,
          isMultipleLang: true,
          label: 'Description',
          description: 'คำอธิบายอย่างย่อ',
        },
        detail: {
          type: FormType.RichText,
          isMultipleLang: true,
          label: 'Detail',
          description: 'รายละเอียด',
        },
        subThumbnails: {
          type: FormType.SingleImage,
          label: 'Bottom Section Image',
          description: 'รูปภาพ Section ด้านล่าง\n Resolution: 640px*746px',
        },
        reserved1: {
          type: FormType.RichText,
          label: 'Bottom Section Description',
          isMultipleLang: true,
          description: 'คำอธิบาย Section ด้านล่าง',
        },
        gallery: {
          type: FormType.MultipleImage,
          fileTypeFilter: FileTypeFilter.Images,
          limit: 6,
          label: 'Gallery',
          description: 'แกลลอรี่รูปภาพ\n Resolution: 416px*495px',
        },
        innovationVDO: {
          type: FormType.Video,
          label: 'Video Background',
          description:
            'วีดีโอพื้นหลังสำหรับนวัตกรรม\n Resolution: 1900px*1200px',
        },
        innovationVDOMobile: {
          type: FormType.Video,
          label: 'Video Background Mobile',
          description:
            'วีดีโอพื้นหลังสำหรับนวัตกรรมสำหรับโทรศัพท์\n Resolution: 375px*748px',
        },
        isUseVDO: {
          type: FormType.Switch,
          label: 'Use Video',
          description: 'ใช้วีดีโอพื้นหลังหรือไม่',
        },
        commonEnum: {
          type: FormType.StaticValue,
          staticValue: 3,
        },
        isActive: {
          type: FormType.IsActive,
          label: 'Active Status',
          labelTranslate: 'Informative.ActiveStatus',
        },
      },
    },
  };
}
