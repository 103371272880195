import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ApiService } from 'app/main/service/api.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { FlatpickrOptions } from 'ng2-flatpickr';
import { ImageUploadComponent } from '../../image-upload/image-upload.component';

import { MainConfig } from 'app/main/config/MainConfig';
import { ModalComponent } from '../../modal/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CmsFormConfig } from 'app/main/config/CMSFormConfig';
import { ComponentsService } from '../../components.service';
import {
  FormType,
  ICustomFile,
  IRadioForm,
  IformConfig,
  IFilesForm,
} from 'app/main/config/CMSInterface';
import { FilesApiService } from 'app/main/service/files-api.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { resolve } from 'dns';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CustomDropzoneComponent } from '../../custom-dropzone/custom-dropzone.component';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-form-template',
  templateUrl: './form-template.component.html',
  styleUrls: ['./form-template.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormTemplateComponent implements OnInit, OnDestroy, AfterViewInit {
  private _unsubscribeAll: Subject<any> = new Subject();
  itemID: string;
  @ViewChildren('imageUploadComponent')
  imageUploadComponent: QueryList<ImageUploadComponent>;

  @ViewChildren('customDropzoneComponent')
  customDropzoneComponent: QueryList<CustomDropzoneComponent>;
  @ViewChild('customDropzoneComponent')
  invalidCustomDropzoneComponent: CustomDropzoneComponent;
  @ViewChildren('videoUploadComponent')
  videoUploadComponent: QueryList<ElementRef>;

  countMedia: number;
  alertFiles: boolean;
  isLoading: boolean;
  loadingVideo: boolean;
  loadingFile: boolean;
  formGroup: FormGroup;
  formType = FormType;
  isSubmit: Boolean = false;
  invalidAlert: Boolean = false;
  invalidMutiplefile: boolean;

  mediaData: any[] = [];
  files: File[] = [];
  images: Blob[] = [];

  @BlockUI() blockUI: NgBlockUI;
  @Input() itemObj!: any; // Your JSON object
  @Input() apiPath: string;
  @Input() componentName: string;
  @Input() tableName: string = 'content';
  @Input() pathUrl: string;
  @Input() config!: { [key: string]: IformConfig };
  @Input() isListMode: boolean = false;
  @Input() isEditing: boolean = false;
  @Input() isLoadingData: boolean;
  @Input() isUseMultipleLang: boolean = true;

  isDataLoading: boolean = false;

  // public dateTimeOptions: FlatpickrOptions = {
  //   altInput: true,
  //   enableTime: true,
  //   defaultDate: '',
  // };

  public summernoteConfig;

  handleList: any[] = [];

  optionList: any[];
  datepickerList: FlatpickrOptions[];
  radioList: IRadioForm[];
  selectedVideoFile: File | null; // Variable to store the selected video file
  previewCoverURL: any | null; // Variable to store the preview cover URL
  videoFileSize: number = environment.limitFileSize.maxVideoSizeInBytes;
  maxFileSize: number = environment.limitFileSize.maxFileSizeInBytes;
  imageFileSize: number = environment.limitFileSize.maxImageSizeInBytes;
  singleFile: any;
  singleVideo: any;
  singleFileList: any[];
  // singleVideoList: any[];
  multipleFilesList: any[];
  previewFilesList: any[];

  @ViewChild('componentTemplate', { static: false })
  componentTemplate: ElementRef;

  selectedVideoName: string = 'Video : input';

  selectedLang: string = environment.langDefault;
  langList = environment.langContent;

  constructor(
    private sanitizer: DomSanitizer,
    private _route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _fileService: FilesApiService,
    private _mainConfig: MainConfig,
    private _modalService: NgbModal,
    private _router: Router,
    private _apiService: ApiService,
    private _translateService: TranslateService,
    private _componentsService: ComponentsService,
    private _globalFuncService: GlobalFuncService,
    private renderer: Renderer2,
    private el: ElementRef,
    private cdr: ChangeDetectorRef
  ) {
    this.SetLoadingState();
    // this.blockUI.start();
    this.optionList = [];
    this.datepickerList = [];
    this.radioList = [];
    this.singleFileList = [];
    // this.singleVideoList = [];
    this.multipleFilesList = [];
    this.isEditing = false;
    this.summernoteConfig = this._mainConfig.summernoteNewsConfig;
    this.blockUI.start();
  }

  onFilesUploaded(files: File[], key): void {
    this.multipleFilesList[key] = files;
    console.log(key, this.multipleFilesList[key]);
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  callSelectVideo(key) {
    const vdoInput = this.videoUploadComponent.find(
      (element: ElementRef) => element.nativeElement.id === key
    );

    vdoInput.nativeElement.click();
  }

  ngOnInit() {
    this.formGroup = this._formBuilder.group({});

    // if (!this.isListMode) {
    //   this.isEditing = true;
    // }
    this.createForm();
  }

  ngAfterViewInit(): void {
    console.log('2', this.previewFilesList, this.singleFileList);

    if (this.previewFilesList) {
      console.log('โหลดเสร็จหมดล้าวววววว');
    }
    if (!this.isEditing) {
      this.blockUI.stop();
    }
  }

  ngOnDestroy(): void {
    this.blockUI.stop();
  }

  createForm() {
    var self = this;
    this.formGroup = this.createFormGroup(self.config);

    this.isLoading = false;
    // console.log(this.formGroup.controls);
  }

  private createFormGroup(obj: IformConfig): FormGroup {
    const group = this._formBuilder.group({});

    for (const key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'object') {
        if (obj[key].type === FormType.IsActive || obj[key].type === FormType.Divider) {
          continue;
        } else if (obj[key].type === FormType.Select) {
          group.addControl(
            key,
            this._formBuilder.control('', obj[key].validator)
          );
          this.optionList[key] = [];

          if (obj[key].isUseMultipleAPI && obj[key].selectDataMultipleAPI) {
            obj[key].selectDataMultipleAPI.forEach((element) => {
              this._apiService
                .GetAllData(element, { isActive: true, isDelete: false })
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((res) => {
                  let tempArr = res.data.resultData;
                  tempArr.forEach((element) => {
                    this.optionList[key].push(element);
                  });
                });
            });
            console.log(key, this.optionList[key]);
          } else {
            if (obj[key].selectDataAPI) {
              let selectDataAPI = obj[key].selectDataAPI;
              this._apiService
                .GetAllData(selectDataAPI, { isActive: true, isDelete: false })
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((res) => {
                  this.optionList[key] = res.data.resultData;
                });
            } else {
              this.optionList[key] = obj[key].selectDataList;
            }
          }
        } else if (obj[key].type === FormType.Datepicker) {
          let tempDate = new Date();
          let tempOption: FlatpickrOptions = {
            dateFormat: 'd M Y',
            altInput: false,
            enableTime: obj[key].useTime,
            defaultDate: tempDate.toISOString(),
          };
          this.datepickerList[key] = tempOption;
          group.addControl(
            key,
            this._formBuilder.control('', obj[key].validator)
          );
        } else if (obj[key].type === FormType.Radio) {
          this.radioList[key] = obj[key].radioList;
          continue;
        } else if (obj[key].type === FormType.Switch) {
          group.addControl(key, this._formBuilder.control(false, []));
          continue;
        } else if (
          obj[key].type === FormType.MultipleImage ||
          obj[key].type === FormType.MultipleFiles
        ) {
          this.multipleFilesList[key] = [];
          continue;
        } else if (
          obj[key].type === FormType.SingleFile ||
          obj[key].type === FormType.SingleImage ||
          obj[key].type === FormType.Video
        ) {
          if (obj[key].type === FormType.Video) {
            this.singleFileList[key] = {
              file: null,
              filename: null,
              coverImageURL: null,
            };

            continue;
          } else {
            this.singleFileList[key] = {
              file: null,
              filename: null,
              coverImageURL: null,
            };
            continue;
          }
        } else if (obj[key].type === FormType.Select) {
          this.optionList[key] = obj[key].selectDataList;
        } else if (obj[key].type === FormType.StaticValue) {
          group.addControl(
            key,
            this.createFormControl(obj[key], obj[key].staticValue)
          );
        } else {
          if (obj[key].isParent) {
            if (obj[key].child) {
              group.addControl(key, this.createFormGroup(obj[key].child));
            } else {
              group.addControl(key, this.createFormGroup(obj[key]));
            }
          } else if (obj[key].isMultipleLang) {
            const langControls: any = {};
            for (const lang of this.langList) {
              langControls[lang] = this._formBuilder.control(
                '',
                obj[key].validator
              );
            }
            group.addControl(key, this._formBuilder.group(langControls));
          } else {
            let value = this.itemObj[key];
            let validators = obj.validator;

            group.addControl(key, this.createFormControl(obj[key]));
          }
        }
      }
    }

    return group;
  }

  private createFormControl(fieldConfig: any, value: any = ''): FormControl {
    return this._formBuilder.control(value, fieldConfig.validator);
  }

  showLog($event) {}

  selectedLanguage(lang: string) {
    this.selectedLang = lang;
  }

  getFieldType(key: string): string {
    return this._globalFuncService.getPropertyByPath(this.config, key).type;
  }

  getFieldLabel(key: string): string {
    let tempObj = this._globalFuncService.getPropertyByPath(this.config, key);
    if (tempObj.labelTranslate) {
      return this._translateService.instant(tempObj.labelTranslate);
    } else {
      return tempObj.label;
    }
  }

  getFieldDescription(key: string): string {
    let tempObj = this._globalFuncService.getPropertyByPath(this.config, key);
    if (tempObj.descriptionTranslate) {
      return this._translateService.instant(tempObj.descriptionTranslate);
    } else {
      return tempObj.description;
    }
  }

  getIsRequiredField(key: string): boolean {
    return (
      this._globalFuncService.getPropertyByPath(this.config, key).validator !=
      null
    );
  }

  getFieldLimit(key: number): number {
    return this.config[key].limit ? this.config[key].limit : 5;
  }

  getSelectLabelColumn(key): string {
    return this.config[key].selectDataLabelColumn
      ? this.config[key].selectDataLabelColumn
      : 'label';
  }

  getSelectValueColumn(key): string {
    return this.config[key].selectDataValueColumn
      ? this.config[key].selectDataValueColumn
      : 'value';
  }

  getTypeFilesFilter(key: string): number {
    return this.config[key].fileTypeFilter;
  }

  getFieldValue(key, field?): any {
    return this._globalFuncService.getPropertyByPath(this.itemObj, key);
  }

  getFieldMaxLength(key): number | null {
    let tempObj = this._globalFuncService.getPropertyByPath(this.itemObj, key);
    return tempObj.maxTextLength ? tempObj.maxTextLength : null;
  }

  getDefaultSelectLabel(key: string): string {
    let tempObj = this._globalFuncService.getPropertyByPath(this.config, key);
    if (!isNullOrUndefined(tempObj.defaultSelectLabelTranslate)) {
      return this._translateService.instant(
        tempObj.defaultSelectLabelTranslate
      );
    } else if (!isNullOrUndefined(tempObj.defaultSelectLabel)) {
      return tempObj.defaultSelectLabel;
    } else {
      return 'All';
    }
  }

  getPreviewImageUrl(key: string): any {
    return this.singleFileList[key].coverImageURL;
  }

  getVideoName(key: string): any {
    return this.singleFileList[key].filename;
  }

  logDate(event, key) {}
  setFormValue(data: any) {
    if (!data) {
      // this.initEmptyRow()
    }
    this.mediaData = data.mediaFiles;
    this.setFormGroupValue(this.config, data);
  }

  initEmptyRow(): Promise<void> {
    this.formGroup.patchValue({
      InnovationHighlight1: 0,
      InnovationHighlight2: 0,
      InnovationHighlight3: 0,
    });
    return new Promise((resolve, rejects) => {
      this._apiService
        .UpdateWithOutId(this.apiPath, this.formGroup.value)
        .pipe(take(1))
        .subscribe((res) => {
          window.location.reload();
        });
    });
  }

  returnZero() {
    return 0;
  }

  private setFormGroupValue(config: any, itemObj: any, parentKey?: string) {
    this.isEditing = true;

    let tempIndex = 0;
    var self = this;

    for (let key in config) {
      const fieldType = config[key].type;
      switch (fieldType) {
        case FormType.SingleFile:
          if (itemObj.mediaFiles[key]) {
            const fileId = itemObj.mediaFiles[key][0].id;

            this._fileService.GetFileDetail(fileId).subscribe((r) => {
              this._fileService.GetFile(fileId).subscribe(async (res) => {
                const file = new File([res], fileId, {
                  type: res.type,
                });

                this.singleFileList[key] = {
                  file: file,
                  filename:
                    r.data.resultData[0].name +
                    '.' +
                    r.data.resultData[0].extension,
                  coverImageURL: null,
                };
              });
            });
          }
          break;

        case FormType.SingleImage:
          if (itemObj.mediaFiles[key]) {
            console.log('CASE SINGLE IMAGE');

            const targetElement = this.imageUploadComponent.find(
              (component: ImageUploadComponent) =>
                component.elementRef.nativeElement.id === key
            );
            targetElement.startLoading();

            const singleImageArr = new FormData();
            const Image = itemObj.mediaFiles[key][0].id;

            this._fileService.GetFile(Image).subscribe((res) => {
              targetElement.setCoverImage(res, Image);
              // this.singleImageList[key] = res;
            });
          }
          break;
        case FormType.Video:
          if (itemObj.mediaFiles[key]) {
            const singleImageArr = new FormData();
            const video = itemObj.mediaFiles[key][0].id;

            this._fileService.GetFile(video).subscribe(async (res) => {
              const file = new File([res], video, {
                type: res.type,
              });

              const vdoInput = this.videoUploadComponent.find(
                (element: ElementRef) => element.nativeElement.id === key
              );

              this.singleFileList[key] = {
                file: file,
                filename: video,
                coverImageURL: await this.generatePreviewCover(file),
              };
              console.log(
                'OLOLOLOLOLOLOLOLOLOLOLOLOLOOLO',
                this.itemObj.mediaFiles
              );
            });
          }
          break;

        case FormType.Switch:
          let tempValue = this._globalFuncService.getPropertyByPath(
            itemObj,
            key
          );

          this.setFormControl(
            this.formGroup,
            key,
            !isNullOrUndefined(tempValue) ? tempValue : false
          );
          break;

        case FormType.Datepicker:
          const tempDate = new Date(itemObj[key]);
          this.datepickerList[key].defaultDate = tempDate.toISOString();
          break;

        case FormType.MultipleFiles:
        case FormType.MultipleImage:
          if (itemObj.mediaFiles[key]) {
            const targetElement = this.customDropzoneComponent.find(
              (component: CustomDropzoneComponent) =>
                component.elementRef.nativeElement.id === key
            );
            targetElement.startLoading();

            const fileArr = new FormData();
            const files = itemObj.mediaFiles[key];
            let tempFilesList: { file: any; ordinal: number }[] = [];
            this.multipleFilesList[key] = [];

            const filePromises: Promise<void>[] = files.map(
              (element: { id: string; ordinal: number }, index) => {
                return new Promise<void>((resolve) => {
                  this._fileService.GetFile(element.id).subscribe((res) => {
                    const type = res.type.split('/')[1];
                    const name = element + '.' + type;
                    const file = new File([res], name, { type: res.type });
                    tempFilesList.push({
                      file: file,
                      ordinal: element.ordinal,
                    });

                    resolve();
                  });
                });
              }
            );

            Promise.all(filePromises).then(() => {
              tempFilesList.sort((a, b) => a.ordinal - b.ordinal);

              tempFilesList.forEach((element) => {
                this.multipleFilesList[key].push(element.file);
              });
              console.log(this.multipleFilesList[key]);

              if (this.multipleFilesList[key].length === files.length) {
                targetElement.ngOnChangesConfig(this.multipleFilesList[key]);
              }
            });
          }

          break;

        case FormType.Radio:
          // this.formGroup.controls[key].setValue(itemObj[key]);
          break;
      }

      if (parentKey) {
        key = `${parentKey}.${key}`;
      }
      if (config.hasOwnProperty(key) && typeof config[key] === 'object') {
        if (config[key].isParent && config[key].child) {
          this.setFormGroupValue(
            this._globalFuncService.getPropertyByPath(config, key).child,
            this._globalFuncService.getPropertyByPath(itemObj, key),
            key
          );
        } else if (config[key].isMultipleLang) {
          let tempObj = this._globalFuncService.getPropertyByPath(itemObj, key);
          try {
            if (!tempObj) {
              tempObj = {};
            } else {
              tempObj = JSON.parse(tempObj);
            }
          } catch (error) {
            tempObj = {};
          }
          const langControls: any = {};
          for (const lang of this.langList) {
            let tempLangKey = `${key}.${lang}`;
            this.setFormControl(this.formGroup, tempLangKey, tempObj[lang]);
          }
        } else {
          this.setFormControl(
            this.formGroup,
            key,
            this._globalFuncService.getPropertyByPath(itemObj, key)
          );
        }
      }

      tempIndex++;
    }

    // console.log(Object.keys(config).length)
    if (tempIndex == Object.keys(config).length) {
      this.blockUI.stop();
    }
    console.log('LAST LOG TO SHOW');
  }

  private setFormControl(formGroup: FormGroup, key: string, value: any) {
    formGroup.get(key)?.patchValue(value);
  }

  openConfirmModal(content: any): void {
    console.log(this.singleFileList);

    this.isSubmit = true;
    let isConfirm = true;

    let title = this._translateService.instant('Modal.ConfirmCreate');
    let detail = this._translateService.instant('Modal.AreYouSureToCreate');

    console.log(this.checkFilesValidate());

    if (this.formGroup.status === 'INVALID' || !this.checkFilesValidate()) {
      this.invalidAlert = true;
      if (this.formGroup.status === 'INVALID') {
        this.scrollToFirstInvalidControl();
      }
    } else {
      this.openModal(title, detail, isConfirm);
    }
  }

  private scrollToFirstInvalidControl(el?) {
    this._globalFuncService.scrollToFirstInvalidControl('generative-form', el);
  }

  openCancelModal(content: any): void {
    let isConfirm = false;

    let title = this._translateService.instant('Modal.CancelManagePage');
    let detail = this._translateService.instant('Modal.PressSubmitToCancel');
    this.openModal(title, detail, isConfirm);
  }

  setRadioValue(controlName: string, value: any): void {
    this.formGroup.controls[controlName].setValue(value);
  }

  updateRadioValue(controlName: string, value: any): void {
    this.formGroup.controls[controlName].setValue(value);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.isConfirm = IsConfirm;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      if (IsConfirm) {
        this.Submit();
      } else {
        this._router.navigate([`${this.pathUrl}`]);
      }
    });
  }

  prepareFinalData(
    config: any,
    data: any,
    tempForm: FormGroup,
    parentKey?: string
  ) {
    for (let key in config) {
      if (parentKey) {
        key = `${parentKey}.${key}`;
      }
      Object.keys(config).forEach((key) => {
        if (config[key].type == FormType.Datepicker) {
          let dateValue = this.formGroup.get(key)?.value;
          let tempDate = new Date(dateValue[0]);
          tempForm.addControl(
            key,
            this._formBuilder.control(tempDate.toISOString())
          );
        }

        if (config[key].type == FormType.Switch) {
          let tempBool = this.formGroup.get(key)?.value;

          if (!tempBool || isNullOrUndefined(tempBool)) {
            tempBool = false;
          }
          tempForm.addControl(key, this._formBuilder.control(tempBool));
        }
      });
      if (config.hasOwnProperty(key) && typeof config[key] === 'object') {
        if (
          config[key].type === FormType.SingleImage ||
          config[key].type === FormType.SingleFile ||
          config[key].type === FormType.MultipleFiles ||
          config[key].type === FormType.MultipleImage ||
          config[key].type === FormType.Datepicker ||
          config[key].type === FormType.Video ||
          config[key].type === FormType.IsActive ||
          config[key].type === FormType.Divider
        ) {
          continue;
        }
        if (config[key].isParent && config[key].child) {
          this.prepareFinalData(
            this._globalFuncService.getPropertyByPath(config, key).child,
            this.formGroup.get(key)?.value,
            tempForm
          );
        } else if (config[key].isMultipleLang) {
          tempForm.addControl(key, this._formBuilder.control(''));
          const multiLangObj = JSON.stringify(this.formGroup.get(key)?.value);
          tempForm.get(key)?.patchValue(multiLangObj);
        } else {
          tempForm.addControl(
            key,
            this._formBuilder.control(this.formGroup.get(key)?.value)
          );
        }
      }
    }
  }

  handleIsNotFileChange(isNotFile: boolean, key: string) {
    this.invalidMutiplefile = isNotFile;

    if (this.customDropzoneComponent) {
      const targetElement = this.customDropzoneComponent.find(
        (component: CustomDropzoneComponent) =>
          component.elementRef.nativeElement.id === key
      );

      if (targetElement) {
        targetElement.handleBox = this.invalidMutiplefile;
        this.cdr.detectChanges();
      }
    }
  }

  checkFilesValidate(): boolean {
    var self = this;
    let isInvalid = false;
    let isScrollToFirstInvalidFile = false;

    Object.keys(self.config).forEach((key) => {
      if (self.config[key].validator) {
        if (self.config[key].type === FormType.SingleImage) {
          let targetElement = this.imageUploadComponent.find(
            (component: ImageUploadComponent) =>
              component.elementRef.nativeElement.id === key
          );

          if (!targetElement.getFile()) {
            targetElement.handleImage();
            if (!isScrollToFirstInvalidFile) {
              this.scrollToFirstInvalidControl(
                targetElement.elementRef.nativeElement as Element
              );
              isScrollToFirstInvalidFile = true;
            }
            isInvalid = true;
          }
        } else if (
          self.config[key].type === FormType.SingleFile ||
          self.config[key].type === FormType.Video
        ) {
          if (
            !self.singleFileList[key] ||
            self.singleFileList[key].length < 1
          ) {
            if (self.config[key].type === FormType.Video) {
              if (!isScrollToFirstInvalidFile) {
                let targetElement = this.videoUploadComponent.find(
                  (component: ElementRef) => component.nativeElement.id === key
                );
                this.scrollToFirstInvalidControl(
                  targetElement.nativeElement as Element
                );
                isScrollToFirstInvalidFile = true;
              }
            }
            isInvalid = true;
          }
        }

        if (
          self.config[key].type == FormType.MultipleFiles ||
          self.config[key].type == FormType.MultipleImage
        ) {
          if (
            !this.multipleFilesList[key] ||
            this.multipleFilesList[key].length < 1
          ) {
            isInvalid = true;
            this.handleIsNotFileChange(isInvalid, key);
          }
        }
      }

      if (
        self.config[key].type == FormType.MultipleFiles ||
        self.config[key].type == FormType.MultipleImage
      ) {
        console.log(this.multipleFilesList[key].length, self.config[key].limit);

        if (this.multipleFilesList[key].length > self.config[key].limit) {
        }
      }
    });

    return !isInvalid;
  }

  Submit() {
    this.blockUI.start();
    var self = this;

    // if (this.formGroup.invalid) {
    //   self._componentsService.ErrorSwal();
    //   return;
    // }
    // if (this.itemObj.isActive) {
    // }
    let tempForm = new FormGroup({});
    this.prepareFinalData(this.config, this.formGroup.value, tempForm);
    if (this.isEditing) {
      this._route.paramMap.subscribe((val) => {
        let docID = this._route.snapshot.paramMap.get('id');
        if (docID) {
          // UpdateWithOutId
          self._apiService
            .UpdateDataById(
              this.apiPath,
              this.itemObj.id.toString(),
              tempForm.value
            )
            .subscribe(
              (res) => {
                self._apiService
                  .SetIsActiveData(
                    this.apiPath,
                    res.data.resultData[0],
                    this.itemObj.isActive
                  )
                  .subscribe((r) => {
                    const uploadPromises = [];
                    for (const key in self.singleFileList) {
                      let fileData;
                      const value = self.singleFileList[key];
                      if (value) {
                        if (this.config[key].type === FormType.Video) {
                          const targetElement = this.videoUploadComponent.find(
                            (component: ElementRef) =>
                              component.nativeElement.id === key
                          );

                          if (this.singleFileList[key]) {
                            fileData = this.singleFileList[key].file;
                          } else {
                            fileData = targetElement.nativeElement.files[0];
                          }
                          if (fileData) {
                            uploadPromises.push(
                              this.uploadFile({
                                isEdit: self.isEditing,
                                idResId: self.itemObj.id.toString(),
                                tablename: self.tableName,
                                collectionName: key,
                                ordinal: 0,
                                fileData: fileData,
                              })
                            );
                          }
                        } else if (
                          this.config[key].type === FormType.SingleImage
                        ) {
                        } else {
                          fileData = value.file;
                          if (fileData) {
                            uploadPromises.push(
                              this.uploadFile({
                                isEdit: self.isEditing,
                                idResId: res.data.resultData[0],
                                tablename: self.tableName,
                                collectionName: key,
                                ordinal: 0,
                                fileData: fileData,
                              })
                            );
                          }
                        }
                      }
                    }

                    for (const key in self.multipleFilesList) {
                      if (self.multipleFilesList[key].length >= 1) {
                        const value = self.multipleFilesList[key];
                        uploadPromises.push(
                          self.updateFiles(
                            self.tableName,
                            self.itemObj.id.toString(),
                            key,
                            0,
                            value
                          )
                        );
                      }
                    }

                    this.imageUploadComponent.forEach(
                      (component: ImageUploadComponent) => {
                        const nativeElement: HTMLElement =
                          component.elementRef.nativeElement;
                        const elementID = nativeElement.id;
                        uploadPromises.push(
                          component.uploadImage({
                            isEdit: true,
                            idResId: res.data.resultData[0],
                            tablename: this.tableName,
                            collectionName: elementID,
                            ordinal: 0,
                          })
                        );
                      }
                    );

                    Promise.all(uploadPromises)
                      .then(() => {
                        self.loadingFile = false;
                        self._componentsService.SuccessSwal();
                        self._router.navigate([`${this.pathUrl}`]);
                      })
                      .catch((error) => {
                        self.loadingFile = false;
                        self._componentsService.ErrorSwal(error);
                        self._router.navigate([`${this.pathUrl}`]);
                      });
                  });
              },
              (err) => {
                self._componentsService.ErrorSwal();
              }
            );
        } else {
          self._apiService
            .UpdateWithOutId(this.apiPath, tempForm.value)
            .subscribe(
              (res) => {
                self._fileService;
                // .DeleteFile('content', res.data.resultData[0])
                // .subscribe((r) => {
                const uploadPromises = [];
                for (const key in self.singleFileList) {
                  let fileData;
                  const value = self.singleFileList[key];
                  if (value) {
                    if (this.config[key].type === FormType.Video) {
                      const targetElement = this.videoUploadComponent.find(
                        (component: ElementRef) =>
                          component.nativeElement.id === key
                      );

                      if (this.singleFileList[key]) {
                        fileData = this.singleFileList[key].file;
                      } else {
                        fileData = targetElement.nativeElement.files[0];
                      }
                      if (fileData) {
                        uploadPromises.push(
                          this.uploadFile({
                            isEdit: self.isEditing,
                            idResId: res.data.resultData[0],
                            tablename: self.tableName,
                            collectionName: key,
                            ordinal: 0,
                            fileData: fileData,
                          })
                        );
                      }
                    } else if (this.config[key].type === FormType.SingleImage) {
                    } else {
                      fileData = value.file;
                      if (fileData) {
                        uploadPromises.push(
                          this.uploadFile({
                            isEdit: self.isEditing,
                            idResId: res.data.resultData[0],
                            tablename: self.tableName,
                            collectionName: key,
                            ordinal: 0,
                            fileData: fileData,
                          })
                        );
                      }
                    }
                  }
                }

                for (const key in self.multipleFilesList) {
                  if (self.multipleFilesList[key].length >= 1) {
                    const value = self.multipleFilesList[key];
                    uploadPromises.push(
                      self.updateFiles(
                        self.tableName,
                        self.itemObj.id.toString(),
                        key,
                        0,
                        value
                      )
                    );
                  }
                }

                this.imageUploadComponent.forEach(
                  (component: ImageUploadComponent) => {
                    const nativeElement: HTMLElement =
                      component.elementRef.nativeElement;
                    const elementID = nativeElement.id;
                    uploadPromises.push(
                      component.uploadImage({
                        isEdit: true,
                        idResId: res.data.resultData[0],
                        tablename: this.tableName,
                        collectionName: elementID,
                        ordinal: 0,
                      })
                    );
                  }
                );

                Promise.all(uploadPromises)
                  .then(() => {
                    self.loadingFile = false;
                    self._componentsService.SuccessSwal();
                    self._router.navigate([`${this.pathUrl}`]);
                  })
                  .catch((error) => {
                    self.loadingFile = false;
                    self._componentsService.ErrorSwal(error);
                    self._router.navigate([`${this.pathUrl}`]);
                  });
                // }
                // );
              },
              (err) => {
                self._componentsService.ErrorSwal();
              }
            );
        }
      });
    } else {
      self._apiService.AddData(this.apiPath, tempForm.value).subscribe(
        (res) => {
          // self._componentsService.SuccessSwal();
          self._apiService
            .SetIsActiveData(
              this.apiPath,
              res.data.resultData[0],
              this.itemObj.isActive
            )
            .subscribe((res) => {
              const uploadPromises = [];
              for (const key in self.singleFileList) {
                let fileData;
                const value = self.singleFileList[key];
                if (value) {
                  if (this.config[key].type === FormType.Video) {
                    const targetElement = this.videoUploadComponent.find(
                      (component: ElementRef) =>
                        component.nativeElement.id === key
                    );

                    if (this.singleFileList[key]) {
                      fileData = this.singleFileList[key].file;
                    } else {
                      fileData = targetElement.nativeElement.files[0];
                    }
                  } else {
                    fileData = value.file;
                  }

                  console.log(value);

                  if (fileData) {
                    uploadPromises.push(
                      this.uploadFile({
                        isEdit: self.isEditing,
                        idResId: res.data.resultData[0],
                        tablename: self.tableName,
                        collectionName: key,
                        ordinal: 0,
                        fileData: fileData,
                      })
                    );
                  }
                }
              }

              for (const key in self.multipleFilesList) {
                if (self.multipleFilesList[key].length >= 1) {
                  const value = self.multipleFilesList[key];
                  uploadPromises.push(
                    self.updateFiles(
                      self.tableName,
                      res.data.resultData[0],
                      key,
                      0,
                      value
                    )
                  );
                }
              }

              this.imageUploadComponent.forEach(
                (component: ImageUploadComponent) => {
                  const nativeElement: HTMLElement =
                    component.elementRef.nativeElement;
                  const elementID = nativeElement.id;
                  uploadPromises.push(
                    component.uploadImage({
                      isEdit: true,
                      idResId: res.data.resultData[0],
                      tablename: this.tableName,
                      collectionName: elementID,
                      ordinal: 0,
                    })
                  );
                }
              );

              Promise.all(uploadPromises)
                .then(() => {
                  self.loadingFile = false;
                  self._componentsService.SuccessSwal();
                  self._router.navigate([`${this.pathUrl}`]);
                })
                .catch((error) => {
                  self.loadingFile = false;
                  self._componentsService.ErrorSwal(error);
                  self._router.navigate([`${this.pathUrl}`]);
                });
            });
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
    }
  }

  onSelectImage(event) {
    var self = this;
    var invalidFiles = false;
    for (let i = 0; i < event.addedFiles.length; i++) {
      if (event.addedFiles.length <= this.countMedia) {
        if (event.addedFiles.length + this.images.length > this.countMedia) {
          this.alertFiles = true;
        }
        const file = event.addedFiles[i];
        const reader = new FileReader();
        reader.onload = () => {
          const img = new Image();
          img.onload = () => {
            self.images.push(file);
          };
          img.src = URL.createObjectURL(file);
        };
        reader.readAsDataURL(file);
      } else {
        this.alertFiles = true;
      }
    }
    // this.files.push(...event.addedFiles);
  }

  onRemoveAtteched(event, arr) {
    arr.splice(arr.indexOf(event), 1);
    if ((arr.length + this.images.length) / 2 <= this.countMedia) {
      this.alertFiles = false;
    }
  }

  generatePreviewCover(file: File): Promise<SafeUrl> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const video = document.createElement('video');
        video.src = event.target.result;

        video.onloadedmetadata = () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          const context = canvas.getContext('2d');

          // Wait for the video to play for a short duration before capturing the frame
          video.onseeked = () => {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            // Get the base64-encoded data URL of the canvas image
            const previewCoverDataURL = canvas.toDataURL('image/png');

            // Set the preview cover URL
            const tempCoverURL =
              this.sanitizer.bypassSecurityTrustUrl(previewCoverDataURL);

            resolve(tempCoverURL);
          };

          video.currentTime = 1; // Set the time to seek in the video (adjust as needed)
        };
      };
      reader.readAsDataURL(file);
      console.log('YAHEEEEEEEEEEEE');
    });
  }

  async prepareVideo(event, key) {
    var self = this;
    if (event.target.files.length > 0) {
      const filename = event.target.files[0].name;
      const file = event.target.files[0];
      if (file && file.size <= this.videoFileSize) {
        let obj: { [k: string]: any } = {};
        obj.file = file;
        obj.coverImageURL = await self.generatePreviewCover(file);
        obj.filename = filename;
        self.singleFileList[key] = obj;
        self.handleList[key] = true;

        console.log(self.singleFileList);
      } else {
        console.error(
          `File size exceeds the maximum limit of ${this.bytesToSizes(
            this.videoFileSize
          )}.`
        );
        Swal.fire(
          'Error',
          `File size exceeds the maximum limit of ${this.bytesToSizes(
            this.videoFileSize
          )}.`,
          'error'
        );
      }
    }
  }

  prepareSingleFile(event, key) {
    var self = this;
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const filename = event.target.files[0].name;
      if (file && file.size <= this.maxFileSize) {
        let obj: { [k: string]: any } = {};
        obj.file = file;
        obj.coverImageURL = null;
        obj.filename = filename;
        self.singleFileList[key] = obj;
        console.log(self.singleFileList[key]);
      } else {
        console.error(
          `File size exceeds the maximum limit of ${this.bytesToSizes(
            this.videoFileSize
          )}.`
        );
        this._componentsService.ErrorSwal('Invalid File Size');
      }
    }
  }

  handleInputChanged(value: string, controlName: string) {
    this.handleList[controlName][0] = true;
    const element = this.el.nativeElement.querySelector(`#${controlName}`);
    if (element) {
      this.renderer.addClass(element, 'invalid_border');
    }
  }

  bytesToSizes(size: number): string {
    return this._globalFuncService.bytesToSize(size);
  }

  uploadFile(params: {
    isEdit: boolean;
    idResId: any;
    tablename: string;
    collectionName: string;
    ordinal: number;
    fileData: any;
  }) {
    // const { isUpdatefile_attr, idResId, tablename } = params;
    var self = this;
    const file = params.fileData;
    console.log(params.fileData);

    const formData = new FormData();
    formData.append('file', file);
    if (params.isEdit) {
      return new Promise<void>((resolve, reject) => {
        // Call your file service to upload the video
        this._fileService
          .UpdateFile(
            params.tablename,
            params.idResId,
            params.collectionName,
            params.ordinal,
            formData
          )
          .subscribe(
            () => {
              resolve(); // Resolve the promise to indicate the upload is complete
            },
            (error) => {
              console.error('Error uploading File:', error);
              reject(error); // Reject the promise if an error occurs
            }
          );
      });
    } else {
      return new Promise<void>((resolve, reject) => {
        // Call your file service to upload the video
        this._fileService
          .UpdateFile(
            params.tablename,
            params.idResId,
            params.collectionName,
            params.ordinal,
            formData
          )
          .subscribe(
            () => {
              console.log('File uploaded successfully!');
              resolve(); // Resolve the promise to indicate the upload is complete
            },
            (error) => {
              console.error('Error uploading File:', error);
              reject(error); // Reject the promise if an error occurs
            }
          );
      });
    }
  }

  onSelectAttachedFile(event, file?, fileId?) {
    var invalidFiles = false;
    if (event) {
      for (let i = 0; i < event.addedFiles.length; i++) {
        const file = event.addedFiles[i];
        const reader = new FileReader();
        reader.onload = () => {
          file.src = reader.result;
          if (this.multipleFilesList.length < 5) {
            this.multipleFilesList.push(file);
          } else {
            this._componentsService.ErrorSwal(
              'Error',
              'The maximum number of files is 5'
            );
          }
        };
        reader.onerror = (error) => {
          console.error('File reading error:', error);
        };

        reader.readAsDataURL(file);
      }
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        file.src = reader.result;
        if (this.multipleFilesList.length < 5) {
          this._fileService.GetFileDetail(fileId).subscribe((res) => {
            let fileName = res.data.resultData[0].name;
            let fileExtension = res.data.resultData[0].extension;
            let fullName = fileName + '.' + fileExtension;
            const convertedFile = new File([file], fullName);
            this.multipleFilesList.push(convertedFile);
          });
        } else {
          this._componentsService.ErrorSwal(
            'Error',
            'The maximum number of files is 5'
          );
        }
      };
      reader.onerror = (error) => {
        console.error('File reading error:', error);
      };

      reader.readAsDataURL(file);
    }
  }

  uploadFiles(
    tablename: string,
    contentId: string,
    collectionName: string,
    ordinal: number,
    fileList: any
  ) {
    let fileArr = new FormData();
    fileList.forEach((file: Blob) => {
      fileArr.append('files', file);
    });
    return new Promise<void>((resolve, reject) => {
      // Call your file service to upload the video
      this._fileService
        .UpdateFile(tablename, contentId, collectionName, ordinal, fileArr)
        .subscribe(
          () => {
            console.log('File uploaded successfully!');
            resolve(); // Resolve the promise to indicate the upload is complete
          },
          (error) => {
            console.error('Error uploading File:', error);
            reject(error); // Reject the promise if an error occurs
          }
        );
    });
  }

  updateFiles(
    tablename: string,
    contentId: string,
    collectionName: string,
    ordinal: number,
    fileList: any
  ) {
    console.log(fileList);

    let fileArr = new FormData();
    fileList.forEach((file: Blob) => {
      fileArr.append('files', file);
    });

    return new Promise<void>((resolve, reject) => {
      // Call your file service to upload the video
      this._fileService
        .UpdateFile(tablename, contentId, collectionName, ordinal, fileArr)
        .subscribe(
          () => {
            console.log('Edit File successfully!');
            resolve(); // Resolve the promise to indicate the upload is complete
          },
          (error) => {
            console.error('Error Edit File:', error);
            reject(error); // Reject the promise if an error occurs
          }
        );
    });
  }

  onRemoveAttechedFile(event, arr) {
    arr.splice(arr.indexOf(event), 1);
    if (arr.length > this.multipleFilesList.length) {
      this.alertFiles = false;
    }
  }
}
