import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { ModalComponent } from './modal/modal.component';
import { BehaviorSubject } from 'rxjs';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ComponentsService {
  @BlockUI() loadingScreen: NgBlockUI;
  contentListSubject = new BehaviorSubject([]);
  currentContentList = this.contentListSubject.asObservable();

  constructor(
    private _modalService: NgbModal,
    private _translateService: TranslateService,
    private _router: Router
  ) {}

  setContentList(contentList: any[]): void {
    this.contentListSubject.next(contentList);
  }

  clearContentList(): void {
    this.contentListSubject.next([]);
  }

  // SuccessSwal(title: string = 'Success'): void {
  //   // Start blocking the UI

  //   // Optional: You can customize the message shown while blocking the UI
  //   this.loadingScreen.start();

  //   // Call the existing success method
  //   this.success(title).then(() => {
  //     // Stop blocking the UI when the success method is finished
  //     this.loadingScreen.stop();
  //     console.log('STOPPPPPP !!!!!');
  //   });
  // }


  SuccessSwal(
    title: string = this._translateService.instant('Alert.Success'),
    confirmButtonText: string = this._translateService.instant('Alert.Confirm'),
    showLinkDocButton?: boolean,
    linkDocButtonText?: string,
    childDocPath?: string,
    text?: string,
    childId?: string,
    callback?: () => void
  ): Promise<SweetAlertResult> {
    return new Promise((resolve) => {
      this.loadingScreen.stop();

      console.log('childDocPath : ', childDocPath);
      
      Swal.fire({
        title: title,
        icon: 'success',
        confirmButtonText: confirmButtonText,
        showCancelButton: showLinkDocButton,
        cancelButtonText: linkDocButtonText,
        text: text,
        customClass: {
          actions: 'my-actions',
          confirmButton: 'order-2',
          cancelButton: 'btn-warning right-gap order-1'
        },
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.cancel) {
          this._router.navigate([childDocPath + '/' + childId]); /* รอ + id */
        }
        if (callback && result.isConfirmed) {
          callback(); // Execute the callback function when the confirm button is pressed
        }
        resolve(result);
      });
    });
  }

  ErrorSwal(
    title: string = this._translateService.instant('Alert.Error'),
    text: string = this._translateService.instant('Alert.SomethingWentWrong'),
    confirmButtonText: string = this._translateService.instant('Alert.Confirm')
  ): void {
    this.loadingScreen.stop();

    Swal.fire({
      title: title,
      text: text,
      icon: 'error',
      confirmButtonText: confirmButtonText,
    });
  }
}
