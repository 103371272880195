import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class GlobalFuncService {
  constructor(private currencyPipe: CurrencyPipe) {}

  MoneyFormatToNumber(value: string): number {
    let result = 0;
    result = parseFloat(value.toString().replace(/,/g, ''));

    if (isNaN(result)) {
      return 0;
    }

    return result;
  }

  FormatToMoney(
    value: number,
    showMaxFourDecimals: boolean = false,
    disableDecimals: boolean = false
  ): string {
    // Check if the value is a number
    if (isNaN(value)) {
      return '';
    }

    // Set the minimum and maximum decimal digits
    const minDecimalDigits = disableDecimals ? 0 : 2;
    const maxDecimalDigits = disableDecimals ? 0 : showMaxFourDecimals ? 4 : 2;

    // Format the number as currency with the desired decimal digits
    return value.toLocaleString(undefined, {
      minimumFractionDigits: minDecimalDigits,
      maximumFractionDigits: maxDecimalDigits,
    });
  }

  GetCurrentStep(value: any): number {
    if (!value || value.length === 0) {
      return 0;
    }

    const orderStatus = value[0];
    if (orderStatus.isCancel || orderStatus.isVoided) {
      return 5; // Cancel
    } else if (orderStatus.isCompleted || orderStatus.isAllRTCreated) {
      return 4; // Complete
    } else if (orderStatus.isAllIVPaid) {
      return 3; // Payment
    } else if (orderStatus.isAllIVCreated || orderStatus.isCreatedIV) {
      return 2; // Wait Payment
    } else if (
      (orderStatus.isCreatedQO && orderStatus.isCreatedSO) ||
      (orderStatus.isCreatedPR && orderStatus.isCreatedPO)
    ) {
      return 1; // Accept
    } else {
      return 0; // Draft
    }
  }

  GetAddressTemplate(customerAddressObj): string {
    let result = '';

    if (customerAddressObj) {
      var provinceId = parseInt(customerAddressObj.provinceId);

      let address = customerAddressObj.addressNumber
        ? customerAddressObj.addressNumber
        : '-';
      let subDistrict = customerAddressObj.subDistrictName
        ? (provinceId == 10 ? 'แขวง' : 'ตำบล') +
          customerAddressObj.subDistrictName
        : '-';
      let district = customerAddressObj.districtName
        ? (provinceId == 10 ? 'เขต' : 'อำเภอ') + customerAddressObj.districtName
        : '-';

      let provinceName = 'จังหวัด' + customerAddressObj.provinceName;

      let postCode = customerAddressObj.postCode;

      result = `${address} ${subDistrict} ${district} ${provinceName} ${postCode}`;
      return customerAddressObj ? result : '-';
    } else {
      return customerAddressObj ? result : '-';
    }
  }

  getPropertyByPath(obj: any, path: string): any {
    const properties = path.split('.');
    let value = obj;

    for (const prop of properties) {
      value = value[prop];
      if (value === undefined) {
        return undefined;
      }
    }

    return value;
  }

  bytesToSize(bytes: number): string {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
  }

  scrollToFirstInvalidControl(formID: string, htmlEL?: Element) {
    let form = document.getElementById(formID); // <-- your formID
    let firstInvalidControl: Element;

    if (!htmlEL) {
      firstInvalidControl = form.getElementsByClassName('ng-invalid')[0];
    } else {
      firstInvalidControl = htmlEL;
    }

    let elementRect = firstInvalidControl.getBoundingClientRect();
    let absoluteElementTop = elementRect.top + window.pageYOffset;
    let middleOfElement = absoluteElementTop - window.innerHeight / 2;
    window.scrollTo({ top: middleOfElement, behavior: 'smooth' });
    (firstInvalidControl as HTMLElement).focus();
  }

  formatDate(isoDate: string, format: string = 'D MMMM YYYY'): string {
    return moment(isoDate).format(format); // Customize the format as needed
  }

  checkFirstLetterisInvalid(text: string): boolean {
    let firstLetter = Array.from(text)[0];
    const specialCharRegex = /^[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\| ]+/;
    return specialCharRegex.test(firstLetter);
  }
}
