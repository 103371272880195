import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OurClientPartnerComponent } from './our-client-partner/our-client-partner.component';
import { OurClientPerformanceComponent } from './our-client-performance/our-client-performance.component';
import { OurClientComponent } from './our-client.component';
import { OurClientPerformanceFormpageComponent } from './our-client-performance/our-client-performance-formpage/our-client-performance-formpage.component';
import { TranslateModule } from '@ngx-translate/core';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSummernoteModule } from 'ngx-summernote';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { ComponentsModule } from 'app/main/components/components.module';
import { RouterModule, Routes } from '@angular/router';
import { OurClientPartnerFormpageComponent } from './our-client-partner/our-client-partner-formpage/our-client-partner-formpage.component';
import { AuthGuard } from 'app/auth/helpers';

const routes: Routes = [
  {
    path: 'cms/our-client',
    component: OurClientComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-our-client' },
  },

  {
    path: 'cms/our-client-partner',
    component: OurClientPartnerComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-our-client-partner' },
  },
  {
    path: 'cms/our-client-partner/create',
    component: OurClientPartnerFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-our-client-partner-create' },
  },
  {
    path: 'cms/our-client-partner/edit/:id',
    component: OurClientPartnerFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-our-client-partner-edit' },
  },

  {
    path: 'cms/our-client-performance',
    component: OurClientPerformanceComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-our-client-performance' },
  },
  {
    path: 'cms/our-client-performance/create',
    component: OurClientPerformanceFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-our-client-performance-create' },
  },
  {
    path: 'cms/our-client-performance/edit/:id',
    component: OurClientPerformanceFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-our-client-performance-edit' },
  },
];

@NgModule({
  declarations: [
    OurClientPartnerComponent,
    OurClientPartnerFormpageComponent,
    OurClientPerformanceComponent,
    OurClientPerformanceFormpageComponent,
    OurClientComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ContentHeaderModule,
    FormsModule,
    NgxSummernoteModule,
    ReactiveFormsModule,
    CorePipesModule,
    ComponentsModule,
    RouterModule.forChild(routes),
  ],
})
export class OurClientModule {}
