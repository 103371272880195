import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsModule } from './settings/settings.module';
import { UserGuideComponent } from './user-guide/user-guide.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { UserGuideCmsComponent } from './user-guide-cms/user-guide-cms.component';


@NgModule({
  declarations: [
    UserGuideComponent,
    UserGuideCmsComponent
  ],
  imports: [
    CommonModule,
    SettingsModule,
    PdfViewerModule,
    ContentHeaderModule
  ]
})
export class GeneralModule { }
