<form class="itemset-list item_set_form" [formGroup]="formGroup">
  <div class="form-group row">
    <label class="col-sm-3 col-form-label"
      >{{ "Form.Name" | translate }}<span class="text-danger">*</span></label
    >
    <div class="col-sm-9">
      <input
        class="form-control"
        type="text"
        formControlName="name"
        (blur)="checkFirstLetterInvalid($event.target.value, $any(f.name))"
        [ngClass]="{
          'is-invalid error':
            (isSubmit && f.name?.invalid) ||
            (f.name?.invalid && f.name?.dirty) ||
            (f.name?.touched && f.name?.invalid)
        }"
      />
      <div
        class="invalid-feedback"
        [ngClass]="{
          'd-block': f.name.invalid && (f.name.dirty || f.name.touched)
        }"
      >
        <div>
          <div *ngIf="f.name.errors?.required">
            {{ "Handle.Name" | translate }}
          </div>
          <div *ngIf="f.name.errors?.maxlength">
            {{ "Handle.Exceed" | translate }}
            100
            {{ "Handle.Char" | translate }}
          </div>
          <div
            *ngIf="checkFirstLetterInvalid($any(f.name.value), $any(f.name))"
          >
            {{ "Handle.FirstLetterInvalid" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label for="colFormLabelLg" class="col-sm-3 col-form-label"
      >{{ "Form.Cover" | translate }}<span class="text-danger">*</span></label
    >
    <div class="col-sm-9">
      <app-image-upload #imageUploadComponent></app-image-upload>
    </div>
  </div>

  <hr />

  <h4 class="mb-2">{{ "ContentDocument.ItemForItemSet" | translate }}</h4>

  <table class="table">
    <thead>
      <tr>
        <th>#</th>
        <th class="text-center" style="width: 30%;">{{ "ContentDocument.Item" | translate }}</th>
        <th>{{ "ContentDocument.PriceQ" | translate }}</th>
        <th>{{ "ContentDocument.Remain" | translate }}</th>
        <th>{{ "ContentDocument.Quantity" | translate }}</th>
        <th>{{ "ContentDocument.Amount" | translate }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr
        #itemSetList
        *ngFor="let selectedItem of selectedItemList; index as ind"
      >
        <td>{{ ind + 1 }}</td>
        <td style="width: 30%;">
          <ng-select
            [id]="'selectedItem' + (ind + 1)"
            class="custom-select"
            (click)="filterItemList()"
            (change)="onSelectedItem(ind + 1, $event)"
            placeholder="-- {{ 'DocTable.Select' | translate }} --"
            [loading]="isLoadingItem"
            [clearable]="false"
            [(ngModel)]="selectedItem.ingredientItemId"
            [ngModelOptions]="{ standalone: true }"
            [ngClass]="{
              'is-invalid error': isSubmit && invalidSelectedItem[ind]
            }"
          >
            <ng-option
              [value]="item.id"
              *ngFor="let item of itemList"
              [selected]="selectedItem.ingredientItemId == item.id"
            >
              {{ item.itemCode }} - {{ item.name }}
            </ng-option>
          </ng-select>
        </td>

        <td>
          <div [id]="'selectedItemPrice' + (ind + 1)">
            {{ convertNumbertoMoneyFormat(selectedItem.unitPrice) }}
          </div>
        </td>
        <td>
          <div [id]="'selectedRemain' + (ind + 1)">
            {{
              selectedItem.remain
                ? convertNumbertoMoneyFormat(selectedItem.remain)
                : "0"
            }}
          </div>
        </td>
        <td>
          <input
            [id]="'selectedQuantity' + (ind + 1)"
            class="form-control"
            type="text"
            pattern="[0-9]*\.?[0-9]+"
            (focus)="removeComma($event)"
            (blur)="formatMoney('selectedQuantity' + (ind + 1),false,!isQuantityDecimal)"
            (keyup)="CalculateRowSummaryPrice(ind + 1)"
            (keydown)="CalculateRowSummaryPrice(ind + 1)"
            (input)="validateInput($event, selectedItem.comments, ind + 1)"
            value="1.00"
          />
          <!-- (input) แปลกๆ ทำไมใช้ comments ? -->
        </td>
        <td>
          <div [id]="'selectedItemTotalPrice' + (ind + 1)">
            {{
              convertNumbertoMoneyFormat(
                selectedItem.unitPrice * selectedItem.quantity
              )
            }}
          </div>
        </td>
        <td class="text-center" style="width: 10px">
          <a
            *ngIf="selectedItemList.length > 1"
            class="remove-button"
            (click)="removeRow(ind)"
          >
            <i data-feather="x"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>

  <div>
    <div class="mt-1 d-flex justify-content-between">
      <a
        class="btn btn-outline-primary mr-1 text-primary"
        (click)="addItemRow()"
      >
        <i data-feather="plus"></i>{{ "DocTable.Button.AddItem" | translate }}
      </a>
      <div
        *ngIf="invalidSelectedItem.includes(true) && isSubmit"
        class="text-danger font"
      >{{ "ErrorMessage.PleaseSelectItem" | translate }}</div>
    </div>
  </div>

  <hr />
  <h4>{{ "ContentDocument.ItemSetValue" | translate }}</h4>

  <div class="d-block">
    <div class="d-flex justify-content-end subtotal-row mb-1">
      <p class="my-auto">
        {{ "ContentDocument.TotalProductPrice" | translate }}
      </p>
      <p class="ml-1 my-auto" id="summaryPrice">0.00</p>
      <p class="ml-2 my-auto">{{ "DocFooter.Baht" | translate }}</p>
    </div>

    <div class="d-flex justify-content-end subtotal-row">
      <p class="my-auto">{{ "ContentDocument.ValueForItemSet" | translate }}</p>
      <p class="ml-1 my-auto">
        <input
          type="text"
          class="input-number form-control"
          style="text-align: end"
          (focus)="removeComma($event)"
          (blur)="formatMoney('sellingPrice')"
          id="sellingPrice"
          formControlName="sellingPrice"
        />
      </p>
      <p class="ml-2 my-auto">{{ "DocFooter.Baht" | translate }}</p>
    </div>
  </div>

  <hr />

  <div class="form-group row">
    <label for="colFormLabelLg" class="col-sm-3 col-form-label">{{
      "Form.Status" | translate
    }}</label>
    <div class="col-sm-9">
      <div
        class="custom-control custom-switch custom-control-inline col-form-label"
      >
        <input
          type="checkbox"
          class="custom-control-input"
          id="customSwitch1"
          formControlName="isActive"
          checked
        />
        <label class="custom-control-label" for="customSwitch1"></label>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label for="colFormLabelLg" class="col-sm-3 col-form-label">{{
      "Form.IsNewProduct" | translate
    }}</label>
    <div class="col-sm-9">
      <div
        class="custom-control custom-switch custom-control-inline col-form-label"
      >
        <input
          type="checkbox"
          class="custom-control-input"
          id="isNewProduct"
          formControlName="isNewProduct"
        />
        <label class="custom-control-label" for="isNewProduct"></label>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label for="colFormLabelLg" class="col-sm-3 col-form-label">{{
      "Form.IsBestSeller" | translate
    }}</label>
    <div class="col-sm-9">
      <div
        class="custom-control custom-switch custom-control-inline col-form-label"
      >
        <input
          type="checkbox"
          class="custom-control-input"
          id="isBestSeller"
          formControlName="isBestSeller"
        />
        <label class="custom-control-label" for="isBestSeller"></label>
      </div>
    </div>
  </div>

  <!-- <div class="form-group row">
    <label class="col-sm-3 col-form-label">{{
      "Form.Barcode" | translate
    }}</label>
    <div class="col-sm-9">
      <input
        class="form-control"
        type="text"
        formControlName="itemBarcode"
        [(ngModel)]="itemBarcode"
        [ngClass]="{
          'is-invalid error':
            f.itemBarcode?.invalid &&
            (f.itemBarcode?.dirty || f.itemBarcode?.touched)
        }"
      />
      <app-barcode-generator
        style="width: 100%"
        [value]="itemBarcode"
      ></app-barcode-generator>
    </div>
  </div> -->

  <div formGroupName="description">
    <div *ngFor="let lang of langList">
      <div class="form-group row" [class.d-none]="selectedLang != lang">
        <div class="col-sm-3 col-form-label">
          <label for="colFormLabelLg">
            {{ "Form.Description" | translate }}
            <span class="text-danger">*</span>
          </label>
          <br />
          <div *ngIf="langList.length > 1" class="badge badge-primary">
            {{ lang | uppercase }}
          </div>
        </div>
        <div class="col-sm-9">
          <textarea
            class="form-control"
            [formControlName]="lang"
            rows="5"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.description?.invalid) ||
                (f.description?.invalid &&
                  (f.description?.dirty || f.description?.touched))
            }"
            [placeholder]="'Form.EnterDescription' | translate"
          ></textarea>
          <div class="invalid-feedback">
            <div
              *ngIf="
                isSubmit ||
                (f.description.invalid &&
                  (f.description.dirty || f.description.touched))
              "
            >
              {{ "Handle.DescriptionAllLanguage" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div formGroupName="detail">
    <div *ngFor="let lang of langList">
      <div class="form-group row" [class.d-none]="selectedLang != lang">
        <div class="col-sm-3 col-form-label">
          <label for="colFormLabelLg">
            {{ "Form.Detail" | translate }} <span class="text-danger">*</span>
          </label>
          <br />
          <div *ngIf="langList.length > 1" class="badge badge-primary">
            {{ lang | uppercase }}
          </div>
        </div>
        <div class="col-sm-9">
          <div
            [ngClass]="{
              'invalid-border':
                (isSubmit && f.detail?.invalid) ||
                (f.detail?.invalid && (f.detail?.dirty || f.detail?.touched))
            }"
          >
            <textarea
              class="form-control"
              [ngxSummernote]="summernoteConfig"
              [formControlName]="lang"
            ></textarea>
          </div>
          <div
            class="invalid-feedback"
            [ngClass]="{
              'd-block':
                (isSubmit && f.detail?.invalid) ||
                (f.detail?.invalid && (f.detail?.dirty || f.detail?.touched))
            }"
          >
            <div
              *ngIf="
                isSubmit ||
                (f.detail.invalid && (f.detail.dirty || f.detail.touched))
              "
            >
              {{ "Handle.DetailAllLanguage" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label"
      >{{ "General.Brand" | translate }}
      <span class="text-danger">*</span></label
    >
    <div class="col-sm-9">
      <div>
        <ng-select
          *ngIf="!isLoadingBrand"
          class="custom-select"
          [(ngModel)]="brandId"
          formControlName="brandId"
          placeholder="{{ 'General.All' | translate }}"
          [clearable]="false"
          [loading]="isLoadingGroup"
          #selectGroup
          [ngClass]="{
            'is-invalid error':
              (isSubmit && f.brandId?.value == null) ||
              (isSubmit && f.brandId?.invalid) ||
              (f.brandId?.invalid &&
                (f.brandId?.dirty || f.brandId?.touched)) ||
              (f.brandId?.value == null &&
                (f.brandId?.dirty || f.brandId?.touched))
          }"
        >
          <!-- <option value="All">{{ "General.All" | translate }}</option> -->
          <ng-option
            [value]="brand.id"
            *ngFor="let brand of BrandObjList"
            [selected]="brand.id == brandId"
          >
            {{ brand.name }}
          </ng-option>
        </ng-select>
        <div>
          <label for="helpInputTop">{{ "General.Brand" | translate }}</label>
          <small
            style="color: rgb(180, 180, 180)"
            [ngClass]="{
              'text-danger':
                (isSubmit && f.brandId?.value == null) ||
                (isSubmit && f.brandId?.invalid) ||
                (f.brandId?.invalid &&
                  (f.brandId?.dirty || f.brandId?.touched)) ||
                (f.brandId?.value == null &&
                  (f.brandId?.dirty || f.brandId?.touched))
            }"
          >
            <i>{{ "iTem.Validate" | translate }}</i></small
          >
        </div>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-sm-3 col-form-label"
      >{{ "General.Group" | translate
      }}<span class="text-danger">*</span></label
    >
    <div class="col-sm-9">
      <div>
        <ng-select
          class="custom-select"
          [(ngModel)]="groupId"
          formControlName="groupId"
          [clearable]="false"
          placeholder="{{ 'General.All' | translate }}"
          (change)="getCategoryData($event)"
          #selectGroup
          [loading]="isLoadingGroup"
          [ngClass]="{
            'is-invalid error':
              (isSubmit && f.groupId?.value == null) ||
              (isSubmit && f.groupId?.invalid) ||
              (f.groupId?.invalid &&
                (f.groupId?.dirty || f.groupId?.touched)) ||
              (f.groupId?.value == null &&
                (f.groupId?.dirty || f.groupId?.touched))
          }"
        >
          <ng-option
            [value]="group.id"
            *ngFor="let group of GroupObjList"
            [selected]="group.id == groupId"
          >
            {{ group.name }}
          </ng-option>
        </ng-select>
        <div>
          <label for="helpInputTop">{{ "iTem.Group" | translate }}</label>
          <small
            style="color: rgb(180, 180, 180)"
            [ngClass]="{
              'text-danger':
                (isSubmit && f.groupId?.value == null) ||
                (isSubmit && f.groupId?.invalid) ||
                (f.groupId?.invalid &&
                  (f.groupId?.dirty || f.groupId?.touched)) ||
                (f.groupId?.value == null &&
                  (f.groupId?.dirty || f.groupId?.touched))
            }"
          >
            <i>{{ "iTem.Validate" | translate }}</i></small
          >
        </div>
      </div>
    </div>
  </div>
  <div
    class="invalid-feedback"
    [ngClass]="{
      'd-block': groupId
    }"
  >
    <div class="form-group row">
      <label class="col-sm-3 col-form-label"
        >{{ "General.Category" | translate
        }}<span class="text-danger">*</span></label
      >
      <div class="col-sm-9">
        <div>
          <ng-select
            class="custom-select"
            [(ngModel)]="categoryId"
            formControlName="categoryId"
            [disabled]="!groupId"
            [clearable]="false"
            placeholder="{{ 'General.All' | translate }}"
            (change)="getSubCategoryData($event)"
            [loading]="isLoadingCategory"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.categoryId?.value == null) ||
                (isSubmit && f.categoryId?.invalid) ||
                (f.categoryId?.invalid &&
                  (f.categoryId?.dirty || f.categoryId?.touched)) ||
                (f.categoryId?.value == null &&
                  (f.categoryId?.dirty || f.categoryId?.touched))
            }"
            #selectCategory
          >
            <ng-option
              [value]="category.id"
              *ngFor="let category of categoryList"
              [selected]="category.id == categoryId"
            >
              {{ category.name }}
            </ng-option>
          </ng-select>
          <div>
            <label for="helpInputTop">{{ "iTem.Cate" | translate }}</label>
            <small
              style="color: rgb(180, 180, 180)"
              [ngClass]="{
                'text-danger':
                  (isSubmit && f.categoryId?.value == null) ||
                  (isSubmit && f.categoryId?.invalid) ||
                  (f.categoryId?.invalid &&
                    (f.categoryId?.dirty || f.categoryId?.touched)) ||
                  (f.categoryId?.value == null &&
                    (f.categoryId?.dirty || f.categoryId?.touched))
              }"
            >
              <i>{{ "iTem.Validate" | translate }}</i></small
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="invalid-feedback"
    [ngClass]="{
      'd-block': categoryId
    }"
  >
    <div class="form-group row">
      <label class="col-sm-3 col-form-label"
        >{{ "General.SubCategory" | translate
        }}<span class="text-danger">*</span></label
      >
      <div class="col-sm-9">
        <div>
          <ng-select
            class="custom-select"
            [(ngModel)]="subCategoryId"
            formControlName="subCategoryId"
            [clearable]="false"
            placeholder="{{ 'General.All' | translate }}"
            (change)="getClassData($event)"
            [loading]="isLoadingSubCategory"
            [ngClass]="{
              'is-invalid error':
                (isSubmit && f.subCategoryId?.value == null) ||
                (isSubmit && f.subCategoryId?.invalid) ||
                (f.subCategoryId?.invalid &&
                  (f.subCategoryId?.dirty || f.subCategoryId?.touched)) ||
                (f.subCategoryId?.value == null &&
                  (f.subCategoryId?.dirty || f.subCategoryId?.touched))
            }"
            #selectSubCategory
          >
            <ng-option
              [value]="subCategory.id"
              *ngFor="let subCategory of subCategoryList"
              [selected]="subCategory.id == subCategoryId"
            >
              {{ subCategory.name }}
            </ng-option>
          </ng-select>
          <div>
            <label for="helpInputTop">{{ "iTem.subCate" | translate }}</label>
            <small
              style="color: rgb(180, 180, 180)"
              [ngClass]="{
                'text-danger':
                  (isSubmit && f.subCategoryId?.value == null) ||
                  (isSubmit && f.subCategoryId?.invalid) ||
                  (f.subCategoryId?.invalid &&
                    (f.subCategoryId?.dirty || f.subCategoryId?.touched)) ||
                  (f.subCategoryId?.value == null &&
                    (f.subCategoryId?.dirty || f.subCategoryId?.touched))
              }"
            >
              <i>{{ "iTem.Validate" | translate }}</i></small
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="invalid-feedback"
    [ngClass]="{
      'd-block': subCategoryId
    }"
  >
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">{{
        "General.Class" | translate
      }}</label>
      <div class="col-sm-9">
        <div class="input-group">
          <ng-select
            class="custom-select"
            [(ngModel)]="classId"
            formControlName="classId"
            (change)="getSubClassData($event)"
            [clearable]="false"
            placeholder="{{ 'General.All' | translate }}"
            [loading]="isLoadingClass"
            #selectClass
          >
            <ng-option
              [value]="class.id"
              *ngFor="let class of classList"
              [selected]="class.id == classId"
            >
              {{ class.name }}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </div>
  </div>

  <div
    class="invalid-feedback"
    [ngClass]="{
      'd-block': classId
    }"
  >
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">{{
        "General.SubClass" | translate
      }}</label>
      <div class="col-sm-9">
        <div class="input-group">
          <ng-select
            class="custom-select"
            [(ngModel)]="subClassId"
            [clearable]="false"
            placeholder="{{ 'General.All' | translate }}"
            formControlName="subClassId"
            [loading]="isLoadingSubClass"
            #selectSubClass
          >
            <ng-option
              [value]="subClass.id"
              *ngFor="let subClass of subClassList"
              [selected]="subClass.id == subClassId"
            >
              {{ subClass.name }}
            </ng-option>
          </ng-select>
        </div>
      </div>
    </div>
  </div>

  <hr />

  <div class="form-group row">
    <label for="colFormLabelLg" class="col-sm-3 col-form-label"
      >{{ "Form.Images" | translate }} <span class="text-danger">*</span></label
    >
    <div class="col-sm-9">
      <!-- typeFiles  0 รับทุกไฟล์ 1 รับเฉพาะรูป 2 รับไฟล์ด็อค 3 ไฟล์บีบอัด 4 ไปกำหนดเอง 55555 -->
      <app-custom-dropzone
        #CustomDropzone
        [typeFiles]="FileTypeFilter.Images"
        [countMedia]="7"
        [media]="passData || []"
        (filesUploaded)="onFilesUploaded($event)"
      ></app-custom-dropzone>
    </div>
  </div>

  <hr />

  <div class="auto-form-footer">
    <a (click)="openCancelModal()" class="btn btn-secondary">Cancel</a>
    <a (click)="openConfirmModal()" class="btn btn-primary">Submit</a>
  </div>
</form>
