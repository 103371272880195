import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OurClientService {
  ourClientPartner = {
    componentName: 'Our Client Partner',
    apiPath: 'OurClientPartner',
    pathUrl: '/cms/our-client-partner',
    keyName: 'name',
  };

  ourClientPerformance = {
    componentName: 'Our Client Performance',
    apiPath: 'OurClientPerformance',
    pathUrl: '/cms/our-client-performance',
    keyName: 'name',
  };

  constructor() { }
}
