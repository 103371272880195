import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormTemplateComponent } from 'app/main/components/form/form-template/form-template.component';
import { CmsFormConfig } from 'app/main/config/CMSFormConfig';
import { ICMSConfig } from 'app/main/config/CMSInterface';
import { ApiService } from 'app/main/service/api.service';
import { Subject, Subscription } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { HomeService } from '../home.service';
import { environment } from 'environments/environment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImageUploadComponent } from 'app/main/components/image-upload/image-upload.component';
import { FilesApiService } from 'app/main/service/files-api.service';
import { ModalComponent } from 'app/main/components/modal/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsService } from 'app/main/components/components.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-home-banner-formpage',
  templateUrl: './home-banner-formpage.component.html',
  styleUrls: ['./home-banner-formpage.component.scss'],
})
export class HomeBannerFormpageComponent implements OnInit {
  private _unsubscribeAll: Subject<any> = new Subject();
  private dataSubscription: Subscription;

  contentHeader: object;
  isLoading: boolean;

  apiPath: string;
  pathUrl: string;
  itemID: string;

  itemObj: any;
  config: ICMSConfig;

  selectedLang: string = environment.langDefault;
  langList = environment.langContent;

  videoFileSize: number = environment.limitFileSize.maxVideoSizeInBytes;

  singleFileList: any[] = [];
  handleList: any[] = [];
  mediaData: any[] = [];

  loadingFile: boolean;
  isSubmit: boolean;
  isEditing: boolean = false;
  invalidAlert: boolean = false;

  tableName: string = 'Content';
  videoWasChanged: boolean = false;

  @BlockUI() blockUI: NgBlockUI;

  @ViewChild('formTemplate') formTemplate: FormTemplateComponent;
  @ViewChild('imageUploadComponent') imageUploadComponent: ImageUploadComponent;
  @ViewChild('mobileImageUploadComponent')
  mobileImageUploadComponent: ImageUploadComponent;

  @ViewChild('videoUploadComponent')
  videoUploadComponent: ElementRef;

  @ViewChild('mobileVideoUploadComponent')
  mobileVideoUploadComponent: ElementRef;

  formGroup: FormGroup;

  constructor(
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _apiService: ApiService,
    private _cmsFormConfig: CmsFormConfig,
    private _homeService: HomeService,
    private sanitizer: DomSanitizer,
    private _globalFuncService: GlobalFuncService,
    private renderer: Renderer2,
    private el: ElementRef,
    private _formBuilder: FormBuilder,
    private _fileService: FilesApiService,
    private _modalService: NgbModal,
    private _router: Router,
    private _componentsService: ComponentsService
  ) {
    this.apiPath = this._homeService.homeBanner.apiPath;
    this.pathUrl = this._homeService.homeBanner.pathUrl;
    this.itemID = this._route.snapshot.paramMap.get('id');
    if (this.itemID) {
      this.isEditing = true;
    }

    this.config = this._cmsFormConfig.config[this.apiPath];
    this.itemObj = this._cmsFormConfig.config[this.apiPath].model;

    this.formGroup = this._formBuilder.group({
      name: ['', [Validators.required]],
      isUseVDO: [false],
      isActive: [true],
    });

    this.singleFileList['bannerVDO'] = {
      file: null,
      filename: null,
      coverImageURL: null,
    };

    this.singleFileList['bannerVDOMobile'] = {
      file: null,
      filename: null,
      coverImageURL: null,
    };

    const langTitleControls: any = {};
    this.langList.forEach((lang) => {
      for (const lang of this.langList) {
        langTitleControls[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);
      }
      this.formGroup.addControl(
        'title',
        this._formBuilder.group(langTitleControls)
      );
    });

    if (this.itemID) {
      this.GetItemData(this.apiPath, this.itemID);
    }

    console.log(this.formGroup);
  }

  get f() {
    return this.formGroup.controls;
  }

  SetLoadingState(): void {
    this.isLoading = true;
  }

  SetLoadedState(): void {
    this.isLoading = false;
  }

  ngOnInit(): void {
    this.contentHeader = {
      headerTitle: 'BreadCrumbs.CMS.HomeBanner',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
    if (this.dataSubscription) {
      this.dataSubscription.unsubscribe();
    }
    this.blockUI.stop();
  }

  GetItemData(apiPath, itemID): void {
    var self = this;

    this.dataSubscription = this._apiService
      .GetDataById(apiPath, itemID)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        self.itemObj = res.data.resultData[0];
        self.setFormValue(self.itemObj);
        console.log(self.itemObj);
      });
  }

  setFormValue(itemObj) {
    this.formGroup.patchValue({
      name: itemObj.name,
      isUseVDO: itemObj.isUseVDO,
      isActive: itemObj.isActive,
    });

    let tempTitleObj = JSON.parse(itemObj.title);
    this.langList.forEach((lang) => {
      this.formGroup.get(`title.${lang}`).patchValue(tempTitleObj[lang]);
    });

    this.mediaData = itemObj.mediaFiles;

    if (itemObj.mediaFiles['bannerIMG']) {
      this.imageUploadComponent.startLoading();

      const Image = itemObj.mediaFiles['bannerIMG'][0].id;

      this._fileService.GetFile(Image).subscribe((res) => {
        this.imageUploadComponent.setCoverImage(res, Image);
      });
    }

    if (itemObj.mediaFiles['bannerIMGMobile']) {
      this.imageUploadComponent.startLoading();

      const Image = itemObj.mediaFiles['bannerIMGMobile'][0].id;

      this._fileService.GetFile(Image).subscribe((res) => {
        this.mobileImageUploadComponent.setCoverImage(res, Image);
      });
    }

    if (itemObj.mediaFiles['bannerVDO']) {
      const video = itemObj.mediaFiles['bannerVDO'][0].id;
      this._fileService.GetFile(video).subscribe(async (res) => {
        const file = new File([res], video, {
          type: res.type,
        });

        this.singleFileList['bannerVDO'] = {
          file: file,
          filename: video,
          coverImageURL: await this.generatePreviewCover(file),
        };
      });
    }

    if (itemObj.mediaFiles['bannerVDOMobile']) {
      const video = itemObj.mediaFiles['bannerVDOMobile'][0].id;
      this._fileService.GetFile(video).subscribe(async (res) => {
        const file = new File([res], video, {
          type: res.type,
        });

        this.singleFileList['bannerVDOMobile'] = {
          file: file,
          filename: video,
          coverImageURL: await this.generatePreviewCover(file),
        };
      });
    }
  }

  async prepareVideo(event, key) {
    var self = this;

    if (event.target.files.length > 0) {
      const filename = event.target.files[0].name;
      const file = event.target.files[0];
      if (file && file.size <= this.videoFileSize) {
        let obj: { [k: string]: any } = {};
        obj.file = file;
        obj.coverImageURL = await self.generatePreviewCover(file);
        obj.filename = filename;
        self.singleFileList[key] = obj;
        self.handleList[key] = true;
        self.videoWasChanged = true;

        console.log(self.singleFileList);
      } else {
        console.error(
          `File size exceeds the maximum limit of ${this.bytesToSizes(
            this.videoFileSize
          )}.`
        );

        Swal.fire(
          'Error',
          `File size exceeds the maximum limit of ${this.bytesToSizes(
            this.videoFileSize
          )}.`,
          'error'
        );
      }
    }
  }

  getPreviewImageUrl(key: string): any {
    return this.singleFileList[key].coverImageURL;

    // return '';
  }

  getVideoName(key: string): any {
    return this.singleFileList[key].filename;
    // return '';
  }

  bytesToSizes(size: number): string {
    return this._globalFuncService.bytesToSize(size);
  }

  generatePreviewCover(file: File): Promise<SafeUrl> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const video = document.createElement('video');
        video.src = event.target.result;

        video.onloadedmetadata = () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          const context = canvas.getContext('2d');

          // Wait for the video to play for a short duration before capturing the frame
          video.onseeked = () => {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            // Get the base64-encoded data URL of the canvas image
            const previewCoverDataURL = canvas.toDataURL('image/png');

            // Set the preview cover URL
            const tempCoverURL =
              this.sanitizer.bypassSecurityTrustUrl(previewCoverDataURL);

            resolve(tempCoverURL);
          };

          video.currentTime = 1; // Set the time to seek in the video (adjust as needed)
        };
      };
      reader.readAsDataURL(file);
    });
  }

  callSelectVideo(key: string) {
    let vdoInput
    if (key == 'bannerVDO') {
      vdoInput = this.videoUploadComponent.nativeElement;
    } else {
      vdoInput = this.mobileVideoUploadComponent.nativeElement;
    }
   

    vdoInput.click();
  }

  handleInputChanged(value: string, controlName: string) {
    this.handleList[controlName][0] = true;
    const element = this.el.nativeElement.querySelector(`#${controlName}`);
    if (element) {
      this.renderer.addClass(element, 'invalid_border');
    }
  }

  selectedLanguage(lang: string): void {
    this.selectedLang = lang;
  }

  openCancelModal(content: any): void {
    let isConfirm = false;

    let title = this._translateService.instant('Modal.CancelManagePage');
    let detail = this._translateService.instant('Modal.PressSubmitToCancel');
    this.openModal(title, detail, isConfirm);
  }

  openConfirmModal() {
    this.isSubmit = true;
    this.changeRequiredMode();

    if (this.formGroup.invalid) {
      this.invalidAlert = true;
      return;
    }

    if (this.f.isUseVDO.value) {
      if (
        !this.singleFileList['bannerVDO'].file ||
        !this.singleFileList['bannerVDOMobile'].file
      ) {
        this.invalidAlert = true;
        return;
      }
    } else {
      if (
        !this.imageUploadComponent.getFile() ||
        !this.mobileImageUploadComponent.getFile()
      ) {
        this.invalidAlert = true;
        this.imageUploadComponent.handleImage();
        this.mobileImageUploadComponent.handleImage();
        return;
      }
    }

    let isConfirm = true;
    let title = this._translateService.instant('Modal.ConfirmCreate');
    let detail = this._translateService.instant('Modal.AreYouSureToCreate');
    this.openModal(title, detail, isConfirm);
  }

  openModal(title: string, detail: string, IsConfirm: boolean): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.isConfirm = IsConfirm;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      if (IsConfirm) {
        this.Submit();
      } else {
        this._router.navigate([`${this.pathUrl}`]);
      }
    });
  }

  Submit() {
    this.blockUI.start();
    var self = this;

    let finalData = this.prepareFinalData();

    if (this.isEditing) {
      this._apiService
        .UpdateDataById(this.apiPath, this.itemID, finalData.value)
        .subscribe((res) => {
          self.checkFileForUpload(this.itemID);
        });
    } else {
      this._apiService
        .AddData(this.apiPath, finalData.value)
        .subscribe((res) => {
          self.checkFileForUpload(res.data.resultData[0]);
        });
    }
  }

  prepareFinalData(): FormGroup {
    let tempForm: FormGroup = new FormGroup({});
    tempForm.addControl(
      'name',
      this._formBuilder.control(this.f.name.value, [])
    );
    tempForm.addControl(
      'isUseVDO',
      this._formBuilder.control(this.f.isUseVDO.value, [])
    );
    tempForm.addControl(
      'isActive',
      this._formBuilder.control(this.f.isActive.value, [])
    );

    let tempTitleObj = {};
    this.langList.forEach((lang) => {
      tempTitleObj[lang] = this.formGroup.get(`title.${lang}`).value;
    });

    console.log(tempTitleObj);

    tempForm.addControl(
      'title',
      this._formBuilder.control(JSON.stringify(tempTitleObj), [])
    );
    console.log(tempForm);

    return tempForm;
  }

  checkFileForUpload(itemID: string) {
    const uploadPromises = [];

    if (this.singleFileList['bannerVDO'].file) {
      uploadPromises.push(
        this.uploadFiles(
          'Content',
          itemID,
          'bannerVDO',
          0,
          this.singleFileList['bannerVDO'].file
        )
      );
    }

    if (this.singleFileList['bannerVDOMobile'].file) {
      uploadPromises.push(
        this.uploadFiles(
          'Content',
          itemID,
          'bannerVDOMobile',
          0,
          this.singleFileList['bannerVDOMobile'].file
        )
      );
    }

    if (this.imageUploadComponent.getFile()) {
      uploadPromises.push(
        this.imageUploadComponent.uploadImage({
          isEdit: true,
          idResId: itemID,
          tablename: this.tableName,
          collectionName: 'bannerIMG',
          ordinal: 0,
        })
      );
    }

    if (this.mobileImageUploadComponent.getFile()) {
      uploadPromises.push(
        this.mobileImageUploadComponent.uploadImage({
          isEdit: true,
          idResId: itemID,
          tablename: this.tableName,
          collectionName: 'bannerIMGMobile',
          ordinal: 0,
        })
      );
    }

    Promise.all(uploadPromises)
      .then(() => {
        this.loadingFile = false;
        this._componentsService.SuccessSwal();
        this._router.navigate([`${this.pathUrl}`]);
      })
      .catch((error) => {
        this.loadingFile = false;
        this._componentsService.ErrorSwal(error);
        this._router.navigate([`${this.pathUrl}`]);
      });
  }

  uploadFiles(
    tablename: string,
    contentId: string,
    collectionName: string,
    ordinal: number,
    file: any
  ) {
    const formData = new FormData();
    formData.append('file', file);
    return new Promise<void>((resolve, reject) => {
      // Call your file service to upload the video
      this._fileService
        .UpdateFile(tablename, contentId, collectionName, ordinal, formData)
        .subscribe(
          () => {
            console.log('File uploaded successfully!');
            resolve(); // Resolve the promise to indicate the upload is complete
          },
          (error) => {
            console.error('Error uploading File:', error);
            reject(error); // Reject the promise if an error occurs
          }
        );
    });
  }

  changeRequiredMode() {
    if (!this.imageUploadComponent.getFile() && !this.f.isUseVDO.value) {
      this.imageUploadComponent.setErrorStatus(false);
      this.mobileImageUploadComponent.setErrorStatus(false);
    } else if (!this.imageUploadComponent.getFile() && this.f.isUseVDO.value) {
      this.imageUploadComponent.setErrorStatus(true);
      this.mobileImageUploadComponent.setErrorStatus(true);
    } else {
      this.imageUploadComponent.setErrorStatus(true);
      this.mobileImageUploadComponent.setErrorStatus(true);
    }
  }
}
