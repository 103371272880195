<div *ngIf="orderStateData">
  <ng-container
    *ngTemplateOutlet="
      docList;
      context: {
        title: 'Quotation',
        doc: orderStateData.quotation,
        docRouterLink: 'quotation',
        docType: 'quotation',
        docCodeName: 'quotationCode'
      }
    "
  ></ng-container>

  <ng-container
    *ngTemplateOutlet="
      docList;
      context: {
        title: 'Sale Order',
        doc: orderStateData.saleOrder,
        docRouterLink: 'saleorder',
        docType: 'saleOrder',
        docCodeName: 'saleOrderCode'
      }
    "
  ></ng-container>

  <ng-container
    *ngTemplateOutlet="
      nestedDocList;
      context: {
        title: 'Invoice',
        docRouterLink: 'invoice',
        docType: 'invoice',
        docCodeName: 'invoiceCode',
        docList: orderStateData.invoice
      }
    "
  ></ng-container>
</div>

<ng-template
  #docList
  let-doc="doc"
  let-title="title"
  let-docRouterLink="docRouterLink"
  let-docType="docType"
  let-docCodeName="docCodeName"
>
  <p>{{ title }}</p>
  <ul>
    <li>
      <a routerLink="/document/{{ docRouterLink }}/detail/{{ doc.id }}">{{
        doc[docCodeName]
      }}</a>
    </li>
  </ul>
</ng-template>

<ng-template
  #nestedDocList
  let-title="title"
  let-docRouterLink="docRouterLink"
  let-docType="docType"
  let-docCodeName="docCodeName"
  let-docList="docList"
>
  <div *ngFor="let doc of docList; let ind = index">
    <div style="padding: 5px; border: 1px solid #000000; border-radius: 5px">
      <a routerLink="/document/{{ docRouterLink }}/detail/{{ doc.id }}">
        <p>{{ title }}</p>
        {{ doc[docCodeName] }}
      </a>

      <div *ngIf="docList[ind]['taxInvoice']">
        <p>Tax Invoice</p>
        <ul>
          <li>
            <a
              routerLink="/document/tax/detail/{{
                docList[ind]['taxInvoice'].id
              }}"
              >{{ docList[ind]["taxInvoice"]["taxInvoiceCode"] }}</a
            >
          </li>
        </ul>
      </div>

      <div *ngIf="docList[ind]['receipt']">
        <p>Receipt</p>
        <ul *ngFor="let rc of docList[ind]['receipt']">
          <li>
            <a routerLink="/document/receipt/detail/{{ rc.id }}">{{
              rc["receiptCode"]
            }}</a>
          </li>
        </ul>
      </div>

      <div *ngIf="docList[ind]['creditNote']">
        <p>Credit Note</p>
        <ul *ngFor="let cdn of docList[ind]['creditNote']">
          <li>
            <a routerLink="/document/credit-note/detail/{{ cdn.id }}">{{
              cdn["creditNoteCode"]
            }}</a>
          </li>
        </ul>
      </div>

      <div *ngIf="docList[ind]['debitNote']">
        <p>Debit Note</p>
        <ul *ngFor="let dbn of docList[ind]['debitNote']">
          <li>
            <a routerLink="/document/debit-note/detail/{{ dbn.id }}">{{
              dbn["debitNoteCode"]
            }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>
