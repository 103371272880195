import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Category from 'app/main/model/Category';
import Class from 'app/main/model/Class';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import Group from 'app/main/model/Group';
import SubCategory from 'app/main/model/SubCategory';
import SubClass from 'app/main/model/SubClass';
import { ApiService } from 'app/main/service/api.service';
import { GlobalFuncService } from 'app/main/service/global-func.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ModalComponent } from '../../modal/modal.component';
import { MainConfig, filesPropNameConfig } from 'app/main/config/MainConfig';
import { environment } from 'environments/environment';
import { FileTypeFilter } from '../../custom-dropzone/file-type.enum';
import { takeUntil } from 'rxjs/operators';
import { CustomDropzoneComponent } from '../../custom-dropzone/custom-dropzone.component';
import { ImageUploadComponent } from '../../image-upload/image-upload.component';
import { ComponentsService } from '../../components.service';
import { TranslateService } from '@ngx-translate/core';
import { FilesApiService } from 'app/main/service/files-api.service';
import Item from 'app/main/model/Item';
import { GroupService } from 'app/main/pages/item-manage/group/group.service';
import { CurrencyPipe } from '@angular/common';
import { error } from 'console';
import { ItemSetService } from 'app/main/pages/item-manage/item-set/item-set.service';

@Component({
  selector: 'app-item-set-form',
  templateUrl: './item-set-form.component.html',
  styleUrls: ['./item-set-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ItemSetFormComponent implements OnInit, AfterViewInit, OnDestroy {
  private dataSubject = new BehaviorSubject<any>(null);
  data$ = this.dataSubject.asObservable();
  private _unsubscribeAll: Subject<any> = new Subject();
  private dataSubscription: Subscription;
  private fileSubscription: Subscription;

  @BlockUI() blockUI: NgBlockUI;
  formGroup: FormGroup;
  itemList: any[] = [];
  selectedItemList: any[] = [];
  selectedItemId: any[] = [];
  originalItem: any[] = [];
  selectedItemMap: Map<string, boolean> = new Map<string, boolean>();
  activityArray: any[] = [];
  alreadyItemSelected: any[] = [];
  ghostArray: any[] = [];
  contentHeader: object;
  itemID: string;
  modeText: string;

  brandId: any = null;
  groupId: any = null;
  categoryId: any = null;
  subCategoryId: any = null;
  classId: any = null;
  subClassId: any = null;
  isSubmit: Boolean = false;

  itemBarcode: string = '';

  BrandObjList: any[] = [];
  GroupObjList: Group[];
  categoryList: Category[] = [];
  subCategoryList: SubCategory[] = [];
  classList: Class[] = [];
  subClassList: SubClass[] = [];

  FileTypeFilter = FileTypeFilter;

  images: Blob[] = [];
  filesPropName = filesPropNameConfig;
  isLoading: boolean;
  isLoadingItem: boolean = true;
  isLoadingBrand: boolean = true;
  isLoadingGroup: boolean = true;
  isLoadingCategory: boolean = true;
  isLoadingSubCategory: boolean = true;
  isLoadingClass: boolean = true;
  isLoadingSubClass: boolean = true;
  isEditing: boolean = false;
  isItemListLoaded: boolean = false;

  summernoteConfig: any;
  apiPath: string;
  pathUrl: string;
  componentName: string;

  passData: any;

  invalidSelectedItem: boolean[] = [];

  @Input() selectedLang: string = environment.langDefault;
  @Input() itemObj: any;
  @Input() callbackUrl: string;

  langList = environment.langContent;
  isQuantityDecimal = environment.transaction.isQuantityDecimal;
  @ViewChild('imageUploadComponent') imageUploadComponent: ImageUploadComponent;
  @ViewChildren('itemSetList') formTable: QueryList<ElementRef<HTMLLIElement>>;
  @ViewChild('CustomDropzone') dropzoneComponent: CustomDropzoneComponent;
  constructor(
    private _apiService: ApiService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _translateService: TranslateService,
    private _modalService: NgbModal,
    private _fileService: FilesApiService,
    private _formBuilder: FormBuilder,
    private _componentsService: ComponentsService,
    private _globalFuncService: GlobalFuncService,
    private _mainConfig: MainConfig,
    private _itemSetService: ItemSetService,
    private _currencyPipe: CurrencyPipe,
    private elementRef: ElementRef,
    private renderer: Renderer2
  ) {
    this.summernoteConfig = this._mainConfig.summernoteNewsConfig;
    this.blockUI.start();
    this.SetLoadingState();
    this.componentName = 'Item';
    this.apiPath = 'item';
    this._unsubscribeAll = new Subject();
    this.initData();
    this.pathUrl = this._itemSetService.pathUrl;
  }

  SetLoadingState() {
    this.isLoading = true;
  }

  SetLoadedState() {
    this.isLoading = false;
  }

  initData() {
    this.formGroup = this._formBuilder.group({
      id: [null],
      itemCode: [null],
      itemId: [null],
      groupId: [null],
      categoryId: [null],
      subCategoryId: [null],
      classId: [null],
      subClassId: [null],
      itemBarcode: [''],
      name: ['', [Validators.required, Validators.maxLength(100)]],
      brandId: [null],
      purchasePrice: ['0.00', [Validators.required]],
      sellingPrice: ['0.00', [Validators.required]],
      taxRate: [null],
      vendorName: [null],
      package: [null],
      unit: [null],
      quantity: ['0.00', [Validators.required]],
      dimension: [null],
      weight: [null],
      volume: [null],
      color: [null],
      material: [null],
      texture: [null],
      isActive: [true],
      isBestSeller: [false],
      isNewProduct: [false],
      isItemSet: true,
    });

    const langDetailControls: unknown = {};
    const langDescControls: unknown = {};
    this.langList.forEach((lang) => {
      for (const lang of this.langList) {
        langDetailControls[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);

        langDescControls[lang] = this._formBuilder.control('', [
          Validators.required,
        ]);
      }
      this.formGroup.addControl(
        'detail',
        this._formBuilder.group(langDetailControls)
      );

      this.formGroup.addControl(
        'description',
        this._formBuilder.group(langDescControls)
      );
    });

    // this.getGroupData();
    // this.getItemList();
  }

  get f() {
    return this.formGroup.controls;
  }

  ngOnInit(): void {
    this.itemID = this._route.snapshot.paramMap.get('id');

    this.isEditing = this.itemID ? true : false;
    this.isLoading = this.isEditing ? true : false;

    this.modeText = this.isEditing
      ? this._translateService.instant('General.Edit')
      : this._translateService.instant('General.Create');

    this.contentHeader = {
      headerTitle: this.modeText + ' ' + this.componentName,
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: this.componentName,
            isLink: true,
            link: this.pathUrl,
          },
          {
            name: this._translateService.instant('General.Home'),
            isLink: true,
            link: '/',
          },
        ],
      },
    };
  }

  ngOnDestroy(): void {
    this.blockUI.stop();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngAfterViewInit(): void {
    this.formTable.changes.subscribe((list) => {
      this.selectedItemList.forEach((element, index) => {
        let rowID = index + 1;
        let selectedQuantityForm = document.getElementById(
          'selectedQuantity' + rowID
        ) as HTMLFormElement;

        selectedQuantityForm.value = this._globalFuncService.FormatToMoney(
          element.quantity,
          false,
          !this.isQuantityDecimal
        );
      });

      this.CalculateSummaryPrice();
    });
    this.initForm(this.isEditing);
    // if (this.isEditing && this.isItemListLoaded) {
    //   this.filterItemList();
    // }
  }

  initForm(isEditing: boolean): void {
    this.getBrandData();

    this.getGroupData();

    if (isEditing) {
      this.getItemData(this.itemID);
    } else {
      this.addItemRow();
      this.getItemList();

      this.itemObj = new Item();
      this.isLoading = false;
    }
  }

  getItemData(guid: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.dataSubscription = this._apiService
        .GetDataById(this.apiPath, guid)
        .subscribe(
          (res) => {
            const self = this;
            self.itemObj = res.data.resultData[0];
            this.selectedItemId = self.itemObj.itemSet;
            console.log('selectedItemId : ', this.selectedItemId);
            console.log('itemObj Data : ', res.data.resultData[0]);
            this.getItemList();
            this.buildForm();

            if (self.itemObj.mediaFiles[self.filesPropName.item.coverImage]) {
              this._fileService
                .GetFile(
                  self.itemObj.mediaFiles[self.filesPropName.item.coverImage][0]
                    .id
                )
                .subscribe((res: Blob) => {
                  if (self.imageUploadComponent) {
                    self.imageUploadComponent.setCoverImage(
                      res,
                      self.itemObj.mediaFiles[
                        self.filesPropName.item.coverImage
                      ][0].id
                    );
                  }
                });
            }

            self.itemObj.purchasePrice = parseFloat(
              self.itemObj.purchasePrice.toFixed(2)
            );

            if (self.itemObj.mediaFiles[self.filesPropName.item.detailImages]) {
              let detailImageArr =
                self.itemObj.mediaFiles[self.filesPropName.item.detailImages];
              self.passData = [];
              const fileFetchPromises: Promise<void>[] = detailImageArr.map(
                (element) => {
                  return new Promise<void>((fileResolve, fileReject) => {
                    let ghostObj: ghostObj = {
                      id: element.id,
                      file: null,
                      ordinal: element.ordinal,
                    };
                    self._fileService.GetFile(element.id).subscribe(
                      (res) => {
                        ghostObj.file = res;
                        this.ghostArray.push(ghostObj);
                        self.images.push(res);
                        const filename = element;
                        const type = res.type.split('/')[1];
                        const name = element + '.' + type;
                        const file = new File([res], name, { type: res.type });

                        fileResolve();
                      },
                      (error) => {
                        fileReject(error);
                      }
                    );
                  });
                }
              );

              Promise.all(fileFetchPromises)
                .then(() => {
                  this.ghostArray.sort((a, b) => a.ordinal - b.ordinal);
                  const minOrdinal = this.ghostArray[0].ordinal;
                  this.ghostArray.forEach((item) => {
                    item.ordinal -= minOrdinal;
                  });
                  this.ghostArray.forEach((item) => {
                    this.passData.push(item.file);
                  });
                  console.log('Sorted Files:', this.ghostArray);
                  console.log('**********FINISHED**********', self.passData);
                  if (self.passData.length === detailImageArr.length) {
                    this.dropzoneComponent.ngOnChangesConfig(self.passData);
                  }
                  this.dataSubject.next({
                    itemObj: self.itemObj,
                    passData: self.passData,
                  });
                  resolve();
                })
                .catch((error) => {
                  reject(error);
                });
            } else {
              this.dataSubject.next({
                itemObj: self.itemObj,
                passData: [],
              });
              resolve();
            }
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  buildForm(): void {
    var self = this;
    console.log(self.itemObj.itemSet);
    this.selectedItemId = self.itemObj.itemSet ? self.itemObj.itemSet : 'All';

    this.brandId = self.itemObj.brandId ? self.itemObj.brandId : 'All';

    this.groupId = self.itemObj.groupId ? self.itemObj.groupId : 'All';
    this.getCategoryData(self.itemObj.groupId);

    this.categoryId = self.itemObj.categoryId ? self.itemObj.categoryId : 'All';
    this.getSubCategoryData(self.itemObj.categoryId);

    this.subCategoryId = self.itemObj.subCategoryId
      ? self.itemObj.subCategoryId
      : 'All';
    this.getClassData(self.itemObj.subCategoryId);

    this.classId = self.itemObj.classId ? self.itemObj.classId : 'All';
    this.getSubClassData(self.itemObj.classId);

    this.subClassId = self.itemObj.subClassId ? self.itemObj.subClassId : 'All';

    this.itemBarcode = self.itemObj.itemBarcode;

    let tempDescriptionObj = JSON.parse(self.itemObj.description);
    let tempDetailObj = JSON.parse(self.itemObj.detail);
    this.langList.forEach((lang) => {
      this.formGroup
        .get(`description.${lang}`)
        .patchValue(tempDescriptionObj[lang]);

      this.formGroup.get(`detail.${lang}`).patchValue(tempDetailObj[lang]);
    });

    this.formGroup.patchValue({
      id: self.itemObj.id,
      itemCode: self.itemObj.itemCode,
      brandId: self.itemObj.brandId,
      groupId: self.itemObj.groupId,
      categoryId: self.itemObj.categoryId,
      subCategoryId: self.itemObj.subCategoryId,
      classId: self.itemObj.classId,
      subClassId: self.itemObj.subClassId,
      itemBarcode: self.itemObj.itemBarcode,
      name: self.itemObj.name,
      itemId: self.itemObj.selectedItemId,
      purchasePrice: this._currencyPipe.transform(
        self.itemObj.purchasePrice,
        '',
        '',
        '1.2-2'
      ),
      sellingPrice: this._currencyPipe.transform(
        self.itemObj.sellingPrice,
        '',
        '',
        '1.2-2'
      ),
      taxRate: self.itemObj.taxRate,
      // description: self.itemObj.description,
      // detail: self.itemObj.detail,

      quantity: this._currencyPipe.transform(
        self.itemObj.quantity,
        '',
        '',
        '1.2-2'
      ),
      dimension: self.itemObj.dimension,
      weight: self.itemObj.weight,
      volume: self.itemObj.volume,
      isActive: self.itemObj.isActive,
      isNewProduct: self.itemObj.isNewProduct,
      isBestSeller: self.itemObj.isBestSeller,
    });

    this.SetLoadedState();
  }

  getItemList(): Promise<any> {
    var self = this;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData('item', { isDelete: false, isActive: true })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(
          (res) => {
            const filteredItemList = res.data.resultData.filter(
              (item: { id: string }) => item.id !== this.itemID
            );
            self.itemList = filteredItemList;

            this.originalItem = [...self.itemList];
            self.selectedItemMap = new Map<string, boolean>();

            self.itemList.forEach((item) => {
              self.selectedItemMap.set(item.id, false);
            });

            if (this.isEditing) {
              console.log(this.selectedItemId);
              this.alreadyItemSelected = res.data.resultData.filter((item) =>
                this.selectedItemId.some(
                  (selectedItem) => selectedItem.ingredientItemId === item.id
                )
              );

              if (this.alreadyItemSelected.length === 0) {
                this.alreadyItemSelected = filteredItemList;
              }
              console.log('alreadyItemSelected', this.alreadyItemSelected);

              this.alreadyItemSelected.forEach((alreadySelectedItem) => {
                this.selectedItemMap.set(alreadySelectedItem.id, true);
              });

              this.addItemRowFromAlreadySelected();
            }

            console.log('selectedItemMap: ', this.selectedItemMap);
            this.blockUI.stop();
            this.isLoadingItem = false;
          },
          (err) => {
            this._componentsService.ErrorSwal();
            this.blockUI.stop();
          }
        );
    });
  }

  getBrandData(): Promise<any> {
    this.isLoadingBrand = true;
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`brand`, { isDelete: false })
        .subscribe((res) => {
          this.BrandObjList = res.data.resultData;

          this.isLoading = false;
          this.isLoadingBrand = false;
        }, rejects);
    });
  }

  getGroupData(): Promise<any> {
    return new Promise((resolve, rejects) => {
      this._apiService
        .GetAllData(`group`, { isDelete: false })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          this.GroupObjList = res.data.resultData;
          this.isLoadingGroup = false;
        }, rejects);
    });
  }

  getCategoryData(selectedGroupId): void {
    var self = this;

    this.resetCategory();
    this.resetSubCategory();
    this.resetClass();
    this.resetSubClass();

    if (selectedGroupId) {
      this._apiService
        .GetAllData('category', { groupId: selectedGroupId, isDelete: false })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          self.categoryList = res.data.resultData;
          this.isLoadingCategory = false;
        });
    }
  }

  getSubCategoryData(selectedCategoryId): void {
    var self = this;
    this.resetSubCategory();
    this.resetClass();
    this.resetSubClass();

    if (selectedCategoryId) {
      this._apiService
        .GetAllData('subcategory', {
          categoryId: selectedCategoryId,
          isDelete: false,
        })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          self.subCategoryList = res.data.resultData;
          this.isLoadingSubCategory = false;
        });
    }
  }

  getClassData(selectedSubCategoryId): void {
    var self = this;

    this.resetClass();
    this.resetSubClass();

    if (selectedSubCategoryId) {
      this._apiService
        .GetAllData('class', {
          subCategoryId: selectedSubCategoryId,
          isDelete: false,
        })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          self.classList = res.data.resultData;
          this.isLoadingClass = false;
        });
    }
  }

  getSubClassData(selectedClassId): void {
    var self = this;

    this.resetSubClass();

    if (selectedClassId) {
      this._apiService
        .GetAllData('subclass', { classId: selectedClassId, isDelete: false })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          self.subClassList = res.data.resultData;
          this.isLoadingSubClass = false;
        });
    }
  }

  resetGroup(): void {
    this.groupId = null;
  }

  resetCategory(): void {
    this.categoryId = null;
    this.categoryList = [];
  }

  resetSubCategory(): void {
    this.subCategoryId = null;
    this.subCategoryList = [];
  }

  resetClass(): void {
    this.classId = null;
    this.classList = [];
  }

  resetSubClass(): void {
    this.subClassId = null;
    this.subClassList = [];
  }

  convertNumbertoMoneyFormat(value: number): string {
    return this._globalFuncService.FormatToMoney(value);
  }

  addItemRow(): void {
    let newItem = {
      ingredientItemId: null,
      quantity: 1.0,
      unitPrice: 0.0,
    };

    this.invalidSelectedItem.push(true);
    console.log('invalidSelectedItem : ', this.invalidSelectedItem);

    this.selectedItemList.push(newItem);
    this.selectedItemId.push(newItem);
    this.activityArray.push(newItem);
  }

  addItemRowFromAlreadySelected(): void {
    let tempQuantity;
    let tempRemain;
    if (this.isEditing) {
      this.alreadyItemSelected.sort((a, b) => a.ordinal - b.ordinal);
      this.alreadyItemSelected.forEach((selectedItem, index) => {
        console.log(selectedItem.id);
        console.log('selectedItemId : ', this.selectedItemId);

        this.invalidSelectedItem.push(false);
        console.log('invalidSelectedItem : ', this.invalidSelectedItem);

        tempQuantity = this.selectedItemId.find(
          (item) => item.ingredientItemId === selectedItem.id
        );

        let newItem = {
          ingredientItemId: selectedItem.id,
          quantity: tempQuantity.quantity,
          unitPrice: selectedItem.sellingPrice,
          remain: selectedItem.quantity,
        };
        this.selectedItemList.push(newItem);

        this.activityArray.push({
          ingredientItemId: selectedItem.id,
          quantity: tempQuantity.quantity,
          ordinal: index,
          remain: selectedItem.quantity,
        });
        console.log('activityArray : ', this.activityArray);
      });
    }
  }

  removeRow(index): void {
    let itemId = this.selectedItemList[index].ingredientItemId;

    console.log('itemId: ', itemId);
    console.log('index: ', index);
    console.log('selectedItemList: ', this.selectedItemList);

    this.invalidSelectedItem.splice(index, 1);
    this.selectedItemList.splice(index, 1);
    this.selectedItemId.splice(index, 1);
    this.activityArray.splice(index, 1);

    if (itemId) {
      this.selectedItemMap.set(itemId, false);
    }

    console.log('invalidSelectedItem: ', this.invalidSelectedItem);
    console.log('removeRow : ', this.selectedItemMap);
    this.filterItemList();
  }

  logInputValue($event, id) {
    console.log('this quantity of : ', $event, id);
  }

  validateInput(event: any, quantity, rowId) {
    const input = event.target;
    const inputValue = input.value;
    const numericValue = parseFloat(inputValue);
    const inputElement = this.elementRef.nativeElement.querySelector(
      `#selectedQuantity${rowId}`
    );

    if (!isNaN(numericValue) || inputValue === '' || inputValue === '.') {
      input.value = inputValue;
      console.log('เปรียบเทียบ', quantity, inputElement);
      if (numericValue >= quantity + 0.00001) {
        this.renderer.addClass(inputElement, 'invalid-border');
      } else {
        this.renderer.removeClass(inputElement, 'invalid-border');
      }
    } else {
      console.log('ไม่ใช่ตัวเลข');
    }
  }

  onSelectedItem(rowID, itemID) {
    console.log('before', this.selectedItemList);
    // this.itemList = this.originalItem

    const quickObj: QuickObj = {
      ingredientItemId: 0,
      quantity: 0,
      ordinal: rowID - 1,
    };

    let numQuantity = document.getElementById(
      'selectedQuantity' + rowID
    ) as HTMLFormElement;
    let tempQuantity = this._globalFuncService.MoneyFormatToNumber(
      numQuantity.value
    );

    let price = document.getElementById(
      'selectedItemPrice' + rowID
    ) as HTMLFormElement;
    let item = this.itemList.find((item) => item.id === itemID);
    const selectedItem = this.selectedItemList[rowID - 1];

    selectedItem.remain = item.quantity;
    let itemsellingPrice = item.sellingPrice.toString();

    const prevSelectedItemID = this.selectedItemId[rowID - 1];

    const prevSelectedItemMapValue = this.selectedItemMap.get(
      prevSelectedItemID.ingredientItemId
    );

    if (!prevSelectedItemMapValue) {
      console.log('คืนค่าให้');
      this.selectedItemMap.set(prevSelectedItemID.ingredientItemId, false);
    }

    quickObj.ingredientItemId = itemID;
    quickObj.quantity = tempQuantity;

    this.selectedItemMap.set(itemID, true);

    price.value = this.convertNumbertoMoneyFormat(itemsellingPrice);
    this.selectedItemList[rowID - 1].id = item.id;
    this.selectedItemList[rowID - 1].unitPrice = item.sellingPrice;

    this.invalidSelectedItem[rowID - 1] = false;
    console.log('invalidSelectedItem : ', this.invalidSelectedItem);

    console.log('Item selected:', rowID, item);
    this.selectedItemId[rowID - 1] = item.id;
    this.CalculateSummaryPrice();
    this.filterItemList();

    console.log(this.selectedItemMap);
    console.log(this.itemList);

    // const existingIndex = this.activityArray.findIndex(
    //   (obj) => obj.id === rowID
    // );
    // if (existingIndex !== -1) {
    //   this.activityArray[existingIndex] = quickObj;
    // } else {
    //   this.activityArray.push(quickObj);
    // }
    this.activityArray[rowID - 1] = quickObj;
  }

  changeItemCodeByIdx(index, newItemCode) {
    console.log(index);

    if (index >= 0 && index < this.activityArray.length) {
      const item = this.activityArray[index];
      if (item.ingredientItemId !== null && item.ingredientItemId !== 0) {
        item.quantity = newItemCode;
      }
    }
  }

  filterItemList(): void {
    this.itemList = this.originalItem.filter((item, index) => {
      let itemId = item.id;
      let isItemSelected = this.selectedItemMap.get(itemId);
      return !isItemSelected;
    });
    console.log('filterItemList: ', this.itemList);
  }

  removeComma(element) {
    let formattedAmount: string;
    formattedAmount = element.target.value;
    element.target.value = formattedAmount.replace(/,/g, '');
  }

  formatMoney(
    elementID: string,
    showFourDigit: boolean = false,
    disableDecimals: boolean = false
  ) {
    let element = document.getElementById(elementID) as HTMLFormElement;

    let formattedAmount;
    formattedAmount = element.value;

    const moneyPattern = /^-?(\d{1,3}(,\d{3})*|\d+)(\.\d+)?$/;
    let isMoney = moneyPattern.test(formattedAmount);

    formattedAmount =
      this._globalFuncService.MoneyFormatToNumber(formattedAmount);
    element.value = this._globalFuncService.FormatToMoney(
      formattedAmount,
      showFourDigit,
      disableDecimals
    );
  }

  CalculateRowSummaryPrice(rowID): void {
    let numPrice = document.getElementById(
      'selectedItemPrice' + rowID
    ) as HTMLElement;
    let numQuantity = document.getElementById(
      'selectedQuantity' + rowID
    ) as HTMLFormElement;

    let numtotalPrice = document.getElementById(
      'selectedItemTotalPrice' + rowID
    ) as HTMLElement;

    let tempUnitPrice = this._globalFuncService.MoneyFormatToNumber(
      numPrice.innerText
    );
    let tempQuantity = this._globalFuncService.MoneyFormatToNumber(
      numQuantity.value
    );

    this.selectedItemList[rowID - 1].unitPrice = tempUnitPrice;
    this.selectedItemList[rowID - 1].quantity = tempQuantity;

    numtotalPrice.innerText = this._globalFuncService.FormatToMoney(
      tempUnitPrice * tempQuantity
    );

    this.changeItemCodeByIdx(rowID - 1, tempQuantity);

    this.CalculateSummaryPrice();
  }

  CalculateSummaryPrice(): void {
    let summaryPrice = document.getElementById('summaryPrice') as HTMLElement;
    let sellingPrice = document.getElementById(
      'sellingPrice'
    ) as HTMLFormElement;

    let sum = 0;
    this.selectedItemList.forEach((item) => {
      sum += item.unitPrice * item.quantity;
    });

    summaryPrice.innerText = this._globalFuncService.FormatToMoney(sum);
    sellingPrice.value = this._globalFuncService.FormatToMoney(sum);

    this.formGroup.get('sellingPrice').patchValue(sellingPrice.value);
  }

  openCancelModal(): void {
    let isConfirm = false;
    let title;
    let detail;
    let contentNameText = this.isEditing
      ? this.itemObj.name
      : this.componentName;
    if (this.isEditing) {
      title = this._translateService.instant('ManageItemSet.CancelEditTitle');
      detail = this._translateService.instant('ManageItem.CancelEdit');
    } else {
      title = this._translateService.instant('ManageItemSet.CancelCreateTitle');
      detail = this._translateService.instant('ManageItemSet.CancelCreate');
    }

    this.openModal(title, isConfirm, detail);
  }

  openConfirmModal(): void {
    console.log(this.activityArray);
    this.imageUploadComponent.handleImage();

    console.log('image submition : ', this.imageUploadComponent.submition);

    this.formGroup.get('itemId').patchValue(this.selectedItemId);

    if (this.images.length <= 0) {
      this.dropzoneComponent.handle();
    }

    this.isSubmit = true;
    if (
      this.formGroup.invalid ||
      this.invalidSelectedItem.includes(true) ||
      this.images.length <= 0 ||
      !this.imageUploadComponent.submition
    ) {
      console.log('INVALID DATA', this.formGroup.value);
      return;
    } else {
      let title;
      let detail;
      let isConfirm = true;
      if (this.isEditing) {
        title = this._translateService.instant(
          'ManageItemSet.ConfirmEditTitle'
        );
        detail = this._translateService.instant('ManageItemSet.ConfirmEdit');
      } else {
        title = this._translateService.instant(
          'ManageItemSet.ConfirmCreateTitle'
        );
        detail = this._translateService.instant('ManageItemSet.ConfirmCreate');
      }
      this.openModal(title, isConfirm, detail);
    }
  }

  openModal(title: string, isConfirm: boolean, detail: string): void {
    const modalRef = this._modalService.open(ModalComponent, {
      centered: true,
      backdrop: 'static',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.isConfirm = isConfirm;
    modalRef.componentInstance.detail = detail;
    modalRef.componentInstance.callBackFunc.subscribe((res) => {
      if (isConfirm) {
        this.Submit();
      } else {
        this._router.navigate([`/item-manage/set`]);
      }
    });
  }

  onFilesUploaded(files: File[]): void {
    this.images = files;
  }
  uploadFiles(
    isUpdateFile: boolean,
    itemGuid: string,
    collectionName: string,
    ordinal: number,
    filePool: any
  ): void {
    let fileArr = new FormData();
    filePool.forEach((file: Blob) => {
      // let fullFileName = file.name

      fileArr.append('files', file);
    });

    if (isUpdateFile) {
      this._fileService
        .UpdateFile(
          this.apiPath,
          this.itemObj.id.toString(),
          collectionName,
          ordinal,
          fileArr
        )
        .subscribe((res) => {
          // this._componentsService.SuccessSwal();
          return;
        });
    } else {
      this._fileService
        .AddFile(this.apiPath, itemGuid, collectionName, ordinal, fileArr)
        .subscribe((res) => {
          // this._componentsService.SuccessSwal();
          return;
        });
    }
  }
  Submit(): void {
    var self = this;
    let isUpdatefile;
    this.blockUI.start();
    console.log(this.formGroup.value);

    if (this.formGroup.invalid || this.invalidSelectedItem.includes(true)) {
      let title = this._translateService.instant('Alert.FormValidationFailed');
      this._componentsService.ErrorSwal(title);
    }

    let tempForm = new FormGroup({});
    this.prepareFinalData(this.formGroup, tempForm);

    if (this.isEditing) {
      isUpdatefile = true;
      this._apiService
        .UpdateDataById(
          this.apiPath,
          this.itemObj.id.toString(),
          tempForm.value
        )
        .subscribe(
          (res) => {
            console.log('res from update', res);
            let tempobj: FormGroup = this._formBuilder.group({});
            tempobj.addControl(
              'itemSets',
              this._formBuilder.control(this.activityArray)
            );

            this._apiService
              .UpdateDataById(
                'ItemSet',
                this.itemObj.id.toString(),
                // tempobj.controls['itemSet'].value
                tempobj.value
              )
              .subscribe((res) => {
                console.log('ADD ITEM INTO ITEMSET', res);
              });
            const params = {
              isEdit: true,
              idResId: res.data.resultData[0],
              tablename: this.apiPath,
              collectionName: this.filesPropName.item.coverImage,
              ordinal: 0,
            };
            this.imageUploadComponent.uploadImage(params);

            if (self.images.length >= 0) {
              self.uploadFiles(
                isUpdatefile,
                self.itemObj.id.toString(),
                this.filesPropName.item.detailImages,
                0,
                self.images
              );
            }

            self._componentsService.SuccessSwal(
              this._translateService.instant('Alert.Success'),
              this._translateService.instant('Alert.Confirm'),
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              () => {
                self._router.navigate([this.pathUrl]);
              }
            );
          },
          (err) => {
            self._componentsService.ErrorSwal();
          }
        );

      this._apiService
        .SetIsActiveData(
          this.apiPath,
          this.itemObj.id.toString(),
          this.formGroup.controls.isActive.value
        )
        .subscribe((res) => {
          return;
        });
    } else {
      isUpdatefile = false;

      this._apiService.AddData(this.apiPath, tempForm.value).subscribe(
        (res) => {
          console.log('DOM : ' + res.data.resultData);
          let tempobj: FormGroup = this._formBuilder.group({});
          tempobj.addControl(
            'itemSets',
            this._formBuilder.control(this.activityArray)
          );

          this._apiService
            .UpdateDataById(
              'ItemSet',
              res.data.resultData[0],
              // tempobj.controls['itemSet'].value
              tempobj.value
            )
            .subscribe((res) => {
              console.log('ADD ITEM INTO ITEMSET', res);
            });
          const params = {
            isEdit: false,
            idResId: res.data.resultData[0],
            tablename: this.apiPath,
            collectionName: this.filesPropName.item.coverImage,
            ordinal: 0,
          };

          // cover file upload
          this.imageUploadComponent.uploadImage(params);

          // multiple picture upload
          if (self.images.length > 0) {
            let guid = res.data.resultData[0];
            self.uploadFiles(
              isUpdatefile,
              guid,
              self.filesPropName.item.detailImages,
              0,
              self.images
            );
          }

          self._componentsService.SuccessSwal(
            this._translateService.instant('Alert.Success'),
            this._translateService.instant('Alert.Confirm'),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            () => {
              self._router.navigate([this.pathUrl]);
            }
          );

          this._apiService
            .SetIsActiveData(
              this.apiPath,
              res.data.resultData[0],
              this.formGroup.controls.isActive.value
            )
            .subscribe((res) => {
              return;
            });
        },
        (err) => {
          self._componentsService.ErrorSwal();
        }
      );
    }
  }

  checkFirstLetterInvalid(text, fControl: FormControl) {
    if (this._globalFuncService.checkFirstLetterisInvalid(text)) {
      fControl.setErrors({ incorrect: true });
      return true;
    }
  }

  prepareFinalData(data: any, tempForm: FormGroup) {
    for (let controlName in data.controls) {
      tempForm.addControl(
        controlName,
        this._formBuilder.control(data.get(controlName).value)
      );
    }

    console.log(this.activityArray);
    this.activityArray.forEach((element: QuickObj, index) => {
      element.ordinal = index;
    });

    let tempDescriptionObj = {};
    this.langList.forEach((lang) => {
      tempDescriptionObj[lang] = this.formGroup.get(
        `description.${lang}`
      ).value;
    });

    tempForm.removeControl('description');
    tempForm.addControl(
      'description',
      this._formBuilder.control(JSON.stringify(tempDescriptionObj), [])
    );

    let tempDetailObj = {};

    this.langList.forEach((lang) => {
      tempDetailObj[lang] = this.formGroup.get(`detail.${lang}`).value;
    });

    tempForm.removeControl('detail');
    tempForm.addControl(
      'detail',
      this._formBuilder.control(JSON.stringify(tempDetailObj), [])
    );

    if (
      this.f.brandId.value == null ||
      this.f.brandId.value == 'All' ||
      !this.f.brandId.value
    ) {
      this.formGroup.patchValue({ brandId: null });
      tempForm.patchValue({ brandId: null });
    }
    if (
      this.f.groupId.value == null ||
      this.f.groupId.value == 'All' ||
      !this.f.groupId.value
    ) {
      this.formGroup.patchValue({ groupId: null });
      tempForm.patchValue({ groupId: null });
    }
    if (
      this.f.categoryId.value == null ||
      this.f.categoryId.value == 'All' ||
      !this.f.categoryId.value
    ) {
      this.formGroup.patchValue({ categoryId: null });
      tempForm.patchValue({ categoryId: null });
    }
    if (
      this.f.subCategoryId.value == null ||
      this.f.subCategoryId.value == 'All' ||
      !this.f.subCategoryId.value
    ) {
      this.formGroup.patchValue({ subCategoryId: null });
      tempForm.patchValue({ subCategoryId: null });
    }
    if (
      this.f.classId.value == null ||
      this.f.classId.value == 'All' ||
      !this.f.classId.value
    ) {
      this.formGroup.patchValue({ classId: null });
      tempForm.patchValue({ classId: null });
    }
    if (
      this.f.subClassId.value == null ||
      this.f.subClassId.value == 'All' ||
      !this.f.subClassId.value
    ) {
      this.formGroup.patchValue({ subClassId: null });
      tempForm.patchValue({ subClassId: null });
    }
    let tempQuantity = parseFloat(
      this.formGroup.value.quantity.replace(/,/g, '')
    ).toFixed(2);
    let temppurchasePrice = parseFloat(
      this.formGroup.value.purchasePrice.replace(/,/g, '')
    ).toFixed(2);
    let tempsellingPrice = parseFloat(
      this.formGroup.value.sellingPrice.replace(/,/g, '')
    ).toFixed(2);

    tempForm.patchValue({ quantity: tempQuantity });
    tempForm.patchValue({ purchasePrice: temppurchasePrice });
    tempForm.patchValue({ sellingPrice: tempsellingPrice });

    console.log(tempForm);
  }
}

interface QuickObj {
  ingredientItemId: number;
  quantity: any;
  ordinal?: number;
}

interface ghostObj {
  id: number;
  file: any;
  ordinal: number;
}
