<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
      <!-- content-header component -->
      <app-content-header [contentHeader]="contentHeader"></app-content-header>
  
      <app-search-panel
        [forCompanentName]="componentName"
        [pathUrl]="pathUrl + '/create'"
        [isGridView]="isGridView"
        [isForGroupTemplate]="isForGroupTemplate"
        [isShowSortByGroup]="false"
        [isSortByNewest]="true"
        (listView)="listView()"
        (gridView)="gridView()"
        (ActiveStatusFilter)="ActiveStatusFilter($event)"
        (SetLoading)="SetLoadingState()"
        (exportXLSX)="exportXLSX()"
        (setPageSize)="setPageSize($event)"
        (callDataFromAPI)="initData($event)"
      ></app-search-panel>
  
      <section>
        <div [ngClass]="{ 'd-none': isGridView }">
          <app-content-list
            [apiPath]="apiPath"
            [searchData]="searchData"
            [keyName]="keyName"
            [isSortByNewest]="true"
            [mainPathUrl]="pathUrl + '/edit'"
            [editPathUrl]="pathUrl + '/edit'"
            [page]="page"
            [pageSize]="pageSize"
            (deleteFunc)="deleteContent($event)"
            (isActiveFunc)="setActiveContent($event)"
            [isGoEditPage]="isGoEditPage"
          ></app-content-list>
        </div>
      </section>
    </div>
  </div>
  