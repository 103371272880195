<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img
            src="{{ coreConfig.app.appLogoNav }}"
            alt="brand-logo"
            width="36"
          />
        </span>
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex justify-content-between content">
  <!-- <div class="bookmark-wrapper d-flex align-items-center">

    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>



    <li class="nav-item d-none d-lg-block">
      <a type="button" class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li>

  </div> -->

  <ul class="nav navbar-nav align-items-center">
    <li
      class="nav-item d-none d-block d-xl-none ng-star-inserted"
      (click)="toggleSidebar('menu')"
    >
      <a class="nav-link"> <i data-feather="menu" size="22"></i></a>
    </li>

    <!-- ? Language selection | Uncomment if needed-->
    <li ngbDropdown class="nav-item dropdown dropdown-language">
      <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
        <img
          class="flag-icon"
          src="../../../../assets/images/flag/{{
            languageOptions[_translateService.currentLang].flag
          }}.svg"
          width="20"
        /><span class="selected-language">{{
          languageOptions[_translateService.currentLang].title
        }}</span></a
      >
      <div ngbDropdownMenu aria-labelledby="dropdown-flag">
        <a
          *ngFor="let lang of _translateService.getLangs()"
          ngbDropdownItem
          (click)="setLanguage(lang)"
        >
          <img
            class="flag-icon"
            src="../../../../assets/images/flag/{{
              languageOptions[lang].flag
            }}.svg"
            width="20"
          />
          {{ languageOptions[lang].title }}
        </a>
      </div>
    </li>
    <!--/ Language selection -->
  </ul>

  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div class="user-nav d-sm-flex d-none">
          <span class="user-name font-weight-bolder">{{
            this.currentUser?.username
          }}</span
          ><span class="user-status">{{ this.currentUser?.roleSetName }}</span>
        </div>

        <span class="avatar" style="border-radius: 5px;"
          ><img
            class=""
            [src]="profileIMG ? profileIMG : coreConfig.app.defaultIMG"
            alt="avatar"
            height="40"
            style="border-radius: 5px; object-fit: cover;"
            width="40" /><span class="avatar-status-online"></span
        ></span>
      </a>
      <div
        ngbDropdownMenu
        aria-labelledby="navbarUserDropdown"
        class="dropdown-menu dropdown-menu-right"
      >
        <a ngbDropdownItem [routerLink]="['/settings']">
          <span [data-feather]="'settings'" [class]="'mr-50'"></span
          >{{ "Menu.Settings" | translate }}
        </a>
        <!-- <a ngbDropdownItem [routerLink]="['/']">
          <span [data-feather]="'mail'" [class]="'mr-50'"></span>
          Inbox
        </a>
        <a ngbDropdownItem [routerLink]="['/']">
          <span [data-feather]="'check-square'" [class]="'mr-50'"></span>
          Task
        </a>
        <a ngbDropdownItem [routerLink]="['/']">
          <span [data-feather]="'message-square'" [class]="'mr-50'"></span>
          Chats
        </a> -->
        <!-- <div class="dropdown-divider"></div> -->
        <a ngbDropdownItem (click)="logout()"
          ><span [data-feather]="'power'" [class]="'mr-50'"></span
          >{{ "Menu.Logout" | translate }}</a
        >
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
