import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { NewsComponent } from './news.component';
import { AuthGuard } from 'app/auth/helpers';
import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from 'app/main/components/components.module';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { NewsCreateComponent } from './news-create/news-create.component';

const routes: Routes = [
  {
    path: 'cms/news',
    component: NewsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'news-manage' },
  },
  {
    path: 'cms/news/create',
    component: NewsCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'news-create' },
  },
  {
    path: 'cms/news/edit/:id',
    component: NewsCreateComponent,
    canActivate: [AuthGuard],
    data: { animation: 'news-edit' },
  },
];

@NgModule({
  declarations: [NewsComponent, NewsCreateComponent],
  imports: [
    RouterModule.forChild(routes),
    CoreCommonModule,
    CommonModule,
    ContentHeaderModule,
    FormsModule,
    ComponentsModule,
    CorePipesModule,
    TranslateModule,
    NgbPaginationModule,
    DataTablesModule,

  ],
  exports: [NewsComponent],
})
export class NewsModule {}
