<section id="knowledge-base-search">
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div
            class="col-2 px-0 d-flex justify-content-start"
            *ngIf="!notShowCreateBtn"
          >
            <a [routerLink]="[pathUrl]" class="btn btn-primary">
              <i data-feather="plus" class="mr-1"></i
              >{{ "General.Create" | translate }}
            </a>
          </div>
          <div class="col-8 px-0 d-flex justify-content-center">
            <ul
              *ngIf="isForGroupTemplate"
              ngbNav
              #navCenter="ngbNav"
              class="nav nav-pills justify-content-center mb-0"
              style="
                background-color: #eeeeee;
                border-radius: 0.358rem;
                margin-left: 0.5rem;
              "
            >
              <li ngbNavItem>
                <a
                  class="nav-link"
                  style="width: 180px"
                  (click)="childListView()"
                  [class.active]="showMode === enumShowMode.Child"
                  >Category</a
                >
              </li>
              <!-- <li ngbNavItem>
                <a
                  class="nav-link"
                  style="width: 180px"
                  (click)="itemListView()"
                  [class.active]="showMode === enumShowMode.Item"
                  >Child Items</a
                >
              </li> -->
              <li ngbNavItem>
                <a
                  class="nav-link"
                  style="width: 180px"
                  (click)="contentView()"
                  [class.active]="showMode === enumShowMode.Content"
                  >Content</a
                >
              </li>
            </ul>
          </div>

          <div class="col-2 px-0 d-flex justify-content-end">
            <!-- <div class="btn-group btn-group-toggle" *ngIf="!notShowSwitchView" data-toggle="buttons">
              <label
                class="btn btn-icon btn-outline-primary view-btn grid-view-btn"
                [class.active]="isGridView === true"
              >
                <input
                  type="radio"
                  name="radio_options"
                  id="radio_option1"
                  (click)="gridView()"
                />
                <i data-feather="grid" class="font-medium-3"></i>
              </label>
              <label
                class="btn btn-icon btn-outline-primary view-btn list-view-btn"
                [class.active]="isGridView !== true"
              >
                <input
                  type="radio"
                  name="radio_options"
                  id="radio_option2"
                  (click)="listView()"
                />
                <i data-feather="list" class="font-medium-3"></i>
              </label>
            </div> -->
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6">
              <div class="scope-custom">
                <label for="first-name-vertical">{{
                  "General.Search" | translate
                }}</label>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    #searchbar
                    name="searchbar"
                    placeholder="{{ 'General.Search' | translate }}"
                  />
                </div>
              </div>
            </div>

            <!-- <div class="col-6 col-sm-6 col-md-3">
              <div class="scope-custom">
                <label for="first-name-vertical">{{
                  "General.SortBy" | translate
                }}</label>
                <select class="custom-select" #sortFil>
                  <option
                    *ngIf="!isSortByNewest"
                    value='{"SortPath":"Name","Direction":0,"isDelete": false}'
                    selected
                  >
                    {{ "Sort.AscChar" | translate }}
                  </option>
                  <option
                    *ngIf="isSortByNewest"
                    value='{"SortPath":"Name","Direction":0,"isDelete": false}'
                  >
                    {{ "Sort.AscChar" | translate }}
                  </option>
                  <option
                    value='{"SortPath":"Name","Direction":1,"isDelete": false}'
                  >
                    {{ "Sort.DescChar" | translate }}
                  </option>
                  <option
                    *ngIf="!isSortByNewest"
                    value='{"SortPath":"UpdateDate","Direction":1,"isDelete": false}'
                  >
                    {{ "Sort.Newest" | translate }}
                  </option>
                  <option
                    *ngIf="isSortByNewest"
                    value='{"SortPath":"UpdateDate","Direction":1,"isDelete": false}'
                    selected
                  >
                    {{ "Sort.Newest" | translate }}
                  </option>
                  <option
                    value='{"SortPath":"UpdateDate","Direction":0,"isDelete": false}'
                  >
                    {{ "Sort.Oldest" | translate }}
                  </option>
                </select>
              </div>
            </div> -->

            <div class="col-12 col-sm-12 col-md-6">
              <div class="scope-custom">
                <label for="first-name-vertical">{{
                  "General.Status" | translate
                }}</label>
                <select name="sorting" class="custom-select" #activeFil>
                  <option value="all">{{ "General.All" | translate }}</option>
                  <option value="active">
                    {{
                      (notForContentMode ? "Status.Active" : "Status.Published")
                        | translate
                    }}
                  </option>
                  <option value="inactive">
                    {{
                      (notForContentMode
                        ? "Status.Inactive"
                        : "Status.Unpublished"
                      ) | translate
                    }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-6 col-sm-6 col-md-3" *ngIf="isShowRoleSet">
              <div class="scope-custom">
                <label for="first-name-vertical">{{
                  "General.RoleSet" | translate
                }}</label>
                <select
                  class="custom-select"
                  [(ngModel)]="roleSetName"
                  #selectRoleSet
                >
                  <option value="All" selected>
                    {{ "General.All" | translate }}
                  </option>
                  <option
                    [value]="roleSet.roleName"
                    *ngFor="let roleSet of roleSetList"
                  >
                    {{ roleSet.roleName }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          

          <div class="row" *ngIf="isShowSortByGroup">
            <div class="col-12 col-sm-12 col-md-4 mt-1" *ngIf="isShowGroup">
              <div class="scope-custom">
                <label for="first-name-vertical">{{
                  "General.Group" | translate
                }}</label>
                <select
                  class="custom-select"
                  [(ngModel)]="groupId"
                  (change)="getCategoryData($event.target.value)"
                  #selectGroup
                >
                  <option value="All" selected>
                    {{ "General.All" | translate }}
                  </option>
                  <option [value]="group.id" *ngFor="let group of groupList">
                    {{ group.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-6 col-sm-6 col-md-4 mt-1" *ngIf="isShowCategory">
              <div class="scope-custom">
                <label for="first-name-vertical">{{
                  "General.Category" | translate
                }}</label>
                <select
                  class="custom-select"
                  [(ngModel)]="categoryId"
                  (change)="getSubCategoryData($event.target.value)"
                  #selectCategory
                >
                  <option value="All" selected>
                    {{ "General.All" | translate }}
                  </option>
                  <option
                    [value]="category.id"
                    *ngFor="let category of categoryList"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-6 col-sm-6 col-md-4 mt-1" *ngIf="isShowSubCategory">
              <div class="scope-custom">
                <label for="first-name-vertical">{{
                  "General.SubCategory" | translate
                }}</label>
                <select
                  class="custom-select"
                  [(ngModel)]="subCategoryId"
                  (change)="getClassData($event.target.value)"
                  #selectSubCategory
                >
                  <option value="All" selected>
                    {{ "General.All" | translate }}
                  </option>
                  <option
                    [value]="subCategory.id"
                    *ngFor="let subCategory of subCategoryList"
                  >
                    {{ subCategory.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-6 col-sm-6 col-md-4 mt-1" *ngIf="isShowClass">
              <div class="scope-custom">
                <label for="first-name-vertical">{{
                  "General.Class" | translate
                }}</label>
                <select
                  class="custom-select"
                  [(ngModel)]="classId"
                  (change)="getSubClassData($event.target.value)"
                  #selectClass
                >
                  <option value="All" selected>
                    {{ "General.All" | translate }}
                  </option>
                  <option [value]="class.id" *ngFor="let class of classList">
                    {{ class.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-6 col-sm-6 col-md-4 mt-1" *ngIf="isShowSubClass">
              <div class="scope-custom">
                <label for="first-name-vertical">{{
                  "General.SubClass" | translate
                }}</label>
                <select
                  class="custom-select"
                  [(ngModel)]="subClassId"
                  #selectSubClass
                >
                  <option value="All" selected>
                    {{ "General.All" | translate }}
                  </option>
                  <option
                    [value]="subClass.id"
                    *ngFor="let subClass of subClassList"
                  >
                    {{ subClass.name }}
                  </option>
                </select>
              </div>
            </div>

            
          </div>
        </div>

        <div class="card-body row">
          <div class="col-12 px-0 d-flex justify-content-between">
            <!-- <div class="col-3 d-flex justify-content-start my-auto">
            </div> -->
            <div class="col-12 px-0 d-flex justify-content-center">
              <button
                (click)="ResetSearch()"
                class="btn btn-secondary"
                style="margin-right: 0.5rem"
              >
                <i data-feather="refresh-ccw" class="mr-1"></i
                >{{ "General.Reset" | translate }}
              </button>

              <button
                (click)="Submit()"
                class="btn btn-primary"
                style="margin-left: 0.5rem"
              >
                <i data-feather="search" class="mr-1"></i
                >{{ "General.Search" | translate }}
              </button>
            </div>
            <!-- <div *ngIf="ExportXLSX()" class="col-3 px-0 d-flex justify-content-end">
              <button (click)="ExportXLSX()" class="btn btn-primary mr-1">
                <i data-feather="file" class="mr-1"></i>Export
              </button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
