class Item {
  id: string;
  itemCode: string;
  modelNo: string;
  groupId: number;
  groupName: string;
  categoryId: number;
  categoryName: string;
  subCategoryId: number;
  subCategoryName: string;
  classId: number;
  className: string;
  subClassId: number;
  subClassName: string;
  name: string;
  coverMediaFile: string;
  mediaFiles: string[];
  itemBarcode: string;
  brandId: number;
  brandName: string;
  purchasePrice: number;
  sellingPrice: number;
  discountPrice: number;
  taxRate: number;
  description: string;
  detail: string;
  vendorId: number;
  packageId: number;
  packageName: string;
  unitId: number;
  unitName: string;
  quantity: number;
  dimension: string;
  weight: number;
  itemSet?: any[];
  volume: number;
  colorId: number;
  colorName: string;
  materialId: number;
  materialName: string;
  textureId: number;
  textureName: string;
  isActive: boolean;
  isItemSet: boolean;
  isNewProduct: boolean;
  isBestSeller: boolean;
  isDelete: boolean;
  createBy: string;
  createDate: string;
  uniqueId: string;
  updateBy: string;
  updateDate: string;
  deleteBy: string;
  deleteDate: string;
}

export default Item;
