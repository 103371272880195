import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-child-document',
  templateUrl: './content-child-document.component.html',
  styleUrls: ['./content-child-document.component.scss']
})
export class ContentChildDocumentComponent implements OnInit {

  @Input() orderStateData: any

  constructor() { }

  ngOnInit(): void {
  }

}
