import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about.component';
import { AuthGuard } from 'app/auth/helpers';
import { TranslateModule } from '@ngx-translate/core';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSummernoteModule } from 'ngx-summernote';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { ComponentsModule } from 'app/main/components/components.module';
import { AboutHistoryFormpageComponent } from './about-history/about-history-formpage/about-history-formpage.component';
import { AboutHistoryComponent } from './about-history/about-history.component';
import { AboutCertifiedFormpageComponent } from './about-certified/about-certified-formpage/about-certified-formpage.component';
import { AboutCertifiedComponent } from './about-certified/about-certified.component';
import { AboutTeamComponent } from './about-team/about-team.component';
import { AboutTeamFormpageComponent } from './about-team/about-team-formpage/about-team-formpage.component';
import { AboutActivityComponent } from './about-activity/about-activity.component';
import { AboutActivityFormpageComponent } from './about-activity/about-activity-formpage/about-activity-formpage.component';

const routes: Routes = [
  {
    path: 'cms/about',
    component: AboutComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-about' },
  },

  {
    path: 'cms/about-certified',
    component: AboutCertifiedComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-about-certified' },
  },
  {
    path: 'cms/about-certified/create',
    component: AboutCertifiedFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-about-certified-create' },
  },
  {
    path: 'cms/about-certified/edit/:id',
    component: AboutCertifiedFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-about-certified-edit' },
  },

  {
    path: 'cms/about-history',
    component: AboutHistoryComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-about-history' },
  },
  {
    path: 'cms/about-history/create',
    component: AboutHistoryFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-about-history-create' },
  },
  {
    path: 'cms/about-history/edit/:id',
    component: AboutHistoryFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-about-history-edit' },
  },


  {
    path: 'cms/about-team',
    component: AboutTeamComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-about-team' },
  },
  {
    path: 'cms/about-team/create',
    component: AboutTeamFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-about-team-create' },
  },
  {
    path: 'cms/about-team/edit/:id',
    component: AboutTeamFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-about-team-edit' },
  },

  {
    path: 'cms/about-activity',
    component: AboutActivityComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-about-activity' },
  },
  {
    path: 'cms/about-activity/create',
    component: AboutActivityFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-about-activity-create' },
  },
  {
    path: 'cms/about-activity/edit/:id',
    component: AboutActivityFormpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'cms-about-activity-edit' },
  },
];

@NgModule({
  declarations: [
    AboutComponent,
    AboutCertifiedComponent,
    AboutCertifiedFormpageComponent,
    AboutHistoryComponent,
    AboutHistoryFormpageComponent,
    AboutTeamComponent,
    AboutTeamFormpageComponent,
    AboutActivityComponent,
    AboutActivityFormpageComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ContentHeaderModule,
    FormsModule,
    NgxSummernoteModule,
    ReactiveFormsModule,
    CorePipesModule,
    ComponentsModule,
    RouterModule.forChild(routes),
  ],
  exports: [AboutComponent],
})
export class AboutModule {}
