import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProductServiceService {

  productService = {
    componentName: 'Product Service',
    apiPath: 'ProductsServices',
    pathUrl: '/cms/product-service',
    keyName: 'name',
  };
  
  product = {
    componentName: 'Product',
    apiPath: 'ProductsServicesItem',
    pathUrl: '/cms/product-service/product',
    keyName: 'name',
  };

  service = {
    componentName: 'Service',
    apiPath: 'ProductsServicesItem',
    pathUrl: '/cms/product-service/service',
    keyName: 'name',
  };

  innovation = {
    componentName: 'Innovation',
    apiPath: 'ProductsServicesItem',
    pathUrl: '/cms/product-service/innovation',
    keyName: 'name',
  };

  constructor() { }
}
